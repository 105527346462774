import {serializable, alias, primitive, object, list, custom} from 'serializr';

export class User {

	@serializable(alias('id', primitive()))
    id?: number;

	@serializable(alias('user_name', primitive()))
    userName?: string;

    @serializable(alias('email', primitive()))
    email?: string;

	@serializable(alias('otp', primitive()))
    otp?: string;

	@serializable(alias('phone_number', primitive()))
    phoneNumber?: string;

    @serializable(alias('password', primitive()))
    password?: string;

	@serializable(alias('role', primitive()))
	role?: string;

	@serializable(alias('address', primitive()))
	address?: string;

	@serializable(alias('company_name', primitive()))
	companyName?: string;

	@serializable(alias('company_description', primitive()))
	companyDescription?: string;

	@serializable(alias('company_province', primitive()))
	companyProvince?: string;

	@serializable(alias('company_isd_code', primitive()))
	companyIsdCode?: string;

	@serializable(alias('company_phone_number', primitive()))
	companyPhoneNumber?: string;

	@serializable(alias('company_website', primitive()))
	companyWebsite?: string;

	@serializable(alias('company_street', primitive()))
	companyStreet?: string;

	@serializable(alias('company_city', primitive()))
	companyCity?: string;
	
	@serializable(alias('company_location', primitive()))
	companyLocation?: string;

	@serializable(alias('company_location_id', primitive()))
	company_location_id?: string;

	
	
	


	@serializable(alias('company_postal_code', primitive()))
	companyPostalCode?: string;

	@serializable(alias('company_country', primitive()))
	companyCountry?: string;

	@serializable(alias('company_email', primitive()))
	companyEmail?: string;


	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('status', primitive()))
	status?: string;

	@serializable(alias('access_token', primitive()))
    accessToken?: string;

	@serializable(alias('gender', primitive()))
	gender?: string;

	@serializable(alias('active', primitive()))
	active?: boolean;

	@serializable(alias('company_type_ids', list(primitive())))
	companyType?: number[];

	
}





export class resumeObject {

    @serializable(alias('uid', primitive()))
    uid?: string;

    @serializable(alias('lastModified', primitive()))
    lastModified?: number;

    @serializable(alias('lastModifiedDate', custom(
        (value) => new Date(value), 
        (value) => value.toISOString()
    )))
    lastModifiedDate?: Date;

    @serializable(alias('name', primitive()))
    name?: string;

    @serializable(alias('size', primitive()))
    size?: number;

    @serializable(alias('type', primitive()))
    type?: string;

    @serializable(alias('percent', primitive()))
    percent?: number;

    // @serializable(alias('originFileObj', custom((value) => {
    //     return value ? {
    //         uid: value.uid
    //         // Add other properties from the 'originFileObj' if needed
    //     } : null;
    // }, () => undefined))) // Dummy deserializer
    // originFileObj?: { uid: string } | null;

	@serializable(alias('originFileObj', custom(
		(value) => {
			return value ? {
				uid: value.uid
				// Add other properties from the 'originFileObj' if needed
			} : null;
		},
		(value) => value || {} // Update the dummy deserializer
	)))
	originFileObj?: { uid: string } | null;

    @serializable(alias('status', primitive()))
    status?: string;

    
    @serializable(alias('response', custom(
        (value) => value || {},
        () => undefined // Dummy deserializer
    )))
    response?: { success?: boolean };

    @serializable(alias('xhr', custom(
        (value) => value || {},
        () => undefined // Dummy deserializer
    )))
    xhr?: object;

    // You can add other properties as needed
}





export class JobSeekerUser {

	@serializable(alias('id', primitive()))
    id?: number;

	@serializable(alias('user_name', primitive()))
    userName?: string;

    @serializable(alias('email', primitive()))
    email?: string;

	@serializable(alias('otp', primitive()))
    otp?: string;

	@serializable(alias('phone_number', primitive()))
    phoneNumber?: string;

    @serializable(alias('password', primitive()))
    password?: string;

	@serializable(alias('role', primitive()))
	role?: string;

	@serializable(alias('address', primitive()))
	address?: string;

	@serializable(alias('company_name', primitive()))
	companyName?: string;

	@serializable(alias('company_description', primitive()))
	companyDescription?: string;

	@serializable(alias('company_province', primitive()))
	companyProvince?: string;

	@serializable(alias('company_isd_code', primitive()))
	companyIsdCode?: string;

	@serializable(alias('company_phone_number', primitive()))
	companyPhoneNumber?: string;

	@serializable(alias('company_website', primitive()))
	companyWebsite?: string;

	@serializable(alias('company_street', primitive()))
	companyStreet?: string;

	@serializable(alias('company_city', primitive()))
	companyCity?: string;

	@serializable(alias('company_postal_code', primitive()))
	companyPostalCode?: string;

	@serializable(alias('company_country', primitive()))
	companyCountry?: string;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('status', primitive()))
	status?: string;

	@serializable(alias('message', primitive()))
	message?: string;

	@serializable(alias('trail_blaizer_profile', primitive()))
	trailBlaizerProfile?: string;

	@serializable(alias('date_of_birth', primitive()))
	dateOfBirth?: string;

	@serializable(alias('isd_code', primitive()))
	isdNumber?: string;

	// @serializable(alias('skills', list(object())))
	// skills?: [];

	@serializable(alias('gender', primitive()))
	gender?: string;

	@serializable(alias('bio', primitive()))
	bio?: string;

	@serializable(alias('terms_and_conditions', primitive()))
	termsAndConditions?: boolean;

	
	

	@serializable(alias('resume', object(resumeObject)))
	resume?: resumeObject;

	// @serializable(alias('resume', primitive()))
	// resume?: string;

	@serializable(alias('access_token', primitive()))
    accessToken?: string;
	
}
