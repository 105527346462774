import React, { useEffect, useState } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import ManageSkills from 'services/MetaList/skills.service';


export interface Group {
  id: number;
  name: string;
}

function JobSkillsComponent(props: any) {

    const { getSkills, skills, createJobSkills, singleSkill } = ManageSkills()
    const { jobInfo, setJobInfo, setFieldValue, singleJob }    =   props;

  

  // const [Groups, setGroups] = useState<Group[]>([])

  const [getGrp, setGrp] = useState<string | undefined>();
  const [GroupData, setGroupData] = useState<Group[] | undefined>();

  const [selectedSkills, setSelectedSkills] = useState<Group[]>([]);

  const [GroupRes, setGroupRes] = useState(true);
  const [transformedOptions, setTransformedOptions] = useState<Group[] | undefined>();
  // let transformedOptions: Group[] | undefined;
  let getObjects: Group[] | undefined;

  
  useEffect(() => {    
    getSkills();
    // setSelectedJobFuns([1, 3])

    console.log("singleJob", singleJob?.skills)

    if(singleJob?.skills) {
      setSelectedSkills(singleJob?.skills)
    }

  }, []);


  const InsertItem = async () => {
    if (getGrp) {

      console.log('getGrp', getGrp)
      const getGroup: any = await createJobSkills({name:getGrp})

      // console.log('getGroup', getGroup)

        const newItem = { id: getGroup?.data?.id, name: getGroup?.data?.name };    

        setSelectedSkills([...selectedSkills, newItem])

      // let newList: any = singleJob?.skills.map((obj: any) => obj.id);

      let newList: any = singleJob?.skills?.map((obj: any) => obj.id) || []; // Provide a default empty array if singleJob?.skills is undefined

      
      // setFieldValue('skill_ids', newList)
      setFieldValue('skill_ids', [...newList, newItem?.id]);
      setGrp(undefined);


    };
      
  };

  const handleSearch = async (e: string) => {
    let Group = await skills?.find(function (element:any) {
      return element.name === e;
    });
    if (Group) {
      setGrp(undefined);
    } else {
      setGrp(e);
    }
  };

  

  return (
    <div className="form-group mt-3">
      {/* <label>Select Skills*:</label> */}

      <div>
        <div >
          <Multiselect
            style={{ borderRadius: "0px" }}
            isObject={true}
            placeholder='Skills'
            onRemove={async (removed) => {
            //   console.log('removed', removed)
              let grpRmv: any = removed.map((obj: any) => obj.id);

              console.log('removed groups', grpRmv)

              setSelectedSkills(removed)
            //   setJobInfo({...jobInfo, skill_ids: grpRmv})
              setFieldValue('skill_ids', grpRmv)
              setGrp(undefined);
             
            }}
            onSearch={handleSearch}
            onSelect={async (add) => {
                // console.log('add grps', add)
              let grpAdd: any = await add.map((obj: any) => obj.id);

              console.log('grpAdd', grpAdd)
              
              setSelectedSkills(add);

            //   setJobInfo({...jobInfo, skill_ids: grpAdd})
            setFieldValue('skill_ids', grpAdd)
            setGrp(undefined);
             

            }}
            options={skills}
            displayValue="name"
            showCheckbox
            selectedValues={((selectedSkills?.length > 0) || (!GroupRes)) ? selectedSkills : null}
          // selectedValues={selectedGroups}
          />
        </div>
        <div className="col-md-2 px-0">
          {getGrp ? (
            <button type='button' className="btn btn-dark btn-md" onClick={InsertItem}>
              Add
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default JobSkillsComponent;