import React, { useEffect, useState } from "react";
import UserService from 'services/AuthService/auth.service';
import ManageJobsService from 'services/ManageJobsService/manage.jobs.service';
import { LocalStorage } from 'shared/utils/localStorageHelpers';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactQuill from "react-quill";
import './jobapplication.css'

const JobApplicantDetails = () => {

  const location = useLocation();

  const [ note, setNote ] = useState('')
  const [noteStatus, setNoteStatus] = useState('')
  const [filteredNotes, setFilteredNotes] = useState<any>([])

  const { getJobSeekerUser, singleUser } = UserService()

  const { postNote, getNotes, notesList }  = ManageJobsService()
  const data = LocalStorage?.getItem("USER")

  
  // const usersInfo = {applicant: singleUser?.id, companyUser: data?.user?.id}

  // console.log(usersInfo, '...jobApplicantNotes')

 
// console.log(singleUser, '....singleUser')

  // useEffect(() => {
  //   getJobSeekerUser(location?.state?.applicantId)
  //   // getNotes({applicant: singleUser?.id, companyUser: data?.user?.id})
  // },[])

  useEffect(() => {
    getJobSeekerUser(location?.state?.applicantId)
    getNotes({applicant: location?.state?.applicantId, companyUser: data?.user?.id})
  },[data?.user?.id])

  useEffect(() => {
    // Check if notesList is an array and not empty
    if (Array.isArray(notesList) && notesList.length > 0) {
      // Check the user's role
      if (data?.user?.role === "Company Admin") {
        // Filter notesList based on the company_user's company
        // const noteResp = notesList.filter((n) => n?.company_user?.company === data?.user?.id);
        const noteResp = notesList
        // console.log(noteResp, '...noteResp');
        setFilteredNotes(noteResp);
      } else if (data?.user?.role === "Company Employee") {
        // Filter notesList based on the job_seeker's id
        const noteResp = notesList.filter((n) => n?.company_user?.id === data?.user?.id);
        setFilteredNotes(noteResp);
      }
    }
  }, [notesList, data?.user?.role, data?.user?.id]);

  console.log(notesList, '...notesList')
  // console.log(data, '...logedUser')
  // console.log(location?.state?.applicantId, '...jobseeker')
  

  

  

  // console.log(filteredNotes, '...filteredNotes');

  const notesRenderinfo = () => {

    return filteredNotes?.map((note:any) => {
      return (
        <div key={note?.id} style={{marginBottom:'20px'}}>
          <h6 style={{fontWeight:500}}>{note?.company_user?.name}</h6>
          <span style={{fontWeight:'normal', fontSize:'14px'}}>
              <div dangerouslySetInnerHTML={{ __html: note?.notes }} />
            </span>          
        </div>
      )
    })
  }


  

  const submitNote = async () => {

    const data = {

      note: note,
      jobSeekerId: singleUser?.id
    }

    const resp = await postNote(data)

    if (resp?.status === 201) {
      setNoteStatus('Note added successfully')
    } else {
      setNoteStatus('Note not added')
    }

    console.log(resp, '...resp')
  }
  

    return(
        <>
        <div className="mt-50"></div>

<main id="main">
  <div className="page-title">
    <nav className="breadcrumbs">
      <div className="container">
        <ol>
          <li><a href="#">Home</a></li>
          <li><a href="#">Company Dashboard</a></li>
          <li className="current">Jobs Applicant</li>
        </ol>
      </div>
    </nav>
  </div>


  <section className="f0f5f3">
    <div className="container">



      <div className="row">



        <div className="col-md-9 padding-left-2rem pbg">
          <div className="row gy-4">
            <div className="companyprofile-info col-md-3">
              <img src="assets/img/blog-author-3.jpg" alt="" className="img-fluid" />
              <h5>{singleUser?.user_name}</h5>
            </div>

            <div className="Description">
              <h5>
                Professional Summary
              </h5>
              <p>
                {/* {singleUser?.bio} */}
                <div dangerouslySetInnerHTML={{ __html: singleUser?.bio }} />
              </p>
            </div>

            <div className="col-md-4">
              <p><i className="bi bi-globe2"></i> {singleUser?.portfolio}</p>
            </div>

            <div className="col-md-4">
              <p><i className="bi bi-mailbox"></i>{singleUser?.email}</p>
            </div>

            <div className="col-md-4">
              <p><i className="bi bi-phone"></i>{singleUser?.isd_code+'-'+singleUser?.phone_number}</p>
            </div>

            <div className="col-md-4">
              <p>{singleUser?.date_of_birth}</p>
            </div>

            <h5>Skills</h5>
            <div className="col-md-6">
              <p></p>
            </div>

            <h6>Educational Details</h6>
            <div className="col-md-3">
              <p>{singleUser?.education_level}</p>
            </div>

            <div className="col-md-3">
              <p>{singleUser?.degree_name}</p>
            </div>

            <div className="col-md-3">
              <p> {singleUser?.college_name}</p>
            </div>

            <div className="col-md-3">
              <p>{singleUser?.university}</p>
            </div>

            <div className="col-md-3">
              <p> {singleUser?.specialization}</p>
            </div>

            <div className="col-md-3">
              <p> {singleUser?.degree_type}</p>
            </div>

            <div className="col-md-3">
              <p>{singleUser?.start_date}</p>
            </div>

            <div className="col-md-3">
              <p> {singleUser?.end_date}</p>
            </div>


            

            <h5>Certifications</h5>
            <div className="Description">
              <ul>
                <li>{singleUser?.certification_one}</li>
                <li>{singleUser?.certification_two}</li>
              </ul>
            </div>

            <h4>
              Preferred Locations
            </h4>

            <div className="col-md-8">
              <p>{singleUser?.location_one+', '+singleUser?.location_two+', '+singleUser?.location_three}</p>
            </div>

            <h5>
              Address
            </h5>

            <div className="col-md-4">
              <p>Country : {singleUser?.country}</p>
            </div>
            <div className="col-md-4">
              <p>State : {singleUser?.state}</p>
            </div>
            <div className="col-md-4">
              <p>City : {singleUser?.city} </p>
            </div>
            <div className="col-md-4">
              <p>Pincode : {singleUser?.postal_code} </p>
            </div>

            <div className="col-md-4">
              <p>Salary Expectations : {singleUser?.salary_expect}</p>
            </div>

            <div className="col-md-4">
              <p> Languages Known : {singleUser?.languages}</p>
            </div>
            <div className="col-md-4">
              <p> Availability Date : {singleUser?.available_date}</p>
            </div>


            <div className="col-md-12">
                    <label>Note/Comments: </label>
                  <ReactQuill
                            theme="snow"
                            // value={values?.bio}
                            onChange={(value) => setNote(value)}
                          />
                  </div>
          </div>

          <br />
          <span style={{color:'green'}}>{noteStatus ? noteStatus : null}</span>
          <br />

          <button type="button" className="btn btn-primary" onClick={submitNote}>Submit</button>

          

          {/* <div className="status-buttons">
            <a className="green text-center" href="#" style={{padding: '10px 30px'}}>Approve</a>
            <a className="orange text-center" href="#" style={{padding: '10px 30px'}}>Shotlist</a>
            <a className="redbtn text-center" href="#" style={{padding: '10px 30px'}}>Delete Application</a>
          </div> */}

        </div>


        <div className="col-md-3 col-12">
          <div className="jobapplicant sidewidth">
            <div className=" side-nav-item jobs-nav ">

              <div className="deatils">
                <span>Location:</span><br />
                <p>{singleUser?.city+', '+singleUser?.state+', '+singleUser?.country}</p>
              </div>

              {/* <div className="deatils">
                <span>Notice Period :</span><br />
                <p>30 days</p>
              </div> */}

              <div className="deatils">
                <span>Email:</span><br />
                <p>{singleUser?.email}</p>
              </div>

              <div className="deatils">
                <span>Phone:</span><br />
                <p>{singleUser?.isd_code+'-'+singleUser?.phone_number}</p> <br />
                {/* <p>+000 000000</p> */}
              </div>

              {/* <div className="deatils">
                <span>Category:</span><br />
                <p>Technology, Product, Agency</p>
              </div> */}
              <center>
                <a className="loginbtn text-center" href="#" style={{padding: '10px 30px'}}>Call </a>
              </center>


            </div>
          </div>

          <br />

          <div className="">
            <div className="side-nav-item jobs-nav ">

              <div className="deatils">
                {notesRenderinfo()}
              </div>


            </div>
          </div>
        </div>

        




      </div>
    </div>
  </section>



</main>
        </>
    )
}

export default JobApplicantDetails