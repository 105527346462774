import React, { useState, useContext } from "react";
import axiosInstance from "interceptor/axiosInstance";
import { Token } from "models/token.model";
import { JobSeekerUser, User } from "models/user.model";
import { useNavigate } from "react-router-dom";
import { ApiRoutes } from "routes/routeConstants/apiRoutes";
import { deserialize, serialize } from "serializr";
import { LocalStorage } from "shared/utils/localStorageHelpers";
import ContextData from "views/useContext";
// import ContextData from 'views/useContext';

const ManageJobsService = () => {
  const navigate = useNavigate();

  const { setData } =	useContext<any>(ContextData);

  const [error, setError] = useState<Error| unknown>();

  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState<any>([])
  const [appliedJobs, setAppliedJobs] = useState<any>([]);
  const [singleJob, setSingleJob] = useState<any>()
  const [jobAppliedUsers, setJobAppliedUsers] = useState<any>([])

  const [ notesList, setNotesList ] = useState<any>([])

  


  const postJob = async (data:any) => {
    try {
      setLoading(true);
      let resp = await axiosInstance.post(ApiRoutes.POST_JOB, data);
      // setJobs(resp?.data)
      
      return resp;
    } catch (error) {
      console.log(error);
    }
  };

  

  const postNote = async (data:any) => {

    // console.log(data, '...postNote')
    try {
      setLoading(true);
      let resp = await axiosInstance.post(ApiRoutes.JOBS_SEEKER_CREATE_NOTE + `/${data?.jobSeekerId}`, {notes:data?.note});
      // setJobs(resp?.data)
      
      return resp;
    } catch (error) {
      console.log(error);
    }
  };


  const getNotes = async (data:any) => {

    console.log(data, '...userinfo')
    try {
      setLoading(true);
      // let resp = await axiosInstance.get(ApiRoutes.JOBS_SEEKER_CREATE_NOTE + `/${data?.applicant}/company_user/${data?.companyUser}`);

      let resp = await axiosInstance.get(ApiRoutes.JOBS_SEEKER_CREATE_NOTE + `/${data?.applicant}`);

      // console.log(resp.data, '...getNotes')
      setNotesList(resp?.data)
      // setJobs(resp?.data)
      
      return resp;
    } catch (error) {
      console.log(error);
    }
  };

  const getJobPostswithoutLogin = async () => {
    try {
      setLoading(true);
      const jobList = await axiosInstance.get(
        ApiRoutes.JOB_LIST_WITHOUT_LOGIN
      );
      // const desiredData = deserialize(User, desiredInfo.data?.results as User[]);
      // setCompanyEmployees(desiredData);

      // console.log('jobList', jobList?.data?.results)

     

      setJobs(jobList?.data?.results)
      return jobList;
    } catch (error) {
      console.log(error);
    }
  };


  const getCompanyJobPosts = async (compId:any) => {
    console.log(compId, '...compId')
    try {
      setLoading(true);
      const jobList = await axiosInstance.get(
        ApiRoutes.JOB_LIST_WITHOUT_LOGIN + `/${compId}`
      );
      // const desiredData = deserialize(User, desiredInfo.data?.results as User[]);
      // setCompanyEmployees(desiredData);

      console.log('jobList', jobList?.data?.results)

     

      setJobs(jobList?.data?.results)
      return jobList;
    } catch (error) {
      console.log(error);
    }
  };


  const getJobPosts = async () => {
    try {
      setLoading(true);
      const jobList = await axiosInstance.get(
        ApiRoutes.POST_JOB
      );
      // const desiredData = deserialize(User, desiredInfo.data?.results as User[]);
      // setCompanyEmployees(desiredData);

      // console.log('jobList', jobList?.data?.results)

     

      setJobs(jobList?.data?.results)
      return jobList;
    } catch (error) {
      console.log(error);
    }
  };

  const getSingleJobPost = async (jobID:number) => {
    try {
      setLoading(true);

      // const desiredInfo = await axiosInstance.get(
      //   ApiRoutes.POST_JOB + `/${jobID}`
      // );

      const desiredInfo = await axiosInstance.get(
        ApiRoutes.GET_SINGLE_JOB_WITHOUT_LOGIN + `/${jobID}`
      );

      


      
      
      // const desiredData = deserialize(User, desiredInfo.data as User);
      // setSingleCompanyEmployees(desiredData);

      // console.log('jobList', desiredInfo?.data)

      
      // setJobs(desiredInfo?.data)
      setSingleJob(desiredInfo?.data)
      return desiredInfo;
    } catch (error) {
      console.log(error);
    }
  };

  const applyJobPost = async (data:any) => {
    try {
      setLoading(true)
      const resp = await axiosInstance.post(ApiRoutes.APPLY_JOB_POST, data)
      return resp;

    } catch (error) {
      console.log(error)
    }
  }

  const appliedJobsList = async (jobSeeker:any) => {
    try {
      // console.log('jobSeeker', jobSeeker?.id)
      setLoading(true)
      const resp = await axiosInstance.get(ApiRoutes.APPLIED_JOBS_LIST + `/${jobSeeker?.id}`)

      // console.log('appliedJobsInfo', resp?.data)
      setAppliedJobs(resp?.data)
      return resp;

    } catch (error) {
      console.log(error)
    }
  }

  const updateJobPost = async (jobID:number, job:any) => {  
    
    // console.log(job, '...jobInfo')

    try {
      delete job?.id
      

      // console.log('jobID', jobID)
      // console.log('job', job)

      setLoading(true);
      const desiredInfo = await axiosInstance.put(
        ApiRoutes.POST_JOB + `/${jobID}`, job
      );

      // console.log('desiredInfo', desiredInfo)
      
      return desiredInfo?.data;
    } catch (error) {
      console.log(error);
    }
  };

  const deleteJobPost = async (jobID:number) => {
    try {
      setLoading(true);
      const desiredInfo = await axiosInstance.delete(
        ApiRoutes.POST_JOB + `/${jobID}`
      );
      return desiredInfo?.data;
    } catch (error) {
      console.log(error);
    }
  };

  const jobAppliedStatus = async (jobID: number) => {

    try {
      setLoading(true)
      const desiredInfo = await axiosInstance.get(
        ApiRoutes.JOB_APPLIED_STATUS + `/${jobID}`
      )
      
      setJobAppliedUsers(desiredInfo?.data)
      return desiredInfo


    } catch (error) {
      console.log(error)
    }
  }

  const updateJobApplicationStatus = async (jobPostId:number, jobSeekerId:any, applicationStatus:any) => {   
    try {
      // delete job?.id

      console.log('jobPostId', jobPostId)
      console.log('jobSeekerId', jobSeekerId)

      setLoading(true);
      const desiredInfo = await axiosInstance.put(
        ApiRoutes.JOB_APPLIED_STATUS + `/${jobPostId}/job_seeker/${jobSeekerId}`, {'status':applicationStatus}
      );

      // console.log('desiredInfo', desiredInfo?.data)
      
      return desiredInfo?.data;
    } catch (error) {
      console.log(error);
    }
  };

  

  return {
    loading,
    postJob,
    getJobPosts,
    getSingleJobPost,
    updateJobPost,
    deleteJobPost,
    applyJobPost,
    appliedJobsList,
    jobAppliedStatus,
    updateJobApplicationStatus,
    getJobPostswithoutLogin,
    getCompanyJobPosts,
    postNote,
    getNotes,
    jobAppliedUsers,
    jobs,
    singleJob,
    appliedJobs,
    notesList
  };
};

export default ManageJobsService;
