import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import './services.css'

const Services = () => {


    return(

        <>
        <div className="mt-50"></div>

<main id="main">

    <div className="page-title">
      <nav className="breadcrumbs">
        <div className="container">
          <ol>
            <li><a href="index.php">Home</a></li>
            <li className="current">Services</li>
          </ol>
        </div>
      </nav>
      </div>


<div className="services">
  <div className="service1">
    <div className="container">
      <div className="row d-flex align-items-center">
        <div className="col-md-6 ">
          <h2>Job Placement Services</h2>
          <p>
            Secure your dream Salesforce role with our job placement services. We offer resume building and personalized job matching.
          </p>
          
          <a href="javascript:void(0)" className="read-more"><span>Get Started</span><i className="bi bi-arrow-right"></i></a>
        </div><div className="col-md-6 "></div>
      </div>
    </div>
  </div>
  <div className="service2">
    <div className="container">
      <div className="row d-flex align-items-center">
        <div className="col-md-6 ">
          <h2>Training and Certification</h2>
          <p>
           Elevate your skills with our Salesforce training programs. Expert guidance and support for obtaining certifications.
          </p>
          
          <a href="#" className="read-more"><span>Get Started</span><i className="bi bi-arrow-right"></i></a>
        </div><div className="col-md-6 "></div>
      </div>
    </div>
  </div>
</div>

<section id="faq" className="faq">

  <div className="container">

    <div className="row gy-4">

      <div className="col-lg-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
        <div className="content px-xl-5">
          <h3><span>Frequently Asked </span><strong>Questions</strong></h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Duis aute irure dolor in reprehenderit
          </p>
        </div>
      </div>

      <div className="col-lg-8 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
<div className="process">
<Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Accordion Item #1</Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Accordion Item #2</Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Accordion Item #2</Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>

    </Accordion>
</div>


      </div>
    </div>

  </div>

</section>


</main>
        </>

    )
}

export default Services;