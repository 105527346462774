import React, { useEffect, useRef, useState } from "react";
import ManageJobsService from 'services/ManageJobsService/manage.jobs.service';
import { useNavigate, useLocation } from "react-router-dom";
// import LocationsComponent from "views/locations";
import { Select } from 'antd';
import ManageLocations from 'services/MetaList/locations.service';
import ManageJobFunctions from 'services/MetaList/job.functions.service'
import './jobs.css'


const Jobs = () => {

      //Using Inline Function and the The Logical Not (!) to toggle state
      const [toggle, setToggle] = useState(false)

  const { jobs, getJobPostswithoutLogin } = ManageJobsService()
  const { getJobFunctions, jobFucntions, getJobFunctionsWithoutLogin } = ManageJobFunctions()
  const navigate = useNavigate();

  const location = useLocation();
  const { locations, getLocations } = ManageLocations();
  const [formatLocations, setFormatLocations] = useState<any>([])

  const [formatCategories, setFormatCategories] = useState<any>([])

  const [jobLocation, setJobLocation] = useState<any>('all');
  const [jobType, setJobType] = useState('all');
  const [jobCategory, setJobCategory] = useState<any>('all');

  const [jobsListItems, setJobsListItems] = useState<any>([]);
  const [jobsFilterListItems, setJobsFilterListItems] = useState<any>([]);

  const { jobCatId, jobSearch } = location?.state || {};

  // console.log(jobSearch, '...jobSearch')
  // console.log(typeof(parseInt(jobSearch?.role)), '...jobSearch')

  //dash menu toggle
  const toggleContainer = useRef<HTMLDivElement>(null);


  useEffect(() => {
    getJobPostswithoutLogin();
    getJobFunctionsWithoutLogin();
    getLocations();
  }, []);



  useEffect(() => {

    console.log(jobSearch, '...jobSearch')
    
    jobSearch?.role && setJobCategory(parseInt(jobSearch?.role))
    jobSearch?.jobType && setJobType(jobSearch?.jobType)
    jobSearch?.location && setJobLocation(parseInt(jobSearch?.location))

  }, [jobSearch?.role, jobSearch?.jobType, jobSearch?.location]);


  // useEffect(() => {
    
  //   let filteredItems = jobsListItems.filter((job: any) => {

  //     if ((jobSearch?.role === 'all' || job?.job_function?.some((category: any) => category?.id === parseInt(jobSearch?.role))) &&
  //       (jobSearch?.location === 'all' || job?.location?.id === parseInt(jobSearch?.location)) &&
  //       (jobSearch?.jobType === 'all' || job?.job_type === jobSearch?.jobType)) {
  //       return true;
  //     } else {
  //       return false; }
  //   });

  //   setJobsFilterListItems(filteredItems);

  // }, [jobsListItems, jobSearch?.role, jobSearch?.jobType, jobSearch?.location]);

  useEffect(() => {
    const formattedLocations = locations?.map((loc: any) => ({
      label: loc.name,
      value: loc.id
    }));

    const additionalLocation = {
      label: 'All Locations', // Replace with your desired label
      value: 'all' // Replace with your desired value
    };

    if (formattedLocations) {
      // setFormatLocations(formattedLocations);
      setFormatLocations([additionalLocation, ...formattedLocations]);

    }
  }, [locations]);


  //job category
  useEffect(() => {
    const formattedJobCategories = jobFucntions?.map((loc: any) => ({
      label: loc.name,
      value: loc.id
    }));

    const additionalCats = {
      label: 'All Categories', // Replace with your desired label
      value: 'all' // Replace with your desired value
    };

    if (formattedJobCategories) {
      // setFormatLocations(formattedLocations);
      setFormatCategories([additionalCats, ...formattedJobCategories]);

    }
  }, [jobFucntions]);



  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  // console.log(locations, '...locations')



  // Update the state in a useEffect hook
  useEffect(() => {
    const filteredJobs = jobCatId
      ? jobs.filter((job: any) =>
        job.job_function.some((jobFunc: any) => jobFunc.id === jobCatId)
      )
      : jobs;

    // Set the state outside the render method
    setJobsListItems(filteredJobs);
   



  }, [jobCatId, jobs]); // Dependency array to ensure the effect runs when jobCatId or jobs change

  useEffect(() => {
   
    const handleClickOutside = (event: MouseEvent) => {
      if (toggleContainer.current && !toggleContainer.current.contains(event.target as Node)) {
        setToggle(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up event listeners
      document.removeEventListener("mousedown", handleClickOutside);
    };


  }, [toggle]);



  // const onSelectChange = (value: any) => {    

  //   if(jobsListItems && value !== null) {      
  //     const filteredItems = jobsListItems.filter((job: any) => job?.location?.id === value)
  //     setJobsFilterListItems(filteredItems);
  //     setJobLocation(value);
  //   }

  // }

  useEffect(() => {
    
    let filteredItems = jobsListItems.filter((job: any) => {

      // console.log(jobType, '...jobType');
      // console.log(typeof(jobType), '...TypejobType');
      


      // if (jobCategory !== 'all' && job?.job_function?.some((category: any) => category?.id === jobCategory)) {
      //   return true;
      // }

      // if (jobLocation !== 'all' && job?.location?.id !== jobLocation) {
      //   return false;
      // }

      // if (jobType !== '' && job?.job_type !== jobType) {
      //   return false;
      // } 

      // return true;

      if ((jobCategory === 'all' || job?.job_function?.some((category: any) => category?.id === jobCategory)) &&
        (jobLocation === 'all' || job?.location?.id === jobLocation) &&
        (jobType === 'all' || job?.job_type === jobType)) {
        return true;
      } else {
        return false;
      }



    });
    setJobsFilterListItems(filteredItems);
    
  }, [jobsListItems, jobCategory, jobLocation, jobType]);






  // console.log(jobCatId, '....jobCatID')
  console.log(jobsListItems, '...jobsListItems')



  const jobList = () => {


    let renderJobs: any = jobLocation && jobLocation !== 'all' ? jobsFilterListItems : jobsListItems;

    // console.log(renderJobs, '...renderJobs')


    // return jobsListItems?.map((job: any) => {

    return renderJobs?.map((job: any) => {

      return (
        // <div key={job?.id}>
        <div className="jobs-box">
          <div className="row" key={job?.id}>
            <div className="col-md-1 flexing">
              <div className="company-logo">
                <img src="assets/img/Companylogos/Companylogos1.png" alt="Salesforce Logo" className="img-fluid" />
              </div>
            </div>
            <div className="col-md-4 flexing">
              <div className="company-info ">
                {/* <p className="green-text">IT & Networking</p> */}
                <ul style={{ paddingLeft: '0px' }}>{

                  job?.job_function.map((jobCat: any, index: any) => {

                    return (
                      <li className="green-text" style={{ listStyle: 'none', fontSize: '10px', display: 'block' }}>{jobCat?.name}</li>
                    )

                  })

                }
                </ul>
                <h4>{job?.title}</h4>
                <div className="address">
                  {/* <span> 52 Irving Pl, NY</span> */}
                  <span>{job?.application_deadline}</span>
                </div>
                <span className="tags">{job?.mode}</span>
              </div>
            </div>

            <div className="col-md-5 flexing">
              <div className="pricing">
                {/* <span className="float-left"><b>{job?.currencyType}&nbsp;</b><b>{job?.salary}&nbsp;</b>lakh/ {job?.salaryType}, &nbsp;</span> */}
                {/* <span className="float-left">{job?.currencyType?.name}</span> */}
                <span className="float-left">{job?.salary}&nbsp;{job?.currencyType?.name}/ {job?.salaryType}, &nbsp;{job?.location?.name}</span>
                {/* <span className="float-left"><b>{job?.location?.name}</b></span> */}
              </div>
            </div>

            <div className="col-md-2 flexing">
              <div className="pricing">
                <span className=" applyViewMore"><a href="javascript:void(0)" onClick={() => navigate('/job-details', { state: { jobPostId: job?.id } })}>View JD</a></span>

              </div>
            </div>

            {/* <div className="col-md-2 flexing">
            <div className="pricing">
              <span className=" apply"><a href="#"> Apply</a></span>
            </div>
          </div>
          </div> */}
          </div>
        </div>
      )
    })
  }


  // console.log(jobFucntions, '...jobFucntions')



  return (

    <>

      <div className="mt-50"></div>

      <main id="main">
        <div className="page-title">
          <nav className="breadcrumbs">
            <div className="container">
              <ol>
                <li><a href="index.php">Home</a></li>
                <li className="current">Jobs</li>
              </ol>
            </div>
          </nav>
        </div>


        <section className="f0f5f3">
          <div className="container">


            <div className="result-bar">
              <div className="row">
                <div className="col-md-3 ">
                  <h4>Filter By</h4>
                </div>
                <div className="col-md-4">
                  Showing 1 – 10 of 11 results
                </div>
                <div className="col-md-4 float-right ">
                  <div className="pagination ">
                    <ul>
                      <li><a href="#">1</a></li>
                      <li className="active"><a href="#">2</a></li>
                      <li><a href="#">3</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>



            <div className="row">

              <div className="col-md-3">

              <div className='mobilesidenavmenu'  ref={toggleContainer}>
                        <button className='sidenavmenu btn sidenavmenubtn  mb-5' onClick={() => setToggle(!toggle)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-bar-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M6 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L12.293 7.5H6.5A.5.5 0 0 0 6 8m-2.5 7a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5"/>
                          </svg>
                          </button>
                            {
                              toggle &&
                              <div className="jobssidenav ">
                              <div className="side-nav-item jobs-nav ">
            
                                <div className="jobs-nav-item">
                                  <Select
                                    showSearch
                                    placeholder="Category"
                                    optionFilterProp="children"
                                    onChange={(value: any) => setJobCategory(value)}
                                    value={jobCategory ? jobCategory : 'all'}
                                    options={formatCategories}
                                    className='testSearch'
                                    style={{ width: "100%" }}
                                  />
                                </div>
            
                                <div className="jobs-nav-item">
                                  {/* <label>Location</label> */}
                                  <Select
                                    showSearch
                                    placeholder="Location"
                                    optionFilterProp="children"
                                    onChange={(value: any) => setJobLocation(value)}
                                    value={jobLocation ? jobLocation : 'all'}
                                    // onSearch={onSearch}
                                    // filterOption={filterOption}            
                                    options={formatLocations}
                                    className='testSearch'
                                    style={{ width: "100%" }}
                                  />
                                </div>
            
            
                                <div className="jobs-nav-item">
                                  {/* <label>Job Type</label> */}
                                  <Select
                                    showSearch
                                    placeholder="Job Type"
                                    optionFilterProp="children"
                                    // onChange={onJobTypeChange}
                                    onChange={(value: any) =>
                                      setJobType(value)
                                    }
                                    // onSearch={onSearch}
                                    filterOption={filterOption}
                                    value={jobType}
                                    options={[
                                      {
                                        value: "all",
                                        label: "All Job Types",
                                      },
                                      {
                                        value: "fulltime",
                                        label: "FullTime",
                                      },
                                      {
                                        value: "parttime",
                                        label: "PartTime",
                                      },
                                      {
                                        value: "contract",
                                        label: "Contract",
                                      },
                                      {
                                        value: "internship",
                                        label: "Internship",
                                      },
                                      {
                                        value: "temporary",
                                        label: "Temporary",
                                      },
                                    ]}
                                    style={{ width: "100%" }}
                                  />
                                </div>
            
                              </div>
                            </div>
                            }   
                           </div>


                <div className="jobssidenav desktopsidenavmenu">
                <div className=" ">
                              <div className="side-nav-item jobs-nav ">
            
                                <div className="jobs-nav-item">
                                  <Select
                                    showSearch
                                    placeholder="Category"
                                    optionFilterProp="children"
                                    onChange={(value: any) => setJobCategory(value)}
                                    value={jobCategory ? jobCategory : 'all'}
                                    options={formatCategories}
                                    className='testSearch'
                                    style={{ width: "100%" }}
                                  />
                                </div>
            
                                <div className="jobs-nav-item">
                                  {/* <label>Location</label> */}
                                  <Select
                                    showSearch
                                    placeholder="Location"
                                    optionFilterProp="children"
                                    onChange={(value: any) => setJobLocation(value)}
                                    value={jobLocation ? jobLocation : 'all'}
                                    // onSearch={onSearch}
                                    // filterOption={filterOption}            
                                    options={formatLocations}
                                    className='testSearch'
                                    style={{ width: "100%" }}
                                  />
                                </div>
            
            
                                <div className="jobs-nav-item">
                                  {/* <label>Job Type</label> */}
                                  <Select
                                    showSearch
                                    placeholder="Job Type"
                                    optionFilterProp="children"
                                    // onChange={onJobTypeChange}
                                    onChange={(value: any) =>
                                      setJobType(value)
                                    }
                                    // onSearch={onSearch}
                                    filterOption={filterOption}
                                    value={jobType}
                                    options={[
                                      {
                                        value: "all",
                                        label: "All Job Types",
                                      },
                                      {
                                        value: "fulltime",
                                        label: "FullTime",
                                      },
                                      {
                                        value: "parttime",
                                        label: "PartTime",
                                      },
                                      {
                                        value: "contract",
                                        label: "Contract",
                                      },
                                      {
                                        value: "internship",
                                        label: "Internship",
                                      },
                                      {
                                        value: "temporary",
                                        label: "Temporary",
                                      },
                                    ]}
                                    style={{ width: "100%" }}
                                  />
                                </div>
            
                              </div>
                            </div>
                </div>






              </div>
              <div className="col-md-9 ">
                <div className="container">
                  {/* <div className="jobs-box"> */}
                  {/* <div className="row"> */}
                  {jobList()}
                  {/* </div> */}


                  {/* </div> */}






                </div>
              </div>
            </div>
          </div>
        </section>



      </main>
    </>
  )
}

export default Jobs