import React, { useState, useContext } from "react";
import axiosInstance from "interceptor/axiosInstance";
import { Token } from "models/token.model";
import { JobSeekerUser, User } from "models/user.model";
import { useNavigate } from "react-router-dom";
import { ApiRoutes } from "routes/routeConstants/apiRoutes";
import { deserialize, serialize } from "serializr";
import { LocalStorage } from "shared/utils/localStorageHelpers";
import ContextData from "views/useContext";
import { AnyARecord } from "dns";

// import ContextData from 'views/useContext';

const UserService = () => {
  const navigate = useNavigate();

  const { setData } = useContext<any>(ContextData);

  const [error, setError] = useState<Error | unknown>();

  const [loading, setLoading] = useState(false);

  const [user, setUser] = useState<User>();

  const [singleUser, setSingleUser] = useState<any>();

  const [admins, setAdmins] = useState<any>();





  const [companyEmployees, setCompanyEmployees] = useState<User[]>([])

  const [singleCompanyEmployees, setSingleCompanyEmployees] = useState<User>()


  const companyAdminUpdatePassword = async (data: any) => {
    // console.log('useUpdatePwddata', data)
    try {
      setLoading(true);
      // const desiredPayload = serialize(User, data);
      const desiredPayload = data
      const desiredInfo = await axiosInstance.put(
        ApiRoutes.COMP_ADMIN_CHANGE_PWD,
        desiredPayload
      );
      return desiredInfo;
    } catch (error) {
      console.log(error);
    }
  }


  const companyAdminForgotChangePassword = async (data: any) => {
    console.log('change password', data)
    try {
      setLoading(true);
      // const desiredPayload = serialize(User, data);
      const desiredPayload = data
      const desiredInfo = await axiosInstance.put(
        ApiRoutes.COMP_ADMIN_CHANGE_PWD_FORGOT,
        desiredPayload
      );
      return desiredInfo;
    } catch (error) {
      console.log(error);
    }
  }


  


  const jobSeekerChangePassword = async (data: any) => {
    // console.log('data', data)
    try {
      setLoading(true);
      // const desiredPayload = serialize(User, data);
      const desiredPayload = data
      const desiredInfo = await axiosInstance.put(
        ApiRoutes.JOB_SEEKER_FORGOT_UPDATE_PWD,
        desiredPayload
      );
      return desiredInfo;
    } catch (error) {
      console.log(error);
    }
  }

  const jobSeekerUpdatePassword = async (data: any) => {
    // console.log('data', data)
    try {
      setLoading(true);
      // const desiredPayload = serialize(User, data);
      const desiredPayload = data
      const desiredInfo = await axiosInstance.put(
        ApiRoutes.JOB_SEEKER_CHANGE_PWD,
        desiredPayload
      );
      return desiredInfo;
    } catch (error) {
      console.log(error);
    }
  }



  const loginUser = async (data: User) => {
    try {
      setLoading(true);
      const desiredPayload = serialize(User, data);
      let resp = await axiosInstance.post(ApiRoutes.USER_LOGIN, { email: desiredPayload?.email, password: desiredPayload?.password });
      // console.log(resp?.data,"..response")
      if (resp?.data) {
        LocalStorage.setItem("USER", { user: resp?.data })
        setData(resp?.data)

        return resp;
      }
    } catch (error) {
      console.log(error);
    }
  };


  const cmpAdminForgotPwd = async (data: any) => {
    try {
      setLoading(true);
      // const desiredPayload = serialize(User, data);
      let resp = await axiosInstance.post(ApiRoutes.COMPANY_ADMIN_FORGOT_PWD, { email: data?.email });

      console.log(resp, '...resp')

      if (resp && resp !== undefined) {
        return resp
      } else {
        return false
      }
      
    } catch (error) {
      console.log(error);
    }
  };

  const registerCompanyAdm = async (data: User) => {
    try {
      setLoading(true);
      const desiredPayload = serialize(User, data);
      // console.log("desiredPayload", desiredPayload);
      const desiredInfo = await axiosInstance.post(
        ApiRoutes.COMPANY_ADMIN,
        desiredPayload
      );
      setData(desiredInfo?.data)
      return desiredInfo;
    } catch (error) {
      console.log(error);
    }
  };

  const registerUser = async (data: User) => {
    // console.log('data', data)
    // const desiredPayload = serialize(User, data);
    // console.log('desiredPayload', desiredPayload)
    try {
      setLoading(true);

      const desiredPayload = await serialize(User, data);

      let userRes = await axiosInstance.post(
        ApiRoutes.COMPANY_ADMIN_WITHOUT_LOGIN,
        desiredPayload
      );

      console.log(userRes, '...userRes')
      if (userRes?.data?.message === "User is already registered.") {

        return userRes?.data

      } else {

        const userDetails = deserialize(User, userRes.data)


        setUser(userDetails)
        return userDetails
      }

    } catch (error) {
      console.log(error, '...respError');
      // return error;
    }
  };


  const sendOtpJobSeeker = async (data: any) => {
    try {
      setLoading(true);
      // const desiredPayload = serialize(User, data);
      const desiredInfo = await axiosInstance.post(
        ApiRoutes.JOBSEEKER_FORGOT_PWD_SEND_OTP_TO_EMAIL,
        data
      );
      console.log('desiredInfo', desiredInfo)
      if (desiredInfo) {
        return desiredInfo
      } else {
        
        return false
      }
      // return desiredInfo;
    } catch (error) {
      console.log(error);
    }
  };

  const sendOtp = async (data: any) => {
    try {
      const desiredPayload = serialize(User, data);

      const desiredData = await axiosInstance.post(ApiRoutes.SEND_OTP, data);

      // console.log('desiredData', desiredData.data)

      // return deserialize(User, desiredData.data)

      return desiredData.data;
    } catch (error) {
      console.log(error);
    }
  };

  const verifyOtp = async (data: any) => {
    try {
      setLoading(true);
      let response = await axiosInstance.post(ApiRoutes.VERIFY_OTP, { email: data?.email, otp: Number(data?.otp) })
      setUser(response.data["company_user"]);

      if (response.data["company_user"]?.status === "success") {
        LocalStorage.setItem("USER", { user: response.data["company_user"] })
        setData(response.data["company_user"])
      }
      return response.data["company_user"]
    } catch (error) {
      setError(error);
    }
  };


  const verifyOtpForgotPwd = async (data: any) => {
    try {
      setLoading(true);
      let response = await axiosInstance.post(ApiRoutes.VERIFY_OTP, { email: data?.email, otp: Number(data?.otp) })
      setUser(response.data["company_user"]);

      if (response.data["company_user"]?.status === "success") {
        // LocalStorage.setItem("USER", { user: response.data["company_user"] })
        setData(response.data["company_user"])
      }
      return response.data["company_user"]
    } catch (error) {
      setError(error);
    }
  };

  // const registerJobSeeker = async (data: JobSeekerUser) => {
  const registerJobSeeker = async (data: any) => {

    try {
      setLoading(true);

      // const desiredPayload = serialize(JobSeekerUser, data);


      const desiredInfo = await axiosInstance.post(
        ApiRoutes.JOB_SEEKER_REGISRATION,
        data
      );
      // const desiredData = deserialize(JobSeekerUser, desiredInfo.data.jobseeker);
      // setUser(desiredData);
      return desiredInfo;
    } catch (error) {
      console.log(error);
    }
  };

  const getRegisterJobSeeker = async (userId: number, data: User) => {
    try {
      setLoading(true);
      const desiredPayload = serialize(User, data);
      const API_URL = `${ApiRoutes.GET_JOB_SEEKER_REGISRATION}/${userId}`;
      const desiredInfo = await axiosInstance.put(API_URL, desiredPayload);
      // console.log("desiredData", desiredInfo);
      return desiredInfo;
    } catch (error) {
      console.log(error);
    }
  };


  const jobSeekerVerifyOtpForgotPwd = async (data: any) => {
    try {
      setLoading(true);
      let response = await axiosInstance.post(ApiRoutes.JOB_SEEKER_FORGOT_VERIFY_OTP, { email: data?.email, otp: Number(data?.otp) })

      const userDetails = response.data;
      setUser(userDetails);

      if (userDetails?.message === "OTP verified successfully.") {
        // LocalStorage.setItem("USER", { user: userDetails })
        setData(userDetails)
      }
      return userDetails
    } catch (error) {
      setError(error);
    }
  };


  const jobSeekerVerifyOtp = async (data: any) => {
    try {
      setLoading(true);
      let response = await axiosInstance.post(ApiRoutes.JOB_SEEKER_VERIFY_OTP, { email: data?.email, otp: Number(data?.otp) })

      const userDetails = response.data;
      setUser(userDetails);

      if (userDetails?.message === "OTP verified successfully.") {
        LocalStorage.setItem("USER", { user: userDetails })
        setData(userDetails)
      }
      return userDetails
    } catch (error) {
      setError(error);
    }
  };

  const jobSeekerLoginUser = async (data: User) => {
    try {
      setLoading(true);
      const desiredPayload = serialize(User, data);
      let resp = await axiosInstance.post(
        ApiRoutes.JOB_SEEKER_USER_LOGIN,
        desiredPayload
      );
      LocalStorage.setItem("USER", { user: resp?.data })
      setData(resp?.data)
      return resp;
    } catch (error) {
      console.log(error);
    }
  };

  const logOut = async () => {
    setLoading(true);
    // console.log(LocalStorage.getItem('USER'),"..local")
    return await axiosInstance
      .delete(ApiRoutes.LOGOUT)
      .then((response: any) => {
        console.log(response, '...response')
        // if(response?.status === 204){
        LocalStorage.removeItem("USER")
        navigate("/");
        // }
      })
      .catch((error: any) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };



  const companyAdminLogOut = async () => {
    setLoading(true);
    return await axiosInstance
      .delete(ApiRoutes.COMPANY_ADMIN_LOGOUT)
      .then((response: any) => {
        console.log(response, '...response')
        // if(response?.status === 204){
        LocalStorage.removeItem("USER")
        navigate("/");
        // }
      })
      .catch((error: any) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const registerCompanyEmployee = async (data: User) => {
    console.log('desiredData', data)
    try {
      setLoading(true);
      const desiredPayload = serialize(User, data);
      const desiredInfo = await axiosInstance.post(
        ApiRoutes.COMPANY_EMPLOYEE_REGISTRATION,
        desiredPayload
      );

      const desiredData = deserialize(User, desiredInfo.data);


      setUser(desiredData);
      return desiredInfo;
    } catch (error) {
      console.log(error);
    }
  };

  const getCompanyEmployee = async () => {
    try {
      setLoading(true);
      const desiredInfo = await axiosInstance.get(
        ApiRoutes.COMPANY_EMPLOYEE_REGISTRATION
      );
      const desiredData = deserialize(User, desiredInfo.data?.results as User[]);
      setCompanyEmployees(desiredData);
      return desiredInfo;
    } catch (error) {
      console.log(error);
    }
  };

  const updateCompanyEmployee = async (employeeId: number, user: any) => {
    try {
      delete user?.password
      setLoading(true);
      const desiredInfo = await axiosInstance.put(
        ApiRoutes.COMPANY_EMPLOYEE_UPDATION + `/${employeeId}`, user
      );
      return desiredInfo?.data;
    } catch (error) {
      console.log(error);
    }
  };

  const deleteCompanyEmployee = async (employeeId: number) => {
    try {
      setLoading(true);
      const desiredInfo = await axiosInstance.delete(
        ApiRoutes.COMPANY_EMPLOYEE_UPDATION + `/${employeeId}`
      );
      return desiredInfo?.data;
    } catch (error) {
      console.log(error);
    }
  };

  const getSingleCompanyEmployee = async (employeeId: number) => {
    try {
      setLoading(true);
      const desiredInfo = await axiosInstance.get(
        ApiRoutes.COMPANY_EMPLOYEE_UPDATION + `/${employeeId}`
      );
      const desiredData = deserialize(User, desiredInfo.data as User);
      setSingleCompanyEmployees(desiredData);
      return desiredInfo;
    } catch (error) {
      console.log(error);
    }
  };

  const superAdminlogin = async (data: User) => {
    try {
      setLoading(true);
      const desiredPayload = serialize(User, data);
      let resp = await axiosInstance.post(ApiRoutes.SUPER_ADMIN_LOGIN, { email: desiredPayload?.email, password: desiredPayload?.password });
      LocalStorage.setItem("USER", { user: resp?.data })
      setData(resp?.data)

      return resp;
    } catch (error) {
      console.log(error);
    }
  };

  const superAdminLogOut = async () => {
    setLoading(true);
    // console.log(LocalStorage.getItem('USER'),"..local")
    return await axiosInstance
      .delete(ApiRoutes.SUPER_ADMIN_LOGOUT)
      .then((response: any) => {
        console.log(response, '...response')
        // if(response?.status === 204){
        LocalStorage.removeItem("USER")
        navigate("/");
        // }
      })
      .catch((error: any) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCompanyAdm = async (userId: number) => {
    console.log(userId, '...userId')
    try {
      setLoading(true);
      const desiredInfo = await axiosInstance.get(
        ApiRoutes.COMPANY_ADMIN + `/${userId}`
      );
      console.log("desiredData", desiredInfo);
      setSingleUser(desiredInfo?.data.results[0])
      return desiredInfo;
    } catch (error) {
      console.log(error);
    }
  };

  const getCompanyAdmDefault = async (userId: number) => {
    console.log(userId, '...userId')
    try {
      setLoading(true);
      const desiredInfo = await axiosInstance.get(
        ApiRoutes.COMPANY_ADMIN_DEFAULT + `/${userId}`
      );
      console.log("desiredData", desiredInfo);
      setSingleUser(desiredInfo?.data.results[0])
      return desiredInfo;
    } catch (error) {
      console.log(error);
    }
  };

  const getCompanyAdmins = async () => {
    try {
      setLoading(true);
      const desiredInfo = await axiosInstance.get(
        ApiRoutes.COMPANY_ADMIN
      );
      console.log("desiredData", desiredInfo);
      setAdmins(desiredInfo?.data.results)
      return desiredInfo;
    } catch (error) {
      console.log(error);
    }
  };

  const updateCompanyAdm = async (userId: number, data: any) => {
    try {
      setLoading(true);
      // console.log(data,"...data")
      // const d = data.get("company_type_ids")
      // const arrayOfStrings = d.split(',');

      // Convert each string element into an integer using map
      // const arrayOfIntegers = arrayOfStrings.map((item:any) => parseInt(item));

      // debugger
      // const desiredPayload = serialize(User, data);

      const desiredInfo = await axiosInstance.put(
        ApiRoutes.COMPANY_ADMIN + `/${userId}`, data
      );
      // console.log("desiredData", desiredInfo);
      // setSingleUser(desiredInfo?.data.results[0])
      return desiredInfo;
    } catch (error) {
      console.log(error);
    }
  };

  const getJobSeekerUser = async (userId: number) => {
    try {
      setLoading(true);
      const desiredInfo = await axiosInstance.get(
        ApiRoutes.JOB_SEEKER_REGISRATION + `/${userId}`
      );
      // console.log("desiredData", desiredInfo);
      setSingleUser(desiredInfo?.data.results[0])
      return desiredInfo;
    } catch (error) {
      console.log(error);
    }
  };

  const updateJobSeekerUser = async (userId: number, data: any) => {

    

    try {
      setLoading(true);
      const desiredInfo = await axiosInstance.put(
        ApiRoutes.JOB_SEEKER_REGISRATION + `/${userId}`, data
      );
      console.log("desiredData", desiredInfo);
      // setSingleUser(desiredInfo?.data.results[0])
      return desiredInfo;
    } catch (error) {
      console.log(error);
    }
  };

  return {
    loading,
    loginUser,
    user,
    registerCompanyAdm,
    registerUser,
    sendOtp,
    verifyOtp,
    registerJobSeeker,
    getRegisterJobSeeker,
    jobSeekerVerifyOtp,
    jobSeekerLoginUser,
    logOut,
    companyAdminLogOut,
    registerCompanyEmployee,
    getCompanyEmployee,
    companyEmployees,
    updateCompanyEmployee,
    deleteCompanyEmployee,
    getSingleCompanyEmployee,
    singleCompanyEmployees,
    superAdminlogin,
    superAdminLogOut,
    getCompanyAdm,
    singleUser,
    updateCompanyAdm,
    getJobSeekerUser,
    updateJobSeekerUser,
    getCompanyAdmins,
    getCompanyAdmDefault,
    companyAdminUpdatePassword,
    jobSeekerUpdatePassword,
    cmpAdminForgotPwd,
    verifyOtpForgotPwd,
    companyAdminForgotChangePassword,
    jobSeekerVerifyOtpForgotPwd,
    jobSeekerChangePassword,
    sendOtpJobSeeker,
    admins
  };
};

export default UserService;
