import React, { useContext } from "react";
// import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Header from "../../shared/components/Header";
import Login from "views/Auth/Login";
import RegisterForm from "views/Auth/RegisterForm";
import Dashboard from "views/Dashboard";
import JobSeekerRegistration from "views/Auth/JobSeekerRegistration";
import StakeHolders from "views/Auth/StakeHolders";
import JobSeekerDashboard from "./JobSeekerDashboard";
import HomePage from "./HomePage";
import JobSeekerLogin from "views/Auth/JobSeekerLogin";
import ContextData from "views/useContext";
import CompanyDashboard from "./companyDashboard";
import Footer from "../../shared/components/Footer";
import JobDetails from "./JobDetails";
import JobApplications from "./JobApplications";
import ManageJobs from "./ManageJobs";
import ManageEmployee from "./ManageEmployee";
import CompanyProfile from "./companyProfile";
import CompanyAccount from "./companyAccountSettings";
import JobApplicantDetails from "./JobApplicantDetails";
import Jobs from "./Jobs";
import SuperAdminDashboard from "./SuperAdminDashboard";
import Services from "./Services";
import ManageAdmins from "./ManageAdmins";
import CompanyAdminChangePassword from "./companyAdminChangePassword";
import JobSekerChangePassword from "./jobSeekerChangePassword";
import ManageJobSeekers from "./ManageJobSeekers"
import CompanyPage from "./companyPage";
import JobSeekerForgotPassword from './jobSeekerforgotPwd'
import EmployerForgotPassword from "./empForgotPwd";
// import JobseekerProfileUpdate from "./jobseekerAccountSettings";


const Home = () => {


  // const appRouter = createBrowserRouter([
  //   {
  //     path: "/",
  //     element: <Login />,
  //   },
  //   {
  //     path: "/register",
  //     element: <RegisterForm />,
  //   },
  //   {
  //     path: "/dashboard",
  //     element: <Dashboard />
  //   },
  // ]);

  return (
    <>    
      {/* <Header />
      <RouterProvider router={appRouter} />  */}

        <Header />
        <Routes>
          {/* <Route path="/" element={<StakeHolders />}  /> */}
          <Route path="/login" element={<Login />}  />
          <Route path="/super-admin-login" element={<Login/>} />
          <Route path="/register" element={<RegisterForm />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/job-seeker-register" element={<JobSeekerRegistration/>}/>
          <Route path="/job-seeker-dashboard" element={<JobSeekerDashboard/>}/>
          {/* <Route path="/home" element={<HomePage/>}/> */}
          <Route path="/" element={<HomePage/>}/>
          <Route path="/company-dashboard" element={<CompanyDashboard/>} />
          <Route path="/super-admin-dashboard" element={<SuperAdminDashboard/>}/>
          <Route path="/jobseeker-login" element={<JobSeekerLogin />}  />
          <Route path="/job-details" element={<JobDetails />} />
          <Route path="/job-applications" element={<JobApplications />} />
          <Route path="/manage-jobs" element={<ManageJobs />} />
          <Route path="/manage-employee" element={<ManageEmployee />} />
          <Route path="/manage-admins" element={<ManageAdmins />} />
          <Route path="/company-profile" element={<CompanyProfile />} />
          <Route path="/company-account-settings" element={<CompanyAccount />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/jobApplicant" element={<JobApplicantDetails />} />
          <Route path="/services" element={<Services />} />
          <Route path="/cmpAdminUpdatePwd" element={<CompanyAdminChangePassword />} />
          <Route path="/jobseekerUpdatePwd" element={<JobSekerChangePassword />} />
          <Route path="/jobseekerforgotPwd" element={<JobSeekerForgotPassword />} />
          <Route path="/empForgotPwd" element={<EmployerForgotPassword />} />
          <Route path="/manage-jobseekers" element={<ManageJobSeekers />} />
          <Route path="/company" element={<CompanyPage />} />
        </Routes>
        <Footer />
       

    </>
  );
};

export default Home;
