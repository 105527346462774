import { Form, Formik } from "formik";
import React, { useContext, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import UserService from "services/AuthService/auth.service";
import InputField from "../../../shared/components/InputField";
import ContextData from "../../useContext";
import "./login.scss"
import { loginValidationSchema } from "./loginValidationSchema";

const Login = () => {
  const navigate = useNavigate()
  const { loginUser, superAdminlogin } = UserService()
  const [loginErr, setLoginErr] = useState('')

  const { data, setData } = useContext<any>(ContextData);

  const location = useLocation()

  const currentPath = location.pathname

  console.log(currentPath)

  return (
    <>
    <main id="main">
      <section id="loginregister" className="loginregister">
        <div className={ currentPath === "/login" ? "companyloginregisterimg" : "emploginregisterimg"}>
          <div className="container" data-aos="fade-up" data-aos-delay="100">
            <div className="row gy-4">
              <div className="col-lg-5 "></div>

              <div className="col-lg-7 form-padding">
                <Formik
                  enableReinitialize

                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  validationSchema={loginValidationSchema}
                  onSubmit={async (values) => {
                    let info
                    if (currentPath === "/login") {
                      info = await loginUser(values)
                      console.log(info, '...userINFO')
                      if (info) {
                        setData(info.data)
                        navigate("/company-dashboard", { state: { data: info?.data } })
                      } else {
                        setLoginErr('invalid credentials or user not found ')
                      }
                    } else {
                      info = await superAdminlogin(values)
                      if (info) {
                        setData(info.data)
                        navigate("/super-admin-dashboard", { state: { data: info?.data } })
                        return
                      }
                    }
                    // if (info) {
                    //   setData(info.data)
                    //   navigate("/company-dashboard", { state: { data: info?.data } })
                    // } else {
                    //   setLoginErr('invalid credentials ')
                    // }

                  }}
                >
                  {({ values, setFieldValue }) => (
                    <Form
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      {/* <h4>Employer Login</h4> */}
                      { currentPath === "/login" ? <h4>Employer Login</h4> : <h4>Super Admin Login</h4>}
                      {loginErr && <div className="alert alert-danger">{loginErr}</div>}

                      <div className="col-md-8 field">
                        <InputField name="email" placeholder="Your Email" />
                      </div>
                      <div className="col-md-8 field">
                        <InputField
                          placeholder="Password"
                          name="password"
                          type="password"
                        />
                      </div>
                      <div className="col-md-12 field forgotpassword">
                        <button type="submit" className="submit">Log In</button>
                        {/* <a href="/register" style={{paddingLeft:"30px"}}>Need Accout?, Signup here</a> */}
                        { currentPath === "/login" ?
                        <>
                        <Link className="btn-getstarted" to="/register" > Don't have account? Signup here</Link>
                        <Link className="btn-getstarted" to="/empForgotPwd"> Forgot Password?</Link>
                        </>
                        : ''
                        }
                      </div>
                    </Form>
                  )}
                </Formik>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    </>
  );
};

export default Login;
