import { Form, Formik } from "formik";
import React, { useContext, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { LocalStorage } from 'shared/utils/localStorageHelpers';
import UserService from "services/AuthService/auth.service";
import InputField from "../../../shared/components/InputField";
import ContextData from "../../useContext";
import { changePasswordSchema } from "./changePasswordSchema";
// import "../login.scss"

const JobSekerChangePassword = () => {
  const navigate = useNavigate()
  const { loginUser, superAdminlogin, jobSeekerUpdatePassword } = UserService()
  const [msg, setMsg] = useState("")
  const [errMsg, setErrMsg] = useState("")
  const [loginErr, setLoginErr] = useState('')

  const userData = LocalStorage.getItem("USER").user

  console.log('userData', userData)

  const { data, setData } = useContext<any>(ContextData);

  const location = useLocation()

  const currentPath = location.pathname

  console.log(currentPath)

  return (
    // <main id="main">
    //   <section id="loginregister" className="loginregister">
    //     <div className="loginregisterimg">
    //       <div className="container" data-aos="fade-up" data-aos-delay="100">
    //         <div className="row gy-4">
    //           <div className="col-lg-5 "></div>

              // <div className="col-lg-7">
              <div className="col-md-9 padding-left-2rem pbg">
                <span style={{ color: 'green' }}>{msg ? msg : ''}</span>
                <span style={{ color: 'red' }}>{errMsg ? errMsg : ''}</span>
                <Formik
                  enableReinitialize
                  initialValues={{
                    email: userData?.email,
                    password: "",
                    new_password: "",
                  }}
                  validationSchema={changePasswordSchema}
                  onSubmit={async (values) => {

                    // console.log(values, '...values')
                    // if(currentPath === "/login"){
                    //    info = await loginUser(values)
                    //    console.log(info, '...userINFO')
                    //    if (info) {
                    //     setData(info.data)
                    //     navigate("/company-dashboard", { state: { data: info?.data } })
                    //   } else {
                    //     setLoginErr('invalid credentials or user not found ')
                    //   }
                    // }else{
                    const info = await jobSeekerUpdatePassword(values)

                    console.log('info', info)
                    if (info) {

                      setMsg('Password updated successfully')
                      setErrMsg("")



                      // setData(info.data)
                      // navigate("/super-admin-dashboard", { state: { data: info?.data } })
                      // return
                    } else {
                      setErrMsg('Invalid credentials')
                      setMsg("")
                    }
                    // }
                    // if (info) {
                    //   setData(info.data)
                    //   navigate("/company-dashboard", { state: { data: info?.data } })
                    // } else {
                    //   setLoginErr('invalid credentials ')
                    // }

                  }}
                >
                  {({ values, setFieldValue }) => (
                    <Form
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      <h4>Enter the details</h4>
                      {loginErr && <div className="alert alert-danger">{loginErr}</div>}

                      <div className="col-md-8 field">
                        <InputField name="email" placeholder="Your Email" value={userData?.email} disabled={true} />
                      </div>
                      <div className="col-md-8 field">
                        <InputField
                          placeholder="Password"
                          name="password"
                          type="password"
                        />
                      </div>

                      <div className="col-md-8 field">
                        <InputField
                          placeholder="New Password"
                          name="new_password"
                          type="password"
                        />
                      </div>

                      <div className="col-md-12 field">
                        <button type="submit" className="submit">Update</button>
                        {/* <a href="/register" style={{paddingLeft:"30px"}}>Need Accout?, Signup here</a> */}
                        {/* <Link className="btn-getstarted" to="/register" style={{ paddingLeft: '30px' }}> Don't have account? Signup here</Link> */}
                      </div>
                    </Form>
                  )}
                </Formik>
                {/* </div> */}
              </div>

    //         </div>
    //       </div>
    //     </div>
    //   </section>
    // </main>
  );
};

export default JobSekerChangePassword;
