import { Select, Switch, Table } from 'antd';
import { Form, Formik } from 'formik';
import { User } from 'models/user.model';
import React, { useContext, useEffect, useRef } from 'react'
import { useState } from 'react';
import UserService from 'services/AuthService/auth.service';
import InputField from 'shared/components/InputField';
import { LocalStorage } from 'shared/utils/localStorageHelpers';
import SuperAdminDashboadrPanel from '../shared/SuperAdminDashboardPanel';
import ReportsService from 'services/Reports/reports.service';
import ContextData from 'views/useContext';
import ManageJobs from '../ManageJobs';
import JobApplications from '../JobApplications';
import CompanyDashboadrPanel from '../shared';
import { useNavigate } from 'react-router-dom';
import ManageLocations from 'services/MetaList/locations.service';
// import CompantDashboadrPanel from '../shared';

const SuperAdminDashboard = () => {
  const data = LocalStorage.getItem("USER")
  const [employee, setEmployee] = useState(false)
  const [gender, setGender] = useState("");
  const [successMessage, setSuccessMessage] = useState('')
  const [isCreation, setIsCreation] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)
  const [isJobManage, setIsJobManage] = useState(false)
  const [isJobApplications, setISJobApplications] = useState(false)
  const [all, setAll] = useState(false)
  const [loading, setLoading] = useState(true);


  const navigate = useNavigate()

  // const { registerCompanyEmployee, getCompanyEmployee, companyEmployees, updateCompanyEmployee, deleteCompanyEmployee, getSingleCompanyEmployee, singleCompanyEmployees } = UserService()

  const { getTotalCompaniesList, getTotalCompaniesListByCountry,  companiesList, getTotalJobSeekersList, getTotalJobSeekersListByCountry, jobSeekersList, getTotalJobsList, getTotalJobsListByCountry, jobsList } = ReportsService()

  const [formatLocations, setFormatLocations] = useState<any>([])
  const { getCountry, country } = ManageLocations()

  const [byCountry, setByCountry] = useState<any>()


  //Using Inline Function and the The Logical Not (!) to toggle state
  const [toggle, setToggle] = useState(false)
  //dash menu toggle
  const toggleContainer = useRef<HTMLDivElement>(null);





  const onSelectChange = (value: string) => {
    console.log(`selected ${value}`);
    setGender(value)
  };

  const onSearch = (value: string) => {
    console.log('search:', value);
  };

  useEffect(() => {
    getTotalCompaniesList()
    getTotalJobSeekersList()
    getTotalJobsList()
    getCountry()
    setLoading(false)
    setAll(false)
  }, [all])

  // useEffect(() => {
  //   getTotalJobsListByCountry()
  // },[byCountry?.id])

  useEffect(() => {
    // Map the locations to the desired format
    let infoLocation = country?.map((loc: any) => {
      return { label: loc.name, value: loc.name+'_'+loc.id };
    }) ?? [];

    infoLocation = [{ label: 'All', value: 'all' }, ...infoLocation];


    // Set the formatted locations in the state
    setFormatLocations(infoLocation);

    const handleClickOutside = (event: MouseEvent) => {
      if (toggleContainer.current && !toggleContainer.current.contains(event.target as Node)) {
        setToggle(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up event listeners
      document.removeEventListener("mousedown", handleClickOutside);
    };


  }, [toggle, country]); // Add locations as a dependency to useEffect

  console.log(jobSeekersList, '...jobsList')


  

  

  return (
    <>
      <div className="mt-50"></div>
      
      <main id="main">
        <div className="page-title">
          <nav className="breadcrumbs">
            <div className="container">
              <ol>
                <li><a href="index.php">Home</a></li>
                <li className="current">Super Admin Dashboard</li>
              </ol>
            </div>
          </nav>
        </div>
        <section className="f0f5f3">
          <div className="container">





            <div className="row">
              <div className="col-md-3">



              <div className='mobilesidenavmenu'  ref={toggleContainer}>
                        <button className='sidenavmenu btn sidenavmenubtn  mb-5' onClick={() => setToggle(!toggle)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-bar-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M6 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L12.293 7.5H6.5A.5.5 0 0 0 6 8m-2.5 7a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5"/>
                          </svg>
                          </button>
                            {
                              toggle &&

                              <div className="adm-side-nav">
                              <div className="profile text-center">
                                <img src="assets/img/Companylogos/Companylogos1.png" alt="Salesforce Logo" className="img-fluid" />
                                <h4>{data?.user?.name}</h4>
                              </div>
                              <SuperAdminDashboadrPanel />
                            </div>
                          }   
                </div>




                <div className="adm-side-nav  desktopsidenavmenu">
                  <div className="profile text-center">
                    <img src="assets/img/Companylogos/Companylogos1.png" alt="Salesforce Logo" className="img-fluid" />
                    <h4>{data?.user?.name}</h4>
                  </div>
                  <SuperAdminDashboadrPanel />
                </div>








              </div>
              <div className="col-md-9 ">

                


                <div className="container">




                  

                  <div className="row">

                  <div className="form-group mt-3">
      <div className="row col-md-12">
        <div className="col-md-10" style={{ marginBottom: "50px" }}>
          <Select
            showSearch
            placeholder="Country"
            optionFilterProp="children"
            onChange={(value:any) => {
              // console.log(value, '..value')
              if ( value === 'all') {
                setAll(true)
              } else {
                getTotalJobsListByCountry(value)
                getTotalJobSeekersListByCountry(value)
                getTotalCompaniesListByCountry(value)
              }
              }}
            // setByCountry(value)}
            // onSearch={onSearch}
            // filterOption={filterOption}            
            // value={singleJob?.companyCountry ? singleJob?.companyCountry : singleJob?.country?.id ? singleJob?.country?.id : parseInt(singleJob?.country)}
            // value={singleJob?.country?.id ? singleJob?.country?.id : singleJob?.companyCountry}
            value={byCountry?.id}

            options={formatLocations}
            style={{ width: "100%" }}
          />
        </div>
        {/* <div className="col-md-2 px-0">
          {getGrp ? (
            <button type='button' className="btn btn-dark btn-md" onClick={InsertItem}>
              Add
            </button>
          ) : null}
        </div> */}
      </div>
    </div>


                    {/* <!------Job Item Start --- */}
                    

                    {/* <div className="result-bar">
                      <div className="row">
                        <div className="col-md-8">
                          Showing 1 – 10 of 11 results
                        </div>
                        <div className="col-md-4 float-right ">
                          <div className="pagination ">
                            <ul>
                              <li><a href="#">1</a></li>
                              <li className="active"><a href="#">2</a></li>
                              <li><a href="#">3</a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    <div className="row">

                      { loading ?  'Loading Data ...' :

                      <>

                      <div className="col-md-3">
                        <div className="count-box1">
                          <div>
                            {/* <span className="count">{companiesList?.total_count}</span> */}

                            <span className="count">{companiesList ? ( companiesList?.total_count ? companiesList?.total_count : (companiesList?.active_count + companiesList?.inactive_count) ): 0}</span>
                            <p>Total Compnies</p>

                            <div>
                              <p>Active: {companiesList?.active_count ? companiesList?.active_count : 0}</p>
                              <p>In Active: {companiesList?.inactive_count ? companiesList?.inactive_count : 0}</p>
                            </div>
                          </div>

                          <div className="count-img">
                            <img src="assets/img/icons/Resume.png" alt="" />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="count-box2">
                          <div>
                            {/* <span className="count">{jobSeekersList?.total_count}</span> */}
                            <span className="count">{jobSeekersList ? ( jobSeekersList?.total_count ? jobSeekersList?.total_count : (jobSeekersList?.active_count + jobSeekersList?.inactive_count) ): 0}</span>
                            <p>Total JobSeekers</p>

                            <div>
                            <p>Active: {jobSeekersList?.active_count ? jobSeekersList?.active_count : 0}</p>
                              <p>In Active: {jobSeekersList?.inactive_count ? jobSeekersList?.inactive_count : 0}</p>
                            </div>
                          </div>

                          <div className="count-img">
                            <img src="assets/img/icons/savedresumes.png" alt="" />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="count-box3">
                          <div>
                            <span className="count">{jobsList ? ( jobsList?.total_count ? jobsList?.total_count : (jobsList?.active_count + jobsList?.inactive_count) ): 0}</span>
                            <p>Total Jobs</p>

                            <div>
                              <p>Active: {jobsList?.active_count ? jobsList?.active_count : 0}</p>
                              <p>In Active: {jobsList?.inactive_count ? jobsList?.inactive_count : 0}</p>
                            </div>
                          </div>

                          <div className="count-img">
                            <img src="assets/img/icons/JobPosted.png" alt="" />
                          </div>
                        </div>
                      </div>
                      </>

                  }


                    </div>

                    {/* <div className='row margintop2p'>                    

                      <div className="col-md-6">
                        <div className="result-box">
                          <div className="d-flex">

                            <div className="user-img text-center">
                              <img src="assets/img/blog-author-3.jpg" alt="" className="img-fluid" />
                            </div>
                            <div className="jobseeker-info " style={{ marginTop: "20px" }}>
                              <h4 className="">Jai shankar kumara swamy </h4>
                            </div>

                          </div>


                          <div className=" d-flex job-flex" style={{}}>
                            <div className="jobseeker-info ">
                              <p className="green-text">Salesforce Administrator</p>
                              <div className="address">
                                <span> 5+ years Experience</span>
                              </div>
                              <span className="tags">Developer</span>
                              <span className="tags">Business Analyst</span><br />
                              <span className="tags">Architect</span>
                              <span className="tags">AppExchange</span>

                            </div>
                          </div>
                          <div className="pricing text-center">
                            <div className="salary">
                              <span className="">Salary</span>
                              <span className=""><h6>$30k/mo</h6></span>
                            </div>
                            <div className="location">
                              <span className="">Location</span>
                              <span className=""><h6>New York, US</h6></span>

                            </div>
                          </div>
                          <div className="pricing">
                            <span className="float-right applyViewMore"><a href="">View Profile</a></span>
                            <span className="float-right apply"><a href="">Download CV</a></span>
                          </div>
                        </div>
                      </div>
                    </div> */}








                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

    </>
  )
}

export default SuperAdminDashboard