import { Form, Formik } from "formik";
import React, { useContext, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import UserService from "services/AuthService/auth.service";
import InputField from "../../../shared/components/InputField";
import ContextData from "../../useContext";
import "./login.scss"
import { loginValidationSchema } from "../Login/loginValidationSchema";

const Login = () => {
  const navigate = useNavigate()
  const { jobSeekerLoginUser } = UserService()
  const [loginErr, setLoginErr] = useState('')

  const { data, setData } = useContext<any>(ContextData);

  return (
    <main id="main">
      <section id="loginregister" className="loginregister">
        <div className="jobloginregisterimg">
          <div className="container" data-aos="fade-up" data-aos-delay="100">
            <div className="row gy-4">
              <div className="col-lg-5 "></div>

              <div className="col-lg-7 form-padding">
                <Formik
                  enableReinitialize
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  validationSchema={loginValidationSchema}
                  onSubmit={async (values) => {


                    let info = await jobSeekerLoginUser(values)

                    if (info) {
                      setData(info.data)
                      navigate("/job-seeker-dashboard", { state: { data: info?.data } })
                    } else {
                      setLoginErr('invalid credentials or user not found')
                    }


                  }}
                >
                  {({ values, setFieldValue }) => (
                    <Form
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      <h4>Sign in to your Dedicated Salesforce Job Portal </h4>
                      {loginErr && <div className="alert alert-danger">{loginErr}</div>}

                      <div className="col-md-8 field">
                        <InputField name="email" placeholder="Your Email" />
                      </div>
                      <div className="col-md-8 field">
                        <InputField
                          placeholder="Password"
                          name="password"
                          type="password"
                        />
                      </div>
                      <div className="row">
                      <div className="col-md-12 field forgotpassword">
                        <button type="submit" className="submit">Sign In</button>
                        <Link className="btn-getstarted" to="/job-seeker-register" >Don’t have Account? Signup</Link>
                        <Link className="btn-getstarted" to="/jobseekerforgotPwd" > Forgot Password? </Link>
                      </div>
                      </div>




                    </Form>
                  )}
                </Formik>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Login;
