import React, { useContext } from "react";
import { Link, useNavigate } from 'react-router-dom';
import UserService from "services/AuthService/auth.service";
import { LocalStorage } from "shared/utils/localStorageHelpers";
import ContextData from "views/useContext";

const Footer = () => {

  const {companyAdminLogOut,logOut} = UserService()

  const data = LocalStorage.getItem("USER")

  return (
    <div>
        <footer id="footer" className="footer">

<div className="container footer-top">
  <div className="row gy-4">
    <div className="col-lg-5 col-md-12 footer-about">
      <a href="" className="logo d-flex align-items-center">
         <img src="assets/img/cloudtech-logo.png" alt="" />  
      </a>
      <p>Cras fermentum odio eu feugiat lide par naso tierra. Justo eget nada terra videa magna derita valies darta donna mare fermentum iaculis eu non diam phasellus.</p>
      <div className="social-links d-flex mt-4">
        <a href=""><i className="bi bi-twitter"></i></a>
        <a href=""><i className="bi bi-facebook"></i></a>
        <a href=""><i className="bi bi-instagram"></i></a>
        <a href=""><i className="bi bi-linkedin"></i></a>
      </div>
    </div>

    <div className="col-lg-2 col-12 footer-links">
      <h4>Useful Links</h4>
      <ul>
        <li><a href="#">Home</a></li>
        <li><a href="#">About us</a></li>
        <li><a href="#">jobcategory</a></li>
        <li><a href="#">Terms of jobcategory</a></li>
        <li><a href="#">Privacy policy</a></li>
      </ul>
    </div>

    <div className="col-lg-2 col-12 footer-links">
      <h4>Our jobcategory</h4>
      <ul>
        <li><a href="#">Web Design</a></li>
        <li><a href="#">Web Development</a></li>
        <li><a href="#">Product Management</a></li>
        <li><a href="#">Marketing</a></li>
        <li><a href="#">Graphic Design</a></li>
      </ul>
    </div>

    <div className="col-lg-3 col-12 footer-contact text-md-start">
      <h4>Contact Us</h4>
      <p>A108 Adam Street</p>
      <p>New York, NY 535022</p>
      <p>United States</p>
      <p className="mt-4"><strong>Phone:</strong> <span>+1 5589 55488 55</span></p>
      <p><strong>Email:</strong> <span>info@example.com</span></p>
    </div>

  </div>
</div>

</footer>
    </div>
  );
};

export default Footer;
