import * as Yup from 'yup';

export const cmpPwdValidateSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Email is required'),
  password: Yup.string().required('Password is required')
      .min(8, 'Password must be at least 8 characters long')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(/^(?=.*[0-9])/,'Password must contain at least one number')
      .matches(/^(?=.*[!@#$%^&*])/,'Password must contain at least one special character'),
  new_password: Yup.string().required('New password is required')
      .oneOf([Yup.ref('password')], 'Both passwords should match'),
});