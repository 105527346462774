import * as Yup from "yup";

export const employeeSchema = Yup.object().shape({
  name: Yup.string().required("Name is required").min(3, "Password must contain 3 characters"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required")
    .test("valid-domain", "Invalid domain extension", (value) => {
      // Custom validation for domain extension
      if (value) {
        const domainRegex =
          /^[A-Za-z0-9._%+-]+@([A-Za-z0-9-]+\.)+[A-Za-z]{2,}$/;
        return domainRegex.test(value);
      }
      return true; // Allow empty values, Yup's required handles that
    }),
  password: Yup.string().required('Password is required')
        .min(8, 'Password must be at least 8 characters long')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/^(?=.*[0-9])/,'Password must contain at least one number')
        .matches(/^(?=.*[!@#$%^&*])/,'Password must contain at least one special character'),
  gender: Yup.string().required("Please select at least one option"),
});
