import React, { useState, useEffect, useRef } from "react";
// import jwt from 'jsonwebtoken';

import { Link, useNavigate } from 'react-router-dom';
import UserService from "services/AuthService/auth.service";
import { LocalStorage } from "shared/utils/localStorageHelpers";
import './header.css'
// import bufferEqual from "./bufferEqual";


// global.Buffer = global.Buffer || require('buffer').Buffer;


const Header = () => {

  const { companyAdminLogOut, logOut, superAdminLogOut } = UserService();
  const [decodedToken, setDecodedToken] = useState(null);
  
  const [isNavVisible, setIsNavVisible] = useState(false);
  const [isNavProfileVisible, setIsNavProfileVisible] = useState(false);
  // const toggleNav = () => {
  //   setIsNavVisible(!isNavVisible);
  // };

  const toggleContainer = useRef<HTMLDivElement>(null);



  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

    
  
    // Function to toggle the visibility of the menu
    const toggleMenu = () => {
      setIsNavProfileVisible(!isNavProfileVisible);
    };





  const data = LocalStorage.getItem("USER");
 

  // if(data?.user?.access_token) {
  //   const decoded: any = jwt.decode(data?.user?.access_token);
  //     setDecodedToken(decoded);
  // }

  console.log(data?.user?.email, '...userData');
  // console.log(decodedToken, '...decodedToken');

  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (toggleContainer.current && !toggleContainer.current.contains(event.target as Node)) {
        setIsNavVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up event listeners
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isNavVisible])

  return (
    <div>
      <header
        id="header"
        className="header fixed-top"
      >
        <div className="container" id="cloudtechmenu">

          <div className="mobile-view"  ref={toggleContainer}> {/* **Nav bar */}
          <>
      <i className="mobile-nav-toggle d-xl-none bi bi-list" onClick={toggleNav}></i>
      <nav id="navmenu" className={`navmenu ${isNavVisible ? 'active' : ''}`}>
        <ul>
          <li>
            <Link to='/' className="active" onClick={toggleNav}>Home</Link>
          </li>
          <li>
            <Link to="/jobs" onClick={toggleNav}>Jobs</Link>
          </li>
          <li>
            <Link to="/services" onClick={toggleNav}>Services</Link>
          </li>
          <li><a href="/login" onClick={toggleNav}>Employer Login</a></li>
        </ul>
      </nav>
           </>
          </div>

         <div className="mobile-view"> {/* **logo */}
          <a href="javascript:void(0)" className="logo">
            <img src="assets/img/cloudtech-logo.png" alt="" />
          </a>
        </div>

        <div className="mobile-view "> {/* **Admin bar */}
            <ul className="mloginout">
              {
                // data?.user?.name ? (
                  data?.user?.email ? (

                  <li className="dropdown has-dropdown profile-nav">
                    <span onClick={toggleMenu}>
                    <span className="profile-name" ><img className="profile-name-img" src="assets/img/blog-author-3.jpg" alt="" style={{width:'45px', height:'auto'}}/></span>

                      </span>
                    <ul  className={`loginmenu ${isNavProfileVisible ? 'active' : ''}`}>
                     <li>{data?.user?.name}</li>
                     <li onClick={() => { data?.user?.role === "Company Admin" ? navigate('/company-dashboard') : data?.user?.role === "Super Admin" ? navigate('/super-admin-dashboard') : navigate('/job-seeker-dashboard')}}><a href="javascript:void(0)"> Profile</a></li>

                      <li onClick={() => {
                        if (data?.user?.role === "Company Admin") {
                          companyAdminLogOut();
                        } else if (data?.user?.role === "Super Admin") {
                          superAdminLogOut();
                        } else {
                          logOut();
                        }
                      }}>



                      {/* <li onClick={() => {
                        data?.user?.role === "Company Admin" ? companyAdminLogOut() :
                          data?.user?.role === "Super Admin" ? superAdminLogOut() :
                            logOut()

                      }}> */}
                        <a href="javascript:void(0)"> Logout</a></li>


                    </ul>
                  </li>

                ) : (
                  <li>
                    
                    <Link  to="/jobseeker-login"> 
                <div className="navloginicon">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16"><path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"></path></svg></div></Link>
                    
                    </li>
                )
              }
            </ul>
          </div>





          {/* ***************************************Desk top******************************************************** */}


          <div className="desktop-view"> {/* **logo*/}
              <a href="javascript:void(0)" className="logo">
                <img src="assets/img/cloudtech-logo.png" alt="" />
              </a>
          </div>


           <div className="desktop-view"> {/* **Nav bar */}
          <>
      <i className="mobile-nav-toggle d-xl-none bi bi-list" onClick={toggleNav}></i>
      <nav id="navmenu" className={`navmenu ${isNavVisible ? 'active' : ''}`}>
        <ul>
          <li>
            <Link to='/' className="active" onClick={toggleNav}>Home</Link>
          </li>
          <li>
            <Link to="/jobs" onClick={toggleNav}>Jobs</Link>
          </li>
          <li>
            <Link to="/services" onClick={toggleNav}>Services</Link>
          </li>
          <li><a href="/login" onClick={toggleNav}>Employer Login</a></li>
        </ul>
      </nav>
           </>
          </div>

          <div className="navmenu desktop-view">{/* **Admin bar*/}
            <ul>
              {
                data?.user?.name ? (

                  <li className="dropdown has-dropdown profile-nav">
                    <a href="#"><span className="profile-name"><img src="assets/img/blog-author-3.jpg" alt="" />{data?.user?.name}</span> <i className="bi bi-chevron-down"></i></a>

                    <ul className="dd-box-shadow">

                      <li onClick={() => { data?.user?.role === "Company Admin" ? navigate('/company-dashboard') : data?.user?.role === "Super Admin" ? navigate('/super-admin-dashboard') : navigate('/job-seeker-dashboard')}}><a href="javascript:void(0)"> Profile</a></li>

                      <li onClick={() => {
                        data?.user?.role === "Company Admin" ?
                          companyAdminLogOut() : data?.user?.role === "Super Admin" ? superAdminLogOut() : logOut()

                      }}><a href="javascript:void(0)"> Logout</a></li>


                    </ul>
                  </li>

                ) : (
                  <li><Link className="btn-getstarted" to="/jobseeker-login" > Log In / Sign Up</Link></li>
                )
              }
            </ul>
          </div>


        </div>

      </header>
    </div>
  );
};

export default Header;
