import { Select, Switch, Table } from "antd";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { User } from "models/user.model";
import React, { useEffect, useState } from "react";
// import ManageJobsService from 'services/ManageJobsService/manage.jobs.service';
import UserService from "services/AuthService/auth.service";
import InputField from "shared/components/InputField";
import { LocalStorage } from "shared/utils/localStorageHelpers";
import JobFunctionsComponent from "../../jobFunctions";
import CompanyDashboadrPanel from "../shared";
import SuperAdminDashboadrPanel from "../shared/SuperAdminDashboardPanel";
import JbSeekerService from "services/jobSeekers/jobseekers.service";
// import JobSkillsComponent from "../../jobSkills"

// import TextEditor from './TextEditor';
import "react-quill/dist/quill.snow.css"; // Import the styles
import ReactQuill from "react-quill";

const ManageJobSeekers = (props: any) => {
  const navigate = useNavigate();
  const [isCreation, setIsCreation] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [gender, setGender] = useState("");


  const { jobSeekersList, getJobSeekersList } = JbSeekerService()

  // const [isCreation, setIsCreation] = useState(false)
  const [skills, setSkills] = useState([]);
  const data = LocalStorage.getItem("USER");

  // console.log(data, '...userDATA')

  const {
    companyEmployees,
    singleCompanyEmployees,
    updateCompanyEmployee,
    registerCompanyEmployee,
    getSingleCompanyEmployee,
    getCompanyEmployee,
    updateJobSeekerUser,
    deleteCompanyEmployee,
  } = UserService();

  // const { registerCompanyEmployee, getCompanyEmployee, companyEmployees, updateCompanyEmployee, deleteCompanyEmployee, getSingleCompanyEmployee, singleCompanyEmployees } = UserService()

  const [formTable, setFormTable] = useState(true);
  // const [postID, setPostID] = useState();
  const [successMessage, setSuccessMessage] = useState("");

  const [recordDelete, setRecordDelete] = useState(false);
  let arrayOfIds;

  const [jobInfo, setJobInfo] = useState({
    title: "",
    job_details: "",
    requirements: "",
    salary: "",
    application_deadline: "",
    maximum_experience: "",
    minimum_experience: "",
    mode: "",
    job_type: "",
    status: "",
    job_function_ids: [],
    company_ids: "",
    location_ids: "",
    // skills: []
  });

  // useEffect(()=>{
  //     getJobPosts()
  // },[formTable])

  useEffect(() => {
    getCompanyEmployee();
    getJobSeekersList()
  }, [isUpdated, !isCreation]);

  // console.log("singleCompanyEmployees", singleCompanyEmployees);

  const onSearch = (value: string) => {
    console.log("search:", value);
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  // const columns:any = [

  const columns = [
    {
      title: "Name",
      dataIndex: "user_name",
      key: "user_name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      render: (active: any, data: User) => (
        <Switch
          defaultChecked={active}
          onChange={(value: boolean) => {
            // updateCompanyEmployee(data?.id!, { active: value });
            updateJobSeekerUser(data?.id!, { active: value });
            setIsUpdated(true);
          }}
        />
      ),
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "actions",
      render: (action: any, data: User) => (
        <>
          <span
            style={{ marginRight: "10px", cursor: "pointer" }}
            onClick={async () => {
              const desiredData = await getSingleCompanyEmployee(data?.id!);
              setIsCreation(true);
              setGender(desiredData?.data?.gender);
            }}
          >
            <i className="bi bi-pencil"></i>
          </span>
        </>
      ),
    },
  ];

  

  const desiredColumns = columns?.filter(
    (column: any) => column?.title !== "Actions"
  );

  console.log(jobSeekersList, '...jobSeekersList')

  return (
    <>
      <div className="mt-50"></div>
      <main id="main">
        <div className="page-title">
          <nav className="breadcrumbs">
            <div className="container">
              <ol>
                <li>
                  <a href="index.php">Home</a>
                </li>
                <li className="current">Company Dashboard</li>
              </ol>
            </div>
          </nav>
        </div>
        <section className="f0f5f3">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <div className="side-nav ">
                  <div className="profile text-center">
                    <img
                      src="assets/img/Companylogos/Companylogos1.png"
                      alt="Salesforce Logo"
                      className="img-fluid"
                    />
                    <h4>{data?.user?.name}</h4>
                  </div>
                  { data?.user?.role === 'Super Admin' ? <SuperAdminDashboadrPanel /> : <CompanyDashboadrPanel /> }
                </div>
              </div>
              <div className="col-md-9 ">
                <div className="row mt-10" style={{ marginTop: "20px" }}>
                  {/* <h3>Employee Enrollment</h3> */}

                  {successMessage ? (
                    <p className="text-info">{successMessage}</p>
                  ) : (
                    ""
                  )}
                  {isCreation && (
                    <Formik
                      enableReinitialize
                      initialValues={{
                        name: singleCompanyEmployees?.name || "",
                        email: singleCompanyEmployees?.email || "",
                        password: singleCompanyEmployees?.password || "",
                        gender: singleCompanyEmployees?.gender || "",
                        role: singleCompanyEmployees?.role || "",
                      }}
                      onSubmit={async (values, { resetForm }) => {
                        if (singleCompanyEmployees?.id) {
                          const data = await updateCompanyEmployee(
                            singleCompanyEmployees?.id,
                            values
                          );
                          if (data?.status === "success") {
                            setIsCreation(false);
                            setSuccessMessage("");
                            resetForm();
                            setIsUpdated(true);
                          } else {
                            setSuccessMessage("Something went wrong");
                          }
                        } else {
                          // const user = await registerCompanyEmployee({ ...values, gender })
                          const user = await registerCompanyEmployee({
                            ...values,
                          });
                          if (user?.data?.status === "success") {
                            setIsCreation(false);
                            setSuccessMessage("");
                            resetForm();
                            setIsUpdated(true);
                          } else {
                            setSuccessMessage("Something went wrong");
                          }
                        }
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form
                          className="php-email-form"
                          data-aos="fade-up"
                          data-aos-delay="200"
                        >
                          <div
                            className="col-md-8"
                            style={{ marginTop: "20px" }}
                          >
                            <label>Name:</label>
                            <InputField
                              placeholder=""
                              name="name"
                              value={values?.name}
                            />
                          </div>
                          <div
                            className="col-md-8"
                            style={{ marginTop: "20px" }}
                          >
                            <label>Email:</label>
                            <InputField
                              placeholder=""
                              name="email"
                              value={values?.email}
                            />
                          </div>

                          {singleCompanyEmployees ? null : (
                            <div
                              className="col-md-8"
                              style={{ marginTop: "20px" }}
                            >
                              <label>Password</label>
                              <InputField
                                placeholder=""
                                name="password"
                                type="password"
                                value={values?.password}
                              />
                            </div>
                          )}
                          <div
                            className="col-md-8"
                            style={{ marginTop: "20px" }}
                          >
                            <label>Gender:</label>
                            <Select
                              showSearch
                              placeholder="Select a gender"
                              optionFilterProp="children"
                              // onChange={onSelectChange}
                              value={values?.gender}
                              // onChange={(e) => setFieldValue('gender', e.target.value)}
                              onChange={(value) =>
                                setFieldValue("gender", value)
                              }
                              onSearch={onSearch}
                              filterOption={filterOption}
                              options={[
                                {
                                  value: "",
                                  label: "Select",
                                },
                                {
                                  value: "male",
                                  label: "Male",
                                },
                                {
                                  value: "female",
                                  label: "Female",
                                },
                              ]}
                              style={{ width: "100%" }}
                            />
                          </div>

                          {values?.role ? (

                          <div
                            className="col-md-8"
                            style={{ marginTop: "20px" }}
                          >
                            <label>Role:</label>
                            <Select
                              showSearch
                              placeholder="Select a Role"
                              optionFilterProp="children"
                              // onChange={onSelectChange}
                              value={values?.role}
                              // onChange={(e) => setFieldValue('gender', e.target.value)}
                              onChange={(value) =>
                                setFieldValue("role", value)
                              }
                              onSearch={onSearch}
                              filterOption={filterOption}
                              options={[
                                {
                                  value: "",
                                  label: "Select",
                                },
                                {
                                  value: "Company Employee",
                                  label: "Company Employee",
                                },
                                {
                                  value: "Company Admin",
                                  label: "Company Admin",
                                },
                              ]}
                              style={{ width: "100%" }}
                            />
                          </div>
                          ) : null }


                          <div
                            className="col-md-6 "
                            style={{ marginTop: "20px" }}
                          >
                            <button className="submit" type="submit">
                              Submit
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  )}

                  {!isCreation && (
                    <div>
                     {data?.user?.role !== "Super Admin" &&  <a
                        href="javascript:void(0)"
                        className="loginbtn"
                        onClick={() => setIsCreation(true)}
                      >
                        Create Employee
                      </a>}

                      <Table
                        dataSource={jobSeekersList}
                        columns={
                          data?.user?.role === "Super Admin"
                            ? desiredColumns
                            : columns
                        }
                        pagination={false}
                        className="margintop2p"
                      />
                    </div>
                  )}
                </div>

                {/* {formTable && <div style={{ marginTop: "20px" }}>
                <button onClick={()=> { setFormTable(false)}}>Create Job</button>
                <Table
                dataSource={jobs}
                columns={columns} pagination={false} />
                </div>} */}
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default ManageJobSeekers;
