import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UserService from "services/AuthService/auth.service";
import ManageJobsService from "services/ManageJobsService/manage.jobs.service";
import ManageCompanyTypes from 'services/MetaList/company.types'

const CompanyPage = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const { companyId } = location.state;
  const { getCompanyAdm, getCompanyAdmDefault, singleUser } = UserService()
  const { getCompanyList, companyList } = ManageCompanyTypes()
  // const [company, setCompany] = useState()
  const {
    // getJobPostswithoutLogin,
    getCompanyJobPosts,
    jobs,
    singleJob,
  } = ManageJobsService();

  useEffect(() => {
    // getCompanyAdm(companyId);
    // getCompanyAdmDefault(companyId)
    // getJobPostswithoutLogin();
    getCompanyJobPosts(companyId);
    getCompanyList()
  }, [])

  // console.log('singleUser', singleUser);
  
  // console.log(jobs, '...jobs');

  const companyInfo = companyList && companyList?.find((company: any) => company.id === companyId);
  console.log(companyInfo, '...companyInfo');
  // if( companyInfo ) {
  //   setCompany(companyInfo);
  // }
  

  // const companyInfo = companyList && companyList.find((company: any) => company.id === companyId)
  // setCompany(companyInfo)

  const jobsList = () => {
    return (
      jobs && jobs.map((job: any) => {
        return (
          <div className="jobs-box box-shadow">
            <div className="row">



              <div className="col-md-1 flexing">
                <div className="company-logo">
                  <img src="assets/img/Companylogos/Companylogos1.png" alt="Salesforce Logo" className="img-fluid" />
                </div>
              </div>
              <div className="col-md-4 flexing">
                <div className="company-info ">
                  {job?.job_function.map((jobCat: any, index: any) => {

                    return (
                      <p className="green-text">{jobCat?.name}</p>
                    )
                  })}
                  {/* <p className="green-text">IT &amp; Networking</p> */}
                  <h4>{job?.title}</h4>
                  <div className="address">
                    <span> {job?.location?.name}</span>
                    <span> {job?.application_deadline}</span>
                  </div>
                  <span className="tags">Temporary</span>
                </div>
              </div>
              <div className="col-md-5 flexing">
                <div className="pricing">
                  <span className="float-left"><b></b>{job?.currencyType?.name} {job?.salary} / {job?.salaryType}</span> &nbsp;&nbsp;
                  <span className="float-left"><b>{job?.location?.name}</b></span>
                </div>
              </div>

              <div className="col-md-2 flexing">
                <div className="pricing">
                  <span className=" applyViewMore"><a href="javascript:void(0)" onClick={() => navigate('/job-details', { state: { jobPostId: job?.id } })}>View JD</a></span>

                </div>
              </div>

              {/* <div className="col-md-2 flexing">
              <div className="pricing">
                <span className=" apply"><a href="#"> Apply</a></span>
              </div>
            </div> */}

            </div>



          </div>
        )
      })

    )
  }



  return (
    <>
      <div className="mt-50"></div>

      <main id="main">
        <div className="page-title">
          <nav className="breadcrumbs">
            <div className="container">
              <ol>
                <li><a href="index.php">Home</a></li>
                <li className="current">Company Profile - Admin</li>
              </ol>
            </div>
          </nav>
        </div>


        <section className="">
          <div className="container">
            <div className="row">
              <div className="col-md-9 padding-left-2rem pbg">
                <div className="row gy-4">
                  <div className="d-flex">
                    <div className="company-logo box-shadow" style={{ padding: '2%', borderRadius: '15px' }}>
                      <img src="assets/img/AitaTechnologies-logo.png" alt="" className="img-fluid" />
                    </div>
                    <div className=" company-details">
                      <h5>{companyInfo?.company_name}</h5>
                      {companyInfo?.company_type.map((item: any) => {
                        return <span className="tags">{item?.name}</span>
                      })}
                      {/* <span className="tags">Auto</span><span className="tags">ComponentsForeign</span><span className="tags">MNCB2B</span> */}
                    </div>
                  </div>

                  <div className="companyprofile-info">
                  </div>

                  <div className="Description">
                    <h5>
                      Overview
                    </h5>
                    <p dangerouslySetInnerHTML={{ __html: companyInfo?.company_description }} />
                  </div>

                  <div className="col-md-4">
                    <p><i className="bi bi-globe2"></i> {companyInfo?.social_media_link_1} </p>
                  </div>

                  <div className="col-md-4">
                    <p><i className="bi bi-mailbox"></i>{companyInfo?.social_media_link_2}</p>
                  </div>

                  {/* <div className="col-md-4">
                      <p><i className="bi bi-phone"></i>+1 123 123 1234</p>
                    </div> */}


                  <div className="companyjobs ">
                    <h5>Jobs Posted</h5>



                    {jobsList()}



                  </div>




                </div>
              </div>


              <div className="col-md-3">
                <div className="side-nav ">
                  <div className="side-nav-item jobs-nav ">

                    <div className="deatils">
                      <span>Location:</span><br />
                      <p>{companyInfo?.company_location?.name}</p>
                    </div>

                    <div className="deatils">
                      <span>Email:</span><br />
                      <p>{companyInfo?.company_email}</p>
                    </div>

                    <div className="deatils">
                      <span>Phone:</span><br />
                      <p>({companyInfo?.company_isd_code}) {companyInfo?.company_phone_number}</p> <br />
                      {/* <p>+000 000000</p> */}
                    </div>

                    {/* <div className="deatils">
                        <span>Category:</span><br />
                        <p>Technology, Product, Agency</p>
                      </div>

                      <center>
                        <a className="loginbtn text-center" href="#" style={{padding: '10px 30px'}}>Contact </a>
                      </center> */}


                  </div>
                </div>
              </div>






            </div>
          </div>
        </section>



      </main>

    </>
  )

}

export default CompanyPage;
