import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import React, { useState, useContext, useEffect, FC } from "react";

import InputField from "../../../shared/components/InputField";
import * as Yup from "yup";
import "./registerForm.scss";
import UserService from "services/AuthService/auth.service";
import ManageCompanyTypes from "services/MetaList/company.types";
import { LocalStorage } from "shared/utils/localStorageHelpers";
import { useNavigate } from "react-router-dom";
import DropdownField from "shared/components/DropdownField";
import ContextData from "views/useContext";
// import { LocalStorage } from "shared/utils/localStorageHelpers";
import { MD5 } from "crypto-js";
import { date } from "serializr";

import "react-quill/dist/quill.snow.css"; // Import the styles
import ReactQuill from "react-quill";
import { Select, SelectProps } from "antd";
import CompanyTypeComponent from "views/companyType";
import LocationsComponent from "views/locations";
import CountryComponent from "views/country";
import { validationSchema } from "./validationSchema";

interface OtpFormValues {
  digit1: string;
  digit2: string;
  digit3: string;
  digit4: string;
}

interface DropdownFieldProps {
  name: string;
  label?: string;
  placeholder: string;
  className?: string;
  options?: any[];
  setFieldValue: Function;
  value: any;
  defaultValue?: any;
  onChange?: (value: any) => void;
  radioDropDown?: boolean;
  allowClear?: boolean;
  children?: any;
  disabled?: boolean;
  bordered?: boolean;
  onSearch?: (searchVal: string) => void;
  showSearch?: boolean;
  filterOption?: SelectProps["filterOption"];
  onInputKeyDown?: React.KeyboardEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  loading?: boolean;
  mode?: "multiple" | "tags" | undefined;
}

// const DropdownField: FC<DropdownFieldProps> = (props) => {

const RegisterForm = () => {
  const navigate = useNavigate();
  const [isEmail, setIsEmail] = useState(true);
  const [iscompanyDetails, setIsCompanyDetails] = useState(false);
  const [isJobDetails, setIsJobDetails] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [adm, setAdm] = useState("");
  const [status, setStatus] = useState("");

  // let compInfo = localStorage.getItem('companyInfo')
  // const compData = compInfo ? JSON.parse(compInfo) : null;

  const [compData, setCompData] = useState();

  const [resOTP, setResOTP] = useState<string>("");
  // const [subOTP, setSubOTP] = useState('');
  const [err, setErr] = useState<string>("");
  const [cmpAdmErr, setCmpAdmErr] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const { data } = useContext<any>(ContextData);

  // console.log('token in registerform', data)

  const { registerUser, sendOtp, verifyOtp, registerCompanyAdm } = UserService();

  const { getCompanyTypes, companyTypes } = ManageCompanyTypes();

  const otpInitialValues: OtpFormValues = {
    digit1: "",
    digit2: "",
    digit3: "",
    digit4: "",
  };

  const jobType = [
    { label: "WFO", value: "WFO" },
    { label: "WFH", value: "WFH" },
    { label: "Hybrid", value: "Hybrid" },
  ];

  useEffect(() => {
    getCompanyTypes();
  }, []);

  

  const handleSubmit = async (values: any) => {
    const otp = values.digit1 + values.digit2 + values.digit3 + values.digit4;
    // Here you can perform the OTP verification logic

    const userData = await verifyOtp({ ...values, otp });
    if (userData?.status === "success") {
      LocalStorage.removeItem("COMPANY_ADMIN_INFO");
      LocalStorage.removeItem("COMPANY_INFO");
      navigate("/company-dashboard");
    } else {
      setErr("Something went wrong");
    }
  };

  const onSearch = (value: string) => {
    console.log("search:", value);
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const compAdmInfo = LocalStorage.getItem("COMPANY_ADMIN_INFO");
  const compInfo = LocalStorage.getItem("COMPANY_INFO");

  // const props = {
  //   action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
  //   onChange: handleChange,
  //   multiple: false,
  // };

  // function onSearch(value: string): void {
  //   throw new Error("Function not implemented.");
  // }

  const handlePartialValidation = async (values: any, setFieldError: any) => {
    const partialValidationSchema = Yup.object().shape({
      name: validationSchema.fields.name,
      email: validationSchema.fields.email,
      password: validationSchema.fields.password,
    });
    
    try {
      await partialValidationSchema.validate(values, { abortEarly: false });
      // Clear all field errors if validation is successful
      Object.keys(values).forEach((field) => {
        setFieldError(field, "");
      });
      return true; // Validation success

      // await partialValidationSchema.validate({
      //   name: values.name,
      //   email: values.email,
      // });

      // // Clear errors for the specified fields if validation succeeds
      // setFieldError('name', '');
      // setFieldError('email', '');

      // return true; // Validation success
    } catch (error: any) {
      // Handle validation errors for specified fields
      if (error.name === "ValidationError") {
        error.inner.forEach((validationError: any) => {
          setFieldError(validationError.path, validationError.message);
        });
      }

      return false; // Validation failure
    }
  };

  // console.log("companyTypes", companyTypes);
  

  return (
    <div>
      <main id="main">
        <section className="loginregister">
          <div className="companyloginregisterimg">
            <div className="container" data-aos="fade-up" data-aos-delay="100">
              <div className="row gy-4">
                <div className="col-lg-5 ">
                  <div className="Dynamic-data ">
                    <h3 className="">Welcome {compAdmInfo?.name}</h3>
                    <div>
                      <h4 className="text-white">Admin Details</h4>
                      <p>Admin Name: {compAdmInfo?.name}</p>
                      <p>Admin Email: {compAdmInfo?.email}</p>
                    </div>
                    <div>
                      <h4 className="text-white">Company Details</h4>
                      <p>Company Name: {compInfo?.companyName}</p>
                      <p>Company EMail: {compInfo?.companyEmail}</p>
                      <p>
                        Phone number:{" "}
                        {compInfo
                          ? compInfo?.companyIsdCode +
                            " " +
                            compInfo?.companyPhoneNumber
                          : null}
                      </p>
                      <p>
                        Address:{" "}
                        {compInfo
                          ? compInfo?.companyStreet +
                            ", " +
                            compInfo?.companyCity +
                            ", " +
                            compInfo?.companyCountry +
                            " - " +
                            compInfo?.companyPostalCode
                          : null}
                      </p>
                    </div>
                    {/* <div>
                      <h4 className="text-white">Details</h4>
                      <p>Name</p>
                      <p>Phone number</p>
                    </div> */}
                  </div>
                </div>
                <div className="col-lg-7">
                  <Formik
                    enableReinitialize
                    initialValues={{
                      companyName: "",
                      password: "",
                      email: "",
                      name: "",
                      companyDescription: "",
                      digit1: otpInitialValues.digit1,
                      digit2: otpInitialValues.digit2,
                      digit3: otpInitialValues.digit3,
                      digit4: otpInitialValues.digit4,
                      companyPhoneNumber: "",
                      companyWebsite: "",
                      companyStreet: "",
                      companyCity: "",
                      companyCountry: "",
                      state: "",
                      companyEmail: "",
                      companyPostalCode: "",
                      companyIsdCode: "",
                      companyProvince: "",
                      companyType: [],
                      companyLocation: "",
                    }}
                    validationSchema={validationSchema}
                    validateOnBlur
                    validateOnChange
                    // validate={(values) => {
                    //   const errors: any = {};
                    //   // console.log({ values });
                    //   if ((values.companyType || []).length === 0) {
                    //     errors.companyType = "Company Type Required";
                    //   }
                    //   if (values.companyLocation === "") {
                    //     errors.companyLocation =
                    //       "Please select at least one location";
                    //   }
                    //   if (values.companyCountry === "") {
                    //     errors.companyCountry =
                    //       "Please select at least one country";
                    //   }
                    //   if (values.companyDescription === "") {
                    //     errors.company_description =
                    //       "Company Description is required";
                    //   }
                    //   return errors;
                    // }}
                    onSubmit={async (values) => {

                      values.companyCity = values.companyLocation

                      
                      // console.log(values, "...values");
                      // console.log(
                      //   {
                      //     ...values,
                      //     role: "Company Admin",
                      //     company_location_id: values.companyLocation,
                      //   },
                      //   "formData"
                      // );

                      const data = await registerUser({
                        ...values,
                        role: "Company Admin",
                        company_location_id: values.companyLocation,
                      });

                      const { password, ...refinedInfo } = values;
                      LocalStorage.setItem("COMPANY_INFO", refinedInfo);

                      console.log(data, "...respData");

                      if (data?.status === "otp_verification_pending") {
                        setIsCompanyDetails(false);
                        setIsVerify(true);
                        setCmpAdmErr("");
                      } else {
                        // setCmpAdmErr(
                        //   "Something went wrong please fill the required fields in requested format"
                        // );
                        setCmpAdmErr(data?.message + ' ' +'Use another email account to register');
                      }
                    }}
                  >
                    {({
                      values,
                      setFieldValue,
                      submitForm,
                      errors,
                      setErrors,
                      isSubmitting,
                      setFieldError,
                    }) => (
                      <Form
                        className="php-email-form"
                        data-aos="fade-up"
                        data-aos-delay="200"
                      >
                        {successMessage ? (
                          <p className="text-info">{successMessage}</p>
                        ) : (
                          ""
                        )}
                        {cmpAdmErr ? (
                          <p className="text-danger">{cmpAdmErr}</p>
                        ) : (
                          ""
                        )}
                        {isEmail && (
                          <div className="row gy-4">
                            <h4>Company Admin details</h4>
                            <div className="col-md-8 m-80w">
                              <InputField className=""
                                placeholder="Your Name"
                                name="name"
                                value={values.name}
                              />
                            </div>
                            <div className="col-md-8 m-80w">
                              <InputField
                                placeholder="Password"
                                name="password"
                                value={values.password}
                                type="password"
                              />
                            </div>
                            <div className="col-md-8 m-80w">
                              <InputField
                                placeholder="Email"
                                name="email"
                                value={values.email}
                                type="email"
                              />
                            </div>
                            <div className="col-md-12 m-50w">
                              <button
                                className="submit"
                                // type="button"
                                onClick={async () => {
                                  const isPartialValid =
                                    await handlePartialValidation(
                                      values,
                                      setFieldError
                                    );

                                  if (isPartialValid) {
                                    setIsEmail(false);
                                    setIsCompanyDetails(true);
                                    const { password, ...refinedInfo } = values;
                                    LocalStorage.setItem(
                                      "COMPANY_ADMIN_INFO",
                                      refinedInfo
                                    );
                                  }
                                }}
                              >
                                Next Step
                              </button>
                            </div>
                            <div className="col-md-6 ">
                              <span className="continuewith">
                                <p>Or Continue with</p>
                              </span>
                              <div className="loginacc ">
                                <span
                                  className="loginacc-icon"
                                  style={{ color: "#ea4335" }}
                                >
                                  <i className="bi bi-google"></i>
                                </span>
                                <span
                                  className="loginacc-icon "
                                  style={{ color: "#4267b2" }}
                                >
                                  <i className="bi bi-facebook"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                        {isVerify && (
                          <div className="col-lg-7">
                            <div className="row gy-4">
                              <h4>Enter verification code sent to your mail</h4>
                              {err ? <p className="text-danger">{err}</p> : ""}
                              <Field
                                className="col-md-1 otp_field"
                                type="text"
                                name="digit1"
                                maxLength={1}
                              />
                              <ErrorMessage name="digit1" component="div" />
                              <Field
                                className="col-md-1 otp_field"
                                type="text"
                                name="digit2"
                                maxLength={1}
                              />
                              <ErrorMessage name="digit2" component="div" />
                              <Field
                                className="col-md-1 otp_field"
                                type="text"
                                name="digit3"
                                maxLength={1}
                              />
                              <ErrorMessage name="digit3" component="div" />
                              <Field
                                className="col-md-1 otp_field"
                                type="text"
                                name="digit4"
                                maxLength={1}
                              />
                              <ErrorMessage name="digit4" component="div" />
                              <button
                                className="submit"
                                type="button"
                                onClick={() => {
                                  handleSubmit(values);
                                }}
                              >
                                Verify OTP
                              </button>
                            </div>
                          </div>
                        )}
                        {iscompanyDetails && (
                          <div className="row gy-4">
                            <h4>Enter the details </h4>
                            <div className="col-md-5 m-50w">
                              <InputField
                                placeholder="Company Name"
                                name="companyName"
                                value={values?.companyName}
                              />
                            </div>
                            <div className="col-md-5 m-50w">
                              <InputField
                                placeholder="Mobile Number"
                                name="companyPhoneNumber"
                                value={values?.companyPhoneNumber}
                              />
                            </div>
                            <div className="col-md-10 ">
                            <CompanyTypeComponent  className="companyTypebar"
                              setFieldValue={setFieldValue}
                            />
                            
                            <ErrorMessage name="companyType">
                              {(msg) => (
                                <div className="input__error">{msg}</div>
                              )}
                            </ErrorMessage>
                            </div>
                            
                            {/* <Select  
                              showSearch
                              placeholder="Select a Role"
                              optionFilterProp="children"
                              value={values?.companyType}
                              // onChange={(e) => setFieldValue('gender', e.target.value)}
                              onChange={(value) =>
                                setFieldValue("companyType", value)
                              }
                              onSearch={onSearch}
                              filterOption={filterOption}
                              options={companyTypes}
                              style={{ width: "100%" }}
                            /> */}



                            <div className="col-md-5">
                              <InputField
                                placeholder="Company E-Mail"
                                name="companyEmail"
                                value={values?.companyEmail}
                              />
                            </div>

                            <div className="col-md-5">
                              <InputField
                                placeholder="Website ( https://www.google.com )"
                                name="companyWebsite"
                                value={values?.companyWebsite}
                              />
                            </div>
                            <div className="col-md-5">
                              <InputField
                                placeholder="Block Number, Street"
                                name="companyStreet"
                                value={values?.companyStreet}
                              />
                            </div>
                            <div className="col-md-5 m-50w">
                              <InputField
                                placeholder="Pin code"
                                name="companyPostalCode"
                                value={values?.companyPostalCode}
                              />
                            </div>

                            <div className="col-md-5 m-50w">
                              <CountryComponent
                                setFieldValue={setFieldValue}
                                singleJob={values}
                              />
                              <ErrorMessage name="companyCountry">
                                {(msg) => (
                                  <div className="input__error">{msg}</div>
                                )}
                              </ErrorMessage>
                            </div>

                            <div className="col-md-5 ">
                              <LocationsComponent
                                setFieldValue={setFieldValue}
                                singleJob={values}
                                countryId={values?.companyCountry}
                                name="companyCity"
                              />
                              <ErrorMessage name="companyLocation">
                                {(msg) => (
                                  <div className="input__error" >{msg}</div>
                                )}
                              </ErrorMessage>
                            </div>
                            {/* <div className="col-md-5">
                              <InputField
                                placeholder="City"
                                name="companyCity"
                                value={values?.companyCity}
                              />
                            </div> */}
                            

                            {/* <div className="col-md-5">
                              <InputField placeholder="State" name="state"
                                value={values?.state} />
                            </div> */}

                            <div className="col-md-5 m-50w">
                              <InputField
                                placeholder="Isd code"
                                name="companyIsdCode"
                                value={values?.companyIsdCode}
                              />
                            </div>
                            <div className="col-md-5 m-50w">
                              <InputField
                                placeholder="Province"
                                name="companyProvince"
                                value={values?.companyProvince}
                              />
                            </div>
                            <div className="col-md-10">
                              {/* <p>Company Description </p> */}

                              {/* <InputField
                                name="companyDescription"
                                placeholder="Company Description"
                                value={values?.companyDescription}
                              /> */}

                              <ReactQuill
                                theme="snow" // 'snow' is a standard theme, but you can choose other themes as well
                                placeholder="Company Description"
                                value={values?.companyDescription}
                                onChange={(value) =>
                                  setFieldValue("companyDescription", value)
                                }
                              />
                              <ErrorMessage name="companyDescription">
                                {(msg) => (
                                  <div className="input__error">{msg}</div>
                                )}
                              </ErrorMessage>
                            </div>
                            <div className="col-md-3 m-30w">
                              <button
                                className="submit"
                                type="button"
                                onClick={() => {
                                  setIsEmail(true);
                                  setIsCompanyDetails(false);
                                }}
                              >
                                Previous
                              </button>
                            </div>

                            <div className="col-md-6 m-30w">
                              <button className="submit nextsubmit" type="submit">
                                Next
                              </button>
                            </div>
                          </div>
                        )}
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <a
        href="#"
        id="scroll-top"
        className="scroll-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </a>
    </div>
  );
};

export default RegisterForm;
