import { Form, Formik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { LocalStorage } from 'shared/utils/localStorageHelpers';
import UserService from "services/AuthService/auth.service";
import InputField from "../../../shared/components/InputField";
import ContextData from "../../useContext";
import SuperAdminDashboadrPanel from "../shared/SuperAdminDashboardPanel";
import CompanyDashboadrPanel from "../shared";
import { cmpPwdValidateSchema } from "./cmpPwdValidateSchema";
// import "../login.scss"

const CompanyAdminChangePassword = () => {

      //Using Inline Function and the The Logical Not (!) to toggle state
      const [toggle, setToggle] = useState(false)

  //dash menu toggle
  const toggleContainer = useRef<HTMLDivElement>(null);


      
  const navigate = useNavigate()
  const { loginUser, superAdminlogin, companyAdminUpdatePassword } = UserService()
  const [msg, setMsg] = useState("")
  const [errMsg, setErrMsg] = useState("")
  const [loginErr, setLoginErr] = useState('')

  const userData = LocalStorage.getItem("USER").user

  console.log('userData', userData)

  const { data, setData } = useContext<any>(ContextData);

  const location = useLocation()

  const currentPath = location.pathname

  console.log(currentPath)

  useEffect(() => {
   
    const handleClickOutside = (event: MouseEvent) => {
      if (toggleContainer.current && !toggleContainer.current.contains(event.target as Node)) {
        setToggle(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up event listeners
      document.removeEventListener("mousedown", handleClickOutside);
    };


  }, [toggle]);

  return (
    <>
      <div className="mt-50"></div>
      <main id="main">
        <div className="page-title">
          <nav className="breadcrumbs">
            <div className="container">
              <ol>
                <li>
                  <a href="index.php">Home</a>
                </li>
                <li className="current">Change Password</li>
              </ol>
            </div>
          </nav>
        </div>
        <section className="f0f5f3">
          <div className="container">



            <div className="row gy-4">

                    <div className="col-md-3">



                    <div className='mobilesidenavmenu'  ref={toggleContainer}>
                        <button className='sidenavmenu btn sidenavmenubtn  mb-5' onClick={() => setToggle(!toggle)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-bar-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M6 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L12.293 7.5H6.5A.5.5 0 0 0 6 8m-2.5 7a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5"/>
                          </svg>
                          </button>
                            {
                              toggle &&
                               <div className="cmp-side-nav ">
                                  <CompanyDashboadrPanel />
                                </div>
                            }   
                           </div>
                          
            
                <div className="cmp-side-nav  desktopsidenavmenu">
                 <CompanyDashboadrPanel />
                </div>

                    </div>

              <div className="col-lg-9">
                <span style={{ color: 'green' }}>{msg ? msg : ''}</span>
                <Formik
                  enableReinitialize
                  initialValues={{
                    email: userData?.email,
                    password: "",
                    new_password: "",
                  }}
                  validationSchema={cmpPwdValidateSchema}
                  onSubmit={async (values) => {

                    // console.log(values, '...values')
                    // if(currentPath === "/login"){
                    //    info = await loginUser(values)
                    //    console.log(info, '...userINFO')
                    //    if (info) {
                    //     setData(info.data)
                    //     navigate("/company-dashboard", { state: { data: info?.data } })
                    //   } else {
                    //     setLoginErr('invalid credentials or user not found ')
                    //   }
                    // }else{
                    const info = await companyAdminUpdatePassword(values)

                    // console.log('info', info)

                    if (info) {
                      setMsg('Password updated successfully')
                      setErrMsg('')

                    } else {
                      setErrMsg('Invalid credentials')
                      setMsg("")
                    }
                    // }
                    // if (info) {
                    //   setData(info.data)
                    //   navigate("/company-dashboard", { state: { data: info?.data } })
                    // } else {
                    //   setLoginErr('invalid credentials ')
                    // }

                  }}
                >
                  {({ values, setFieldValue }) => (
                    <Form
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      <h4>Enter the details</h4>
                      {loginErr && <div className="alert alert-danger">{loginErr}</div>}

                      <div className="col-md-6 field">
                        <InputField name="email" placeholder="Your Email" value={userData?.email} disabled={true} />
                      </div><div className="col-md-6 "></div>
                      <div className="col-md-6 field">
                        <InputField
                          placeholder="Current Password"
                          name="password"
                          type="password"
                        />
                      </div><div className="col-md-6 "></div>

                      <div className="col-md-6 field">
                        <InputField
                          placeholder="New Password"
                          name="new_password"
                          type="password"
                        />
                      </div>

                      <div className="col-md-12 field">
                        <button type="submit" className="submit">Update</button>
                        {/* <a href="/register" style={{paddingLeft:"30px"}}>Need Accout?, Signup here</a> */}
                        {/* <Link className="btn-getstarted" to="/register" style={{ paddingLeft: '30px' }}> Don't have account? Signup here</Link> */}
                      </div>
                    </Form>
                  )}
                </Formik>
                {/* </div> */}
              </div>
            </div>



            </div>
        </section>
      </main>
    </>
  );
};

export default CompanyAdminChangePassword;
