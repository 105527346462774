import React, { useState, useContext } from "react";
import axiosInstance from "interceptor/axiosInstance";
import { Token } from "models/token.model";
import { JobSeekerUser, User } from "models/user.model";
import { useNavigate } from "react-router-dom";
import { ApiRoutes } from "routes/routeConstants/apiRoutes";
import { deserialize, serialize } from "serializr";
import { LocalStorage } from "shared/utils/localStorageHelpers";
import ContextData from "views/useContext";
// import ContextData from 'views/useContext';

const JbSeekerService = () => {

    const navigate = useNavigate();
    const { setData } = useContext<any>(ContextData);
    const [error, setError] = useState<Error | unknown>();
    const [loading, setLoading] = useState(false);
    const [companyTypes, setCompanyTypes] = useState<any>([])
    const [jobSeekersList, setJobSeekerslist] = useState<any>([])
    const [singleSkill, setSingleSkill] = useState<any>()
    // const [singleJobFunction, setSingleJobFunction] = useState<any>()


    // const getCompanyTypes = async () => {
    //     try {
    //         setLoading(true);
    //         const desiredData = await axiosInstance.get(
    //             ApiRoutes.COMP_TYPES
    //         );
    //         // const desiredData = deserialize(User, desiredInfo.data?.results as User[]);
    //         // setCompanyEmployees(desiredData);

    //         console.log('companyTypeData', desiredData?.data?.Company_types)
    //         setCompanyTypes(desiredData?.data?.Company_types)
    //         return desiredData?.data?.Company_types;
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    const getJobSeekersList = async () => {
        try {
            setLoading(true);
            const desiredData = await axiosInstance.get(
                ApiRoutes.JOB_SEEKER_REGISRATION
            );
            // const desiredData = deserialize(User, desiredInfo.data?.results as User[]);
            // setCompanyEmployees(desiredData);

            // console.log('companyTypeData', desiredData?.data?.results)
            setJobSeekerslist(desiredData?.data?.results)
            return desiredData?.data;
        } catch (error) {
            console.log(error);
        }
    };

    

    

    // const getSingleJobPost = async (jobID: number) => {
    //     try {
    //         setLoading(true);
    //         const desiredInfo = await axiosInstance.get(
    //             ApiRoutes.POST_JOB + `/${jobID}`
    //         );

    //         // const desiredData = deserialize(User, desiredInfo.data as User);
    //         // setSingleCompanyEmployees(desiredData);

    //         console.log('jobList', desiredInfo?.data)


    //         // setJobs(desiredInfo?.data)
    //         setSingleJob(desiredInfo?.data)
    //         return desiredInfo;
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    // const updateJobPost = async (jobID: number, job: any) => {

    //     try {
    //         delete job?.id

    //         console.log('jobID', jobID)
    //         console.log('job', job)

    //         setLoading(true);
    //         const desiredInfo = await axiosInstance.put(
    //             ApiRoutes.POST_JOB + `/${jobID}`, job
    //         );

    //         console.log('desiredInfo', desiredInfo)
    //         return desiredInfo?.data;
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    // const deleteJobPost = async (jobID: number) => {
    //     try {
    //         setLoading(true);
    //         const desiredInfo = await axiosInstance.delete(
    //             ApiRoutes.POST_JOB + `/${jobID}`
    //         );
    //         return desiredInfo?.data;
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };



    return {
        loading,
        jobSeekersList,
        getJobSeekersList,
    };
};

export default JbSeekerService;
