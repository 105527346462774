import React, {useContext} from "react";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import ContextData from "views/useContext";

const Dashboard = () => {

  const { data } = useContext<any>(ContextData);

    // const navigate = useNavigate()
    // const location = useLocation()

    console.log('token in dashboard', data)

  

    

  

    return (
        <main id="main">
      <section id="loginregister" className="loginregister">
        <div>
            Welcome to Dashboard
        </div>
      </section>
    </main>
    )

}

export default Dashboard;