import * as Yup from "yup";

export const validationSchema = Yup.object({
    user_name: Yup.string().required('Name is required').min(3, 'Name must be at least 3 characters'),
    password: Yup.string().required('Password is required')
              .min(8, 'Password must be at least 8 characters long')
              .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
              .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
              .matches(/^(?=.*[0-9])/,'Password must contain at least one number')
              .matches(/^(?=.*[!@#$%^&*])/,'Password must contain at least one special character'),
    email: Yup.string().email('Invalid email format').required('Email is required').test(
      'valid-domain',
      'Invalid domain extension',
      (value) => {
        // Custom validation for domain extension
        if (value) {
          const domainRegex = /^[A-Za-z0-9._%+-]+@([A-Za-z0-9-]+\.)+[A-Za-z]{2,}$/;
          return domainRegex.test(value);
        }
        return true; // Allow empty values, Yup's required handles that
      }
    ),
    phone_number: Yup.string().required('Phone number is required'),
    isdNumber: Yup.string().required('ISD No required'),


    address: Yup.string().required('Please fill the address'),
    // country: Yup.string().required('Required'),
    // state: Yup.string().required('Required'),
    // pinCode: Yup.string().required('Required'),
    // jobRole: Yup.string().required('Required'),
    // industry: Yup.string().required('Required'),
    // state: Yup.string().required('Required'),
    // companyEmail: Yup.string().email('Invalid email format').required('Email is required').test(
    //   'valid-domain',
    //   'Invalid domain extension',
    //   (value) => {
    //     // Custom validation for domain extension
    //     if (value) {
    //       const domainRegex = /^[A-Za-z0-9._%+-]+@([A-Za-z0-9-]+\.)+[A-Za-z]{2,}$/;
    //       return domainRegex.test(value);
    //     }
    //     return true; // Allow empty values, Yup's required handles that
    //   }
    // ),
    // preferredWork: Yup.string().required('Required'),
    // experience: Yup.string().required('Required'),
    // salaryRange: Yup.string().required('Required'),
    // jobLocation: Yup.array().min(1, 'Select at least one type').required('Required'),
    // applicationDeadLine: Yup.string().required('Required'),
    // resume: Yup.string().required('Required'),    
    // dateOfBirth: Yup.string().required('Required'),






    // bio: Yup.string().required('Required'),
    // gender: Yup.string().required("Please select at least one option"),
    termsAndConditions: Yup.boolean()
              .required("You must accept the terms and conditions to proceed.")
              .oneOf([true], "You must accept the terms and conditions to proceed."),
    companyLocation: Yup.string().required("Location is Required"),
    companyCountry: Yup.string().required("Country is Required")







  });
  