import * as Yup from "yup";

export const jobseekerProfileUpdateSchema = Yup.object().shape({
  user_name: Yup.string().required("User Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  isd_code: Yup.string().required("ISD Code is required"),
  phone_number: Yup.string().required("Phone Number is required"),
  bio: Yup.string().required("Summary is Required"),
  date_of_birth: Yup.string().required("DOB is required"),
  education_level: Yup.string().required("Education Level is required"),
  degree_name: Yup.string().required("Degree Name is required"),
  college_name: Yup.string().required("College Name is required"),
  university: Yup.string().required("University is required"),
  specialization: Yup.string().required("Specialization is required"),
  degree_type: Yup.array()
      .min(1, "Please select at least one option")
      .required("Education Type selection is required"),
  start_date: Yup.string().required("Start Date is required"),
  end_date: Yup.string().required("End Date is required"),
  // certification_one: Yup.string().required("certification 1 is required"),

  
  certification_one: Yup.array()
    .min(1, "Please select at least one certification")
    .required("Type selection is required"),
  

  certification_two: Yup.string().required("certification 2 is required"),
  portfolio: Yup.string().required("Portfolio URL is required"),
  linkedin: Yup.string().required("LinkedIn URL is required"),
  location_one: Yup.string().required("Location 1 is required"),
  location_two: Yup.string().required("Location 2 is required"),
  location_three: Yup.string().required("Location 3 is required"),
  job_type: Yup.string().required("Job Type is Required"),
  salary_expect: Yup.string().required("Salary Expectations is required"),
  languages: Yup.string().required("Languages is required"),
  available_date: Yup.string().required("Availability Date is required"),
  trail_blaizer_profile: Yup.string().required(
    "Trail Blaizer Profile is required"
  ),
  street: Yup.string().required("Address is required"),
  province: Yup.string().required("Province is required"),
  companyLocation: Yup.string().required("Please select at least one option"),
  companyCountry: Yup.string().required("Please select at least one option"),
  postal_code: Yup.string().required("Pin code is required"),

  // Add validation rules for other fields as needed
  // ...

  // Example validation for resume file size and format
  resume: Yup.mixed()
    .test("fileSize", "File size too large", function (value) {
      // Explicitly define the type of 'value' as File | undefined
      if (value instanceof File) {
        return value.size <= 1048576; // 1 MB
      }
      return true; // Allow undefined or non-File values
    })
    .test("fileFormat", "Invalid file format", function (value) {
      // Explicitly define the type of 'value' as File | undefined
      if (value instanceof File) {
        const fileFormat: any = (value.name ?? "")
          .split(".")
          .pop()
          ?.toLowerCase();

        // const fileFormat = value.name.split('.').pop().toLowerCase();
        return ["pdf", "doc", "docx"].includes(fileFormat);
      }
      return true; // Allow undefined or non-File values
    }),

  // Example validation for display_picture file size and format
  display_picture: Yup.mixed()
    .test("fileSize", "File size too large", function (value) {
      // Explicitly define the type of 'value' as File | undefined
      if (value instanceof File) {
        return value.size <= 1048576; // 1 MB
      }
      return true; // Allow undefined or non-File values
    })
    .test("fileFormat", "Invalid file format", function (value) {
      // Explicitly define the type of 'value' as File | undefined
      if (value instanceof File) {
        const fileFormat: any = (value.name ?? "")
          .split(".")
          .pop()
          ?.toLowerCase();

        // const fileFormat = value.name.split('.').pop().toLowerCase();
        return ["pdf", "doc", "docx"].includes(fileFormat);
      }
      return true; // Allow undefined or non-File values
    }),
});
