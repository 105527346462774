import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import UserService from "services/AuthService/auth.service";
import { LocalStorage } from "shared/utils/localStorageHelpers";
import CompanyDashboadrPanel from "../shared";
import { useNavigate } from "react-router-dom";

const CompanyProfile = () => {

  const navigate = useNavigate();

    //Using Inline Function and the The Logical Not (!) to toggle state
    const [toggle, setToggle] = useState(false)

  const currentUser = LocalStorage.getItem("USER")
  const { getCompanyAdm, singleUser } = UserService()
  const data = LocalStorage.getItem("USER")
  //dash menu toggle
  const toggleContainer = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    getCompanyAdm(currentUser?.user.id)

    const handleClickOutside = (event: MouseEvent) => {
      if (toggleContainer.current && !toggleContainer.current.contains(event.target as Node)) {
        setToggle(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up event listeners
      document.removeEventListener("mousedown", handleClickOutside);
    };

  }, [toggle])

  console.log('data', singleUser)

  return (
    <>
      <div className="mt-50">
      </div>
      <main id="main">
        <div className="page-title">
          <nav className="breadcrumbs">
            <div className="container">
              <ol>
                <li>
                  <a href="index.php">
                    Home
                  </a>
                </li>
                <li className="current">
                  Profile
                </li>
              </ol>
            </div>
          </nav>
        </div>
        <section className="f0f5f3">
          <div className="container">
            <div className="row">
              <div className="col-md-3">        
                      
                      <div className='mobilesidenavmenu'  ref={toggleContainer}>
                        <button className='sidenavmenu btn sidenavmenubtn  mb-5' onClick={() => setToggle(!toggle)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-bar-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M6 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L12.293 7.5H6.5A.5.5 0 0 0 6 8m-2.5 7a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5"/>
                          </svg>
                          </button>
                            {
                              toggle &&
                               <div className="cmp-side-nav ">
                                  <CompanyDashboadrPanel />
                                </div>
                            }   
                           </div>
                          
            
                <div className="cmp-side-nav  desktopsidenavmenu">
                 <CompanyDashboadrPanel />
                </div>


                
              </div>
              <div className="col-md-9 padding-left-2rem pbg">
                <div className="row gy-4">
                  {/* <h4>
                    Profile
                  </h4>
                  <div className="companyprofile-info">
                    <img src="assets/img/Companylogos/Companylogos1.png" alt="Salesforce Logo"
                    className="img-fluid" />
                    <h5>
                      {singleUser?.company_data?.company_name}
                    </h5>
                  </div> */}
                  <div className="Description">
                    <h4>
                      Company Description 
                      <span onClick={() => navigate('/company-account-settings')}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-pencil penciledit" viewBox="0 0 16 16">
                          <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"/>
                        </svg>
                        </span>
                    </h4>
                    <p>
                      {/* {singleUser?.company_data?.company_description} */}
                      <p dangerouslySetInnerHTML={{ __html: singleUser?.company_data?.company_description }} />
                    </p>
                  </div>
                  <div className="col-md-5">
                    <p><i className="bi bi-globe2"></i> {singleUser?.company_data?.company_website}</p>
                  </div>
                  <div className="col-md-4">
                    <p><i className="bi bi-mailbox"></i>{singleUser?.company_data?.company_email}</p>
                  </div>
                  <div className="col-md-3">
                    <p><i className="bi bi-phone"></i>{singleUser?.company_data?.company_phone_number}</p>
                  </div>
                  <div className="col-md-12">
                    <div className="Description">
                      {/* <p> {singleUser?.company_data?.company_history}</p> */}
                      <p dangerouslySetInnerHTML={{ __html: singleUser?.company_data?.company_history }} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <p><i className="bi bi-person-gear"></i>{singleUser?.company_data?.products}</p>
                  </div>
                  <div className="col-md-4">
                    <p><i className="bi bi-building-fill-add"></i>{singleUser?.company_data?.company_size}</p>
                  </div>
                  <div className="col-md-4">
                    <p><i className="bi bi-phone"></i>{singleUser?.company_data?.company_phone_number}</p>
                  </div>
                  <div className="col-md-4">
                    <p><i className="bi bi-journal-medical"></i> Pharma Industry</p>
                  </div>
                  <div className="col-md-4">
                    <p><a href=""><i className="bi bi-facebook"></i></a>{singleUser?.company_data?.social_media_link_1}</p>
                  </div>
                  <div className="col-md-4">
                    <p> <a href=""><i className="bi bi-instagram"></i></a>{singleUser?.company_data?.social_media_link_2}</p>
                  </div>
                  {/* <div className="col-md-4">
                      <p><a href=""><i className="bi bi-linkedin"></i></a>cloudtechjob</p>
                  </div>
                  <div className="col-md-4">
                     <p> <a href=""><i className="bi bi-twitter"></i></a>cloudtechjob</p>
                  </div> */}



                  <div className="">
                    <h4>
                      Company Location 
                      <span onClick={() => navigate('/company-account-settings')}><svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-pencil penciledit" viewBox="0 0 16 16">
                          <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"/>
                        </svg></span>
                    </h4>
                    <p>
                      Elevate your skills with our Salesforce training programs. Expert guidance
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p>Location  : {singleUser?.company_data?.company_location?.name}</p>
                  </div>
                  <div className="col-md-4">
                    <p>Pincode : {singleUser?.company_data?.company_postal_code}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

export default CompanyProfile