import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import React, { useState, useContext } from "react";
import InputField from "../../../shared/components/InputField";
import * as Yup from 'yup';
import "./registerForm.scss";
import UserService from "services/AuthService/auth.service";
import { LocalStorage } from "shared/utils/localStorageHelpers";
import { Navigate, useNavigate } from "react-router-dom";
import DropdownField from "shared/components/DropdownField";
import ContextData from "views/useContext";
import { MD5 } from "crypto-js";
import { date } from "serializr";
import { Button, Checkbox, Select, Tooltip, Upload, UploadFile, UploadProps } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { resumeObject } from "models/user.model";
import LocationsComponent from "views/locations";
import CountryComponent from "views/country";
import { validationSchema } from "./validationSchema";

interface OtpFormValues {
  digit1: string;
  digit2: string;
  digit3: string;
  digit4: string;
}

const JobSeekerRegistration = () => {
  const navigate = useNavigate();
  const [isEmail, setIsEmail] = useState(true);
  const [iscompanyDetails, setIsCompanyDetails] = useState(false);
  const [isJobDetails, setIsJobDetails] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  // const [adm, setAdm] = useState("");
  // const [resume, setResume] = useState<resumeObject>()
  const [resume, setResume] = useState<any>()
  // const [termsAndConditions, setTermsAndConditions] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  // const [resOTP, setResOTP] = useState<string>("");
  // const [subOTP, setSubOTP] = useState('');
  const [err, setErr] = useState("");
  const [customErr, setCustomErr] = useState("");
  const [cmpAdmErr, setCmpAdmErr] = useState("");
  // const [gender, setGender] = useState("");



  const { data } = useContext<any>(ContextData);

  // console.log("token in registerform", data);

  const {
    registerJobSeeker,
    jobSeekerVerifyOtp,
  } = UserService();

  const otpInitialValues: OtpFormValues = {
    digit1: "",
    digit2: "",
    digit3: "",
    digit4: "",
  };

  const jobType = [
    { label: "WFO", value: "WFO" },
    { label: "WFH", value: "WFH" },
    { label: "Hybrid", value: "Hybrid" },
  ];

  const userInfo = LocalStorage.getItem('JOBSEEKER_INFO')
  const userInfoOne = LocalStorage.getItem('JOBSEEKER_INFO_ONE')

  const handleSubmit = async (values: any) => {
    const otp = values.digit1 + values.digit2 + values.digit3 + values.digit4;
    // Here you can perform the OTP verification logic

    const userData = await jobSeekerVerifyOtp({ ...values, otp })
    if (userData?.message === "OTP verified successfully.") {
      LocalStorage.removeItem('JOBSEEKER_INFO')
      LocalStorage.removeItem('JOBSEEKER_INFO_ONE')
      navigate('/job-seeker-dashboard')
    }
    else {
      setErr("Something went wrong")
    }
  };





  // const onChange = (e: CheckboxChangeEvent) => {
  //   console.log(`checked = ${e.target.checked}`);
  //   setTermsAndConditions(e.target.checked)
  // };

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleChange: UploadProps['onChange'] = (info) => {

    // console.log('FileInfo:', info);
    // console.log('FileList:', info.fileList);
    // console.log('File:', info.file);

    // setResume(info.file.name)
    // setResume(info.file)

    if (info.file && info.file.originFileObj) {
      setResume(info.file.originFileObj);
    }



    let newFileList = [...info.fileList];
    if (info.fileList.length > 0 && newFileList.length > 0) {

      // 1. Limit the number of uploaded files
      // Only to show two recent uploaded files, and old ones will be replaced by the new
      newFileList = newFileList.slice(-2);

      // 2. Read from response and show file link
      newFileList = newFileList.map((file) => {
        // console.log(file, "...fiel")
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.url;
        }
        return file;
      });
    }

    setFileList(newFileList);
  };

  // const handleFileChange = async (e:any) => {
  //   console.log('fileFormat', typeof(e.target.files))
  //   const selectedFile = await e.target.files[0];
  //   setResume(selectedFile);
  // };

  // const onSelectChange = (value: string) => {
  //   console.log(`selected ${value}`);
  //   setGender(value)
  // };

  const onSearch = (value: string) => {
    console.log('search:', value);
  };



  // Filter `option.label` match the user type `input`
  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const props = {
    action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    onChange: handleChange,
    multiple: false,
  };

  const handlePartialValidation = async (values: any, setFieldError: any) => {

    // Validate only specific fields
    const partialValidationSchema = Yup.object().shape({
      user_name: validationSchema.fields.user_name,
      email: validationSchema.fields.email,
      password: validationSchema.fields.password,
      isdNumber: validationSchema.fields.isdNumber,
      phone_number: validationSchema.fields.phone_number,
    });

    try {

      await partialValidationSchema.validate(values, { abortEarly: false });

      // Clear all field errors if validation is successful
      Object.keys(values).forEach((field) => {
        setFieldError(field, '');
      });
      return true; // Validation success

    } catch (error: any) {

      // Handle validation errors for specified fields
      if (error.name === 'ValidationError') {
        error.inner.forEach((validationError: any) => {
          setFieldError(validationError.path, validationError.message);
          // console.log(`Field: ${validationError.path}, Error: ${validationError.message}`);          
        });
      }

      return false; // Validation failure
    }
  };

  // console.log({gender})


  return (

    <div>
      <main id="main">
        <section id="loginregister" className="loginregister">
          <div className="jobloginregisterimg">
            <div className="container" data-aos="fade-up" data-aos-delay="100">
              <div className="row gy-4">
                <div className="col-lg-5 ">
                  <div className="Dynamic-data ">
                    <h3 className="">Welcome {userInfo?.user_name}</h3>
                    <div>
                      <h4 className="text-white">User Details</h4>
                      <p>Name: {userInfo?.user_name}</p>
                      <p>E-Mail: {userInfo?.email}</p>
                      <p>Phone number: {userInfo ? userInfo?.isdNumber + ' ' + userInfo?.phone_number : null}</p>
                      <p>DOB: {userInfoOne?.dateOfBirth}</p>
                      <p>Address: {userInfoOne?.address}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7">
                  <Formik
                    enableReinitialize
                    initialValues={{
                      // userName: "",
                      user_name: "",
                      email: "",
                      password: "",
                      phone_number: "",
                      companyEmail: "",
                      address: "",
                      country: "",
                      state: "null",
                      pinCode: "",
                      jobRole: "",
                      industry: "",
                      preferredWork: "",
                      experience: "",
                      salaryRange: "",
                      jobLocation: "",
                      applicationDeadLine: "",
                      digit1: otpInitialValues.digit1,
                      digit2: otpInitialValues.digit2,
                      digit3: otpInitialValues.digit3,
                      digit4: otpInitialValues.digit4,
                      resume: "",
                      isdNumber: "",
                      // skills: ['c++'],
                      dateOfBirth: "",
                      trailBlaizerProfile: "",
                      gender: "",
                      bio: "",
                      termsAndConditions: false,
                      companyLocation: "",
                      companyCountry: "",

                    }}                    
                    validationSchema={validationSchema}
                    validateOnBlur
                    validateOnChange
                    validate={(values: any) => {
                      const errors: any = {};
                      console.log({values})

                      // values.gender = gender;
                      
                      // if (values.companyLocation === "") {
                      //   errors.companyLocation =
                      //     "Please select at least one location";
                      // }
                      // if (values.companyCountry === "") {
                      //   errors.companyCountry =
                      //     "Please select at least one country";
                      // }
                      // if (values?.gender === "") {
                      //   errors.gender = "Please select at least one option";
                      // }
                      // if (values?.termsAndConditions === false) {
                      //   errors.termsAndConditions = "Please choose the Terms and Conditions";
                      // }

                      return errors;
                    }}

                    
                    onSubmit={async (values) => {

                      
                      console.log('welcome')
                      // console.log({ values })


                      // Create a new FormData instance
                      const formData = new FormData();

                      // Append form fields to FormData
                      Object.entries(values).forEach(([key, value]) => {
                        formData.append(key, typeof value === 'boolean' ? String(value) : value);
                      });




                      if (resume) {
                        // Assuming 'resume' is a File object obtained from a file input
                        const allowedFormats = ['pdf', 'doc', 'docx']; // Add or modify allowed file formats
                        const maxFileSizeInBytes = 1 * 1024 * 1024; // 1 MB, adjust as needed

                        // Check file format
                        const fileName = resume.name;
                        const fileFormat = fileName.split('.').pop().toLowerCase();
                        if (!allowedFormats.includes(fileFormat)) {
                          setCustomErr('Invalid file format. Please upload a PDF, DOC, or DOCX file.');
                          return; // or handle the error accordingly
                        }

                        // Check file size
                        if (resume.size > maxFileSizeInBytes) {
                          setCustomErr('File size exceeds the maximum allowed size (5 MB).');
                          return; // or handle the error accordingly
                        }

                        // If validation passes, append the file to form data
                        formData.append("resume", resume);
                      }



                      // formData.append('terms_accepted_at', termsAndConditions ? '1' : '0');

                      // formData.append('termsAndConditions', String(termsAndConditions));
                      // formData.append('gender', gender);
                      formData.append('country', values?.companyCountry);
                      formData.append('location_id', values?.companyLocation);
                      formData.append('active', '1')

                      console.log(...formData, '...form data')

                      // const desiredValues = { ...values, resume, termsAndConditions, gender, country: values?.companyCountry, location_id: values?.companyLocation, }

                      // delete values.companyCountry

                      // registerJobSeeker(desiredValues);

                      try {

                        // const response = await axios.post("http://localhost:8000/api/v1/job_seeker_management/register", formData);
                        // console.log(response.data);
                        registerJobSeeker(formData);
                      } catch (error) {
                        console.error("Error uploading form data:", error);
                      }





                      // registerJobSeeker(formData)
                      setIsVerify(true)
                      setIsCompanyDetails(false)
                      const { password, ...refinedInfo } = values
                      LocalStorage.setItem('JOBSEEKER_INFO_ONE', refinedInfo)

                    }}
                  >
                    {({ values, setFieldValue, setErrors, setFieldError, errors, handleChange }) => (


                      <Form
                        className="php-email-form"
                        data-aos="fade-up"
                        data-aos-delay="200"
                        encType="multipart/form-data"
                      >
                        {successMessage ? (
                          <p className="text-info">{successMessage}</p>
                        ) : ''}
                        {isEmail && (
                          <div className="row gy-4">
                            <h4>Jobseeker Registration</h4>
                            {cmpAdmErr ? (
                              <p className="text-danger">{cmpAdmErr}</p>
                            ) : (
                              ""
                            )}
                            <div className="col-md-6">
                              <InputField
                                name="user_name"
                                placeholder="User Name"
                                value={values?.user_name}
                              />
                              {errors?.user_name && <p className="input__error">{errors?.user_name}</p>}
                            </div><div className="col-md-6"></div>

                            {/* {errors.userName && <div className="input__error">{errors.userName}</div>} */}
                            <div className="col-md-6">
                              <InputField placeholder="Email" name="email" type="email" value={values?.email} />
                              {errors?.email && <p className="input__error">{errors?.email}</p>}
                            </div><div className="col-md-6"></div>
                            <div className="col-md-6">
                              <InputField
                                placeholder="Password"
                                name="password"
                                type="password"
                                value={values?.password}
                              />
                              {errors?.password && <p className="input__error">{errors?.password}</p>}
                            </div><div className="col-md-6"></div>
                            <div className="row form-group mt-10" style={{ marginTop: 15 }}>
                              <div className="col-md-2 m-30w">
                                <InputField
                                  placeholder="ISD Number"
                                  name="isdNumber"
                                  value={values?.isdNumber}
                                />
                                {errors?.isdNumber && <p className="input__error">{errors?.isdNumber}</p>}
                              </div>
                              <div className="col-md-4 m-70w">
                                <InputField
                                  placeholder="Mobile Number"
                                  name="phone_number"
                                  value={values?.phone_number}
                                />
                                {errors?.phone_number && <p className="input__error">{errors?.phone_number}</p>}
                              </div>
                            </div>
                            <div className="col-md-12 ">
                              <button
                                className="submit"
                                type="button"
                                onClick={async () => {
                                  const isPartialValid = await handlePartialValidation(values, setFieldError);

                                  // console.log('isPartialValid', isPartialValid)
                                  if (isPartialValid) {
                                    setIsEmail(false);
                                    setIsCompanyDetails(true);
                                    const { password, ...refinedInfo } = values;
                                    LocalStorage.setItem('JOBSEEKER_INFO', refinedInfo);
                                  }
                                }}
                              >
                                Next Step
                              </button>
                            </div>
                            {/* <div className="col-md-6 ">
                              <span className="continuewith">
                                <p>Or Continue with</p>
                              </span>
                              <div className="loginacc ">
                                <span
                                  className="loginacc-icon"
                                  style={{ color: "#ea4335" }}
                                >
                                  <i className="bi bi-google"></i>
                                </span>
                                <span
                                  className="loginacc-icon "
                                  style={{ color: "#4267b2" }}
                                >
                                  <i className="bi bi-facebook"></i>
                                </span>
                              </div>
                            </div> */}
                          </div>
                        )}
                        {isVerify && (
                          <div className="col-lg-7">
                            <div className="row gy-4">
                              <h4>Enter verification code sent to your mail</h4>
                              {err ? <p className="text-danger">{err}</p> : ""}
                              <Field
                                className="col-md-1 otp_field"
                                type="text"
                                name="digit1"
                                maxLength={1}
                              />
                              <ErrorMessage name="digit1" component="div" />

                              <Field
                                className="col-md-1 otp_field"
                                type="text"
                                name="digit2"
                                maxLength={1}
                              />
                              <ErrorMessage name="digit2" component="div" />
                              <Field
                                className="col-md-1 otp_field"
                                type="text"
                                name="digit3"
                                maxLength={1}
                              />
                              <ErrorMessage name="digit3" component="div" />
                              <Field
                                className="col-md-1 otp_field"
                                type="text"
                                name="digit4"
                                maxLength={1}
                              />
                              <ErrorMessage name="digit4" component="div" />
                              <button
                                className="submit"
                                type="button"
                                onClick={() => {
                                  handleSubmit(values);
                                }}
                              >
                                Verify OTP
                              </button>
                            </div>
                          </div>
                        )}
                        {iscompanyDetails && (
                          <div className="row gy-4">
                            {/* <h4>Upload your resume</h4> */}

                            <div className="col-md-4 m-50w input-field Uploadtheresume">
                              <Upload {...props} fileList={fileList} name="resume">
                                <Button icon={<UploadOutlined />}>Upload the resume</Button>
                              </Upload>
                              <span className="error-input">{customErr ? customErr : null}</span>
                            </div>

                            {/* <div className="col-md-5">
                              <InputField
                                type="file"
                                placeholder="Upload Resume"
                                name="resume"
                                onChange={handleFileChange}
                              />
                            </div> */}



                            <div className="col-md-4 m-50w">
                              <Tooltip title="Date of Birth">
                                <InputField
                                  type="date"
                                  placeholder="Date of Birth"
                                  name="dateOfBirth"
                                  value={values?.dateOfBirth}
                                />
                              </Tooltip>
                            </div>

                            {/* <div className="col-md-8">
                              <InputField
                                placeholder="Skills"
                                name="skills"
                              />
                            </div> */}
                            <div className="col-md-8">
                              <InputField
                                placeholder="address"
                                name="address"
                                value={values?.address ? values?.address : ""}
                              />
                            </div><div className="col-md-4"></div>

                            <div className="row countryinputs">

                              {/* <div className="col-md-4">
                              <LocationsComponent
                                setFieldValue={setFieldValue}
                                singleJob={values ? values : ''} 
                                />
                            </div>

                            <div className="col-md-4 ">
                            <div className="input-align">
                              <CountryComponent 
                                setFieldValue={setFieldValue}
                                singleJob={values}
                              />
                              </div>
                            </div> */}



                              <div className="col-md-4">
                                <CountryComponent
                                  setFieldValue={setFieldValue}
                                  singleJob={values}
                                />
                                <ErrorMessage name="companyCountry">
                                  {(msg) => (
                                    <div className="input__error">{msg}</div>
                                  )}
                                </ErrorMessage>
                              </div>


                              <div className="col-md-4">
                                <LocationsComponent
                                  setFieldValue={setFieldValue}
                                  singleJob={values ? values : ""}
                                  countryId={values?.companyCountry}
                                />
                                <ErrorMessage name="companyLocation">
                                  {(msg) => (
                                    <div className="input__error">{msg}</div>
                                  )}
                                </ErrorMessage>
                              </div>






                            </div>


                            <div className="col-md-6">
                              <InputField
                                placeholder="bio"
                                name="bio"
                                value={values?.bio}
                              />
                            </div>
                            <div className="col-md-2 m-30w">
                              <Select
                                showSearch
                                placeholder="Gender"
                                optionFilterProp="children"
                                onChange={(value) => setFieldValue('gender', value)}
                                onSearch={onSearch}
                                filterOption={filterOption}
                                // setFieldValue={setFieldValue}
                                value={values?.gender}
                                options={[
                                  {
                                    value: '',
                                    label: 'Gender',
                                  },
                                  {
                                    value: 'male',
                                    label: 'Male',
                                  },
                                  {
                                    value: 'female',
                                    label: 'Female',
                                  }
                                ]}
                                style={{ width: "100%" }}
                              />
                            </div>
                            {/* <div className="col-md-8">
                              <InputField
                                placeholder="trail blazer profile"
                                name="trail_blaizer_profile"
                                value={values?.trailBlaizerProfile}
                              />
                            </div> */}
                            <div className="col-md-6 m-70w mtextwhite">
                              <Checkbox 
                              onChange={(e) => setFieldValue("termsAndConditions", e.target.checked)}
                              defaultChecked={values?.termsAndConditions}>
                                Accept terms and conditions
                                </Checkbox>
                              <ErrorMessage name="termsAndConditions">
                                  {(msg) => (
                                    <div className="input__error">{msg}</div>
                                  )}
                                </ErrorMessage>

                            </div>
                            <div className="col-md-6 "></div>
                            <div className="col-md-3 ">
                              <button
                                className="submit"
                                type="button"
                                onClick={() => {
                                  setIsEmail(true);
                                  setIsCompanyDetails(false);
                                }}
                              >
                                Previous
                              </button>
                            </div>

                            <div className="col-md-3 ">
                              <button className="submit nextsubmit"  type="submit">
                                Submit
                              </button>
                            </div>
                          </div>
                        )}
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* <a
        href="#"
        id="scroll-top"
        className="scroll-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </a> */}
    </div>
  );
};

export default JobSeekerRegistration;
