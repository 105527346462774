import * as Yup from 'yup';

export const loginValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email format').required('Email is required').test(
    'valid-domain',
    'Invalid domain extension',
    (value) => {
      // Custom validation for domain extension
      if (value) {
        const domainRegex = /^[A-Za-z0-9._%+-]+@([A-Za-z0-9-]+\.)+[A-Za-z]{2,}$/;
        return domainRegex.test(value);
      }
      return true; // Allow empty values, Yup's required handles that
    }
  ),
  password: Yup.string().required('Password is required'),
});
