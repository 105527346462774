import { Select, Switch, Table, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { ErrorMessage, Form, Formik } from "formik";
import { User } from "models/user.model";
import React, { useEffect, useRef, useState } from "react";
import ManageJobsService from "services/ManageJobsService/manage.jobs.service";
import ManageCurrencyTypes from "services/MetaList/currenc.types";
import ManageLocations from "services/MetaList/locations.service";
import InputField from "shared/components/InputField";
import { LocalStorage } from "shared/utils/localStorageHelpers";
import JobFunctionsComponent from "../../jobFunctions";
import CompanyDashboadrPanel from "../shared";
import SuperAdminDashboadrPanel from "../shared/SuperAdminDashboardPanel";
import JobSkillsComponent from "../../jobSkills";
import "./managejob.css"
// import TextEditor from './TextEditor';
import "react-quill/dist/quill.snow.css"; // Import the styles
import ReactQuill from "react-quill";
import LocationsComponent from "views/locations";
import CountryComponent from "views/country";
import { ManageJobsValidationSchema } from "./ManageJobsValidationSchema";



const ManageJobs = (props: any) => {

  const navigate = useNavigate();

    //Using Inline Function and the The Logical Not (!) to toggle state
    const [toggle, setToggle] = useState(false)

  // const [isCreation, setIsCreation] = useState(false)
  const [skills, setSkills] = useState([]);
  const data = LocalStorage.getItem("USER");
  // console.log(data, "...authUser");
  const [isUpdated, setIsUpdated] = useState(false);

  const {
    postJob,
    getJobPosts,
    getSingleJobPost,
    updateJobPost,
    deleteJobPost,
    jobs,
    singleJob,
  } = ManageJobsService();

  const { locations, getLocations } = ManageLocations();

  const { getCurrencyTypes, currencyTypes } = ManageCurrencyTypes();

  const [formTable, setFormTable] = useState(true);
  const [isCreation, setIsCreation] = useState(false);
  // const [postID, setPostID] = useState();
  const [successMessage, setSuccessMessage] = useState("");

  //dash menu toggle
  const toggleContainer = useRef<HTMLDivElement>(null);

  const [recordDelete, setRecordDelete] = useState(false);
  let arrayOfIds: any, arrayOfSkills: any, arrayOfCurrency: any;

  // const [jobInfo, setJobInfo] = useState({
  //   title: "",
  //   job_details: "",
  //   requirements: "",
  //   salary: "",
  //   application_deadline: "",
  //   maximum_experience: "",
  //   minimum_experience: "",
  //   mode: "",
  //   job_type: "",
  //   status: "",
  //   job_function_ids: [],
  //   company_ids: "",
  //   location_ids: "",
  //   country_ids: "",
  //   skill_ids: [],
  //   salaryType: "",
  //   currencyType_ids: [],
  // });

  useEffect(() => {
    getJobPosts();
    getCurrencyTypes();
    getLocations();

    const handleClickOutside = (event: MouseEvent) => {
      if (toggleContainer.current && !toggleContainer.current.contains(event.target as Node)) {
        setToggle(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up event listeners
      document.removeEventListener("mousedown", handleClickOutside);
    };


  }, [toggle, formTable]);

  // console.log('jobs Info 123', jobs)

  const onSearch = (value: string) => {
    // console.log("search:", value);
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const columns: any = [
    {
      title: "Job Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "company_name",
      render: (name: any, data: any) => (
        <span>{data?.company?.company_name}</span>
      ),
    },
    {
      title: "Job Type",
      dataIndex: "job_type",
      key: "job_type",
    },
    {
      title: "Job Expiry",
      dataIndex: "application_deadline",
      key: "application_deadline",
      render: (application_deadline: any, post: any) => {
        // Convert application_deadline to a Date object
        const deadlineDate = new Date(application_deadline).getTime();

        const today = new Date();
        const todayInMilliseconds = today.getTime();

        // console.log('Deadline Date:', deadlineDate);
        // console.log('Today in Milliseconds:', todayInMilliseconds);

        // Check if the application deadline is in the past
        if (deadlineDate < todayInMilliseconds) {
          // Trigger the function or perform the desired action
          // For example, console.log a message
          // alert('Application deadline has passed!');

          const arrayOfSkillIds = post?.skills?.map((obj: any) => obj.id);
          const arrayOfFunctionIds = post?.job_function?.map(
            (obj: any) => obj.id
          );
          updateJobPost(post?.id!, {
            ...post,
            active: false,
            skill_ids: arrayOfSkillIds,
            job_function_ids: arrayOfFunctionIds,
          });
        }

        return <span>{new Date(application_deadline).toDateString()}</span>;
      },
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   render: (status: string, post: any) => {
    //     console.log(post);
    //     return data?.user?.role === "Super Admin" ? (
    //       <Switch
    //         defaultChecked={status === "Open" ? true : false}
    //         onChange={(value: boolean) => {
    //           updateJobPost(post?.id!, {
    //             status: status === "Open" ? "Close" : "Open",
    //           });
    //           setIsUpdated(true);
    //         }}
    //       />
    //     ) : (
    //       <>{status}</>
    //     );
    //   },
    // },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      render: (active: any, post: any) => (
        <Switch
          defaultChecked={active}
          onChange={(value: boolean) => {
            const arrayOfSkillIds = post?.skills?.map((obj: any) => obj.id);
            const arrayOfFunctionIds = post?.job_function?.map(
              (obj: any) => obj.id
            );
            updateJobPost(post?.id!, {
              ...post,
              active: value,
              skill_ids: arrayOfSkillIds,
              job_function_ids: arrayOfFunctionIds,
            });

            setIsUpdated(true);
          }}
        />
      ),
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "actions",
      render: (action: any, data: User) => (
        <>
          <span
            style={{ marginRight: "10px", cursor: "pointer" }}
            onClick={async () => {
              const desiredData = await getSingleJobPost(data?.id!);
              setFormTable(false);
            }}
          >
            <i className="bi bi-pencil"></i>
          </span>

          {/* <span
            style={{ marginRight: "10px", cursor: 'pointer' }}
            onClick={() => { 

                const isConfirmed = window.confirm('Are you sure you want to delete?');

                // If the user confirms, proceed with deletion
                if (isConfirmed) {
                //   console.log('Item deleted!');
                  deleteJobPost(data?.id!);
                  setIsUpdated(true);
                  getJobPosts()
                }
                }}
            ><i className="bi bi-trash"></i></span> */}

          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              // props.setISJobApplications(true)
              navigate("/job-applications", { state: { jobID: data?.id } });
            }}
          >
            <i className="bi bi-eye" title="View Job Applicantions"></i>
          </span>
        </>
      ),
    },
  ];

  // Add unique keys to each column
  columns.forEach((column: any, index: any) => {
    column.key = index.toString();
  });

  if (singleJob?.job_function) {
    // Extracting only the 'id' values and storing them in a new array
    arrayOfIds = singleJob?.job_function?.map((obj: any) => obj.id);
    arrayOfSkills = singleJob?.skills?.map((obj: any) => obj.id);
    arrayOfCurrency = singleJob?.currencyType?.id;

    //   console.log('arrayOfIds', arrayOfIds)
  }

  // console.log("singleJob", singleJob);

  // console.log(jobs, '...listJobs')

  // const filteredJobs = jobs.filter((job: any) => {
  //   return job?.id !== singleJob?.id
  // })

  // console.log(currencyTypes,"...currencyTypes")

  const desiredColumns = columns?.filter(
    (column: any) => column?.title !== "Actions"
  );

  const cmpAdminJobsList = jobs?.filter(
    (job: any) => job?.user?.id === data?.user?.id
  );

  // console.log(jobs.length > 0 ? jobs : null, '...jobsInfo')
  // console.log(singleJob, '...singleJob');

  // console.log(jobs, '...jobs')
  console.log(singleJob, "...singleJob");

  return (
    <>
      <div className="mt-50"></div>
      <main id="main">
        <div className="page-title">
                    <nav className="breadcrumbs">
                        <div className="container">
                            <ol>
                                <li><a href="index.php">Home</a></li>
                                <li className="current">Manage Jobs</li>
                            </ol>
                        </div>
                    </nav>
                </div>
        <section className="f0f5f3">
          <div className="container">
            <div className="row">
              <div className="col-md-3">

                
              <div className='mobilesidenavmenu'  ref={toggleContainer}>
                        <button className='sidenavmenu btn sidenavmenubtn  mb-5' onClick={() => setToggle(!toggle)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-bar-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M6 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L12.293 7.5H6.5A.5.5 0 0 0 6 8m-2.5 7a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5"/>
                          </svg>
                          </button>
                            {
                              toggle &&

                                        <div className="cmp-side-nav ">
                                        <CompanyDashboadrPanel />
                                        </div>
                          }   
                </div>
                          
            
                <div className="cmp-side-nav  desktopsidenavmenu">
                 <CompanyDashboadrPanel />
                </div>



              </div>
              <div className="col-md-9 ">
                {!formTable && (
                  <Formik
                    validationSchema={ManageJobsValidationSchema}
                    initialValues={{
                      title: singleJob?.title || "",
                      job_details: singleJob?.job_details || "",
                      requirements: singleJob?.requirements || "",
                      salary: singleJob?.salary || "",
                      application_deadline:
                        singleJob?.application_deadline || "",
                      minimum_experience: singleJob?.minimum_experience || "",
                      maximum_experience: singleJob?.maximum_experience || "",
                      mode: singleJob?.mode || "",
                      job_type: singleJob?.job_type || "",
                      status: singleJob?.status || "Open",
                      job_function_ids: arrayOfIds || [], //singleJob?.job_function || [],
                      // company_ids: singleJob?.company || "",
                      // location_ids: singleJob?.location || "",
                      // country_ids: parseInt(singleJob?.country) || '',
                      skill_ids: arrayOfSkills || [],
                      salaryType: singleJob?.salaryType || "",
                      // currencyType: singleJob?.currencyType || "",
                      currencyType_ids: arrayOfCurrency || [],
                      companyLocation: singleJob?.location?.id || "",
                      companyCountry: singleJob?.country?.id || "",
                    }}
                    enableReinitialize
                    onSubmit={async (values) => {
                      // console.log('update job', values)

                      if (singleJob?.id) {
                        const desiredValues = {
                          ...values,
                          location_ids: values?.companyLocation,
                          country_ids: values?.companyCountry,
                        };
                        const data = await updateJobPost(
                          singleJob?.id,
                          desiredValues
                        );
                        if (data?.status === "Open") {
                          setFormTable(true);
                          //  setIsCreation(false)
                          setSuccessMessage("");
                          setRecordDelete(false);
                          // resetForm();
                        } else {
                          setSuccessMessage("Something went wrong");
                        }
                      } else {
                        const desiredValues = {
                          ...values,
                          location_ids: values?.companyLocation,
                          country_ids: values?.companyCountry,
                        };
                        // console.log(desiredValues, '...values')
                        // console.log('else condition', desiredValues)
                        const user = await postJob(desiredValues);
                        if (user?.data?.status === "Open") {
                          setFormTable(true);
                          //    setIsCreation(true)
                          setSuccessMessage("");
                          setRecordDelete(false);
                          // resetForm();
                        } else {
                          setSuccessMessage("Something went wrong");
                        }
                      }

                      // postJob(values)
                      // setFormTable(true)
                    }}
                    // validate={(values) => {
                    //   const errors: any = {};

                    //   if (values?.salaryType === "") {
                    //     errors.salaryType = "Please select at least one option";
                    //   }
                    //   if (values.job_details === "") {
                    //     errors.job_details = "Job Description required";
                    //   }
                    //   if (values.currencyType_ids?.length === 0) {
                    //     errors.currencyType_ids =
                    //       "Please select at least one option";
                    //   }
                    //   if (values.skill_ids?.length === 0) {
                    //     errors.skill_ids = "Please select at least one option";
                    //   }
                    //   if (values.job_function_ids?.length === 0) {
                    //     errors.job_function_ids =
                    //       "Please select at least one option";
                    //   }
                    //   if (values.companyLocation === "") {
                    //     errors.companyLocation =
                    //       "Please select at least one location";
                    //   }
                    //   if (values.companyLocation === "") {
                    //     errors.companyLocation =
                    //       "Please select at least one location";
                    //   }
                    //   if (values.companyCountry === "") {
                    //     errors.companyCountry =
                    //       "Please select at least one country";
                    //   }
                    //   if (values.mode === "") {
                    //     errors.mode = "Please select at least one option"
                    //   }
                    //   if (values.job_type === "") {
                    //     errors.job_type = "Please select at least one option";
                    //   }

                    //   return errors;
                    // }}
                  >
                    {({ values, errors, setFieldValue }) => (
                      <Form
                        className="php-email-form"
                        data-aos="fade-up"
                        data-aos-delay="200"
                      >
                        <div className="col-md-12" style={{ marginTop: "20px" }}>
                          {/* <label>Job Title:</label> */}
                          <InputField
                            placeholder="Job Title"
                            name="title"
                            value={values?.title}
                          />
                        </div>
                        <div className="col-md-12" style={{ marginTop: "20px" }}>
                          {/* <InputField
                                placeholder="Job Details"
                                name="job_details"
                                value={values?.job_details}
                            /> */}
                          {/* <label>Job Description:</label> */}
                          <ReactQuill
                            theme="snow" // 'snow' is a standard theme, but you can choose other themes as well
                            value={values?.job_details}
                            placeholder="Enter Job Description"
                            onChange={(value) =>
                              setFieldValue("job_details", value)
                            }
                          />
                          {/* color change after */}
                          <ErrorMessage name="job_details">
                            {(msg) => (
                              <div className="input__error">{msg}</div>
                            )}
                          </ErrorMessage>
                        </div>
                        <div className="col-md-12" style={{ marginTop: "20px" }}>
                          {/* <label>Requirements:</label> */}
                          <InputField
                            placeholder="Requirements"
                            name="requirements"
                            value={values?.requirements}
                          />
                        </div>

                        <div className="row">
                          <div
                            className="col-md-4"
                            style={{ marginTop: "20px" }}
                          >
                            {/* <label>Salary:</label> */}
                            <InputField
                              placeholder="Salary"
                              name="salary"
                              value={values?.salary}
                            />
                          </div>

                          <div
                            className="col-md-4"
                            style={{ marginTop: "20px" }}
                          >
                            {/* <label>Salary Type:</label> */}
                            <Select
                              value={values?.salaryType}
                              onChange={(value) =>
                                setFieldValue("salaryType", value)
                              }
                              showSearch
                              placeholder="Select"
                              optionFilterProp="children"
                              onSearch={onSearch}
                              filterOption={filterOption}
                              style={{ width: "100%" }}
                              options={[
                                {
                                  value: "",
                                  label: "Salary Type",
                                },
                                {
                                  value: "Weekly",
                                  label: "Week",
                                },
                                {
                                  value: "Monthly",
                                  label: "Month",
                                },
                                {
                                  value: "Yearly",
                                  label: "Annual",
                                },
                              ]}
                            />
                            <ErrorMessage name="salaryType">
                              {(msg) => (
                                <div className="input__error">{msg}</div>
                              )}
                            </ErrorMessage>
                          </div>
                          <div
                            className="col-md-4"
                            style={{ marginTop: "20px" }}
                          >
                            
                            <Select
                              style={{ width: "100%" }}
                              value={values?.currencyType_ids}
                              onChange={(value) => {
                                // console.log('selectedvalue', value)

                                setFieldValue("currencyType_ids", value);
                              }}
                              showSearch
                              placeholder="Select"
                              optionFilterProp="children"
                              onSearch={onSearch}
                              filterOption={filterOption}
                              options={currencyTypes?.map((obj: any) => {
                                return {
                                  value: obj.id,
                                  label: obj.name,
                                };
                              })}
                            />
                            <ErrorMessage name="currencyType_ids">
                              {(msg) => (
                                <div className="input__error">{msg}</div>
                              )}
                            </ErrorMessage>
                          </div>
                        </div>
                        <div className="row">
                        

                        <div className="col-md-6">
                          <JobFunctionsComponent
                            setFieldValue={setFieldValue}
                            singleJob={singleJob}
                          />
                          <ErrorMessage name="job_function_ids">
                            {(msg) => (
                              <div className="input__error">{msg}</div>
                            )}
                          </ErrorMessage>
                        </div>
                        <div className="col-md-6">
                          <JobSkillsComponent
                            setFieldValue={setFieldValue}
                            singleJob={singleJob}
                          />
                          <ErrorMessage name="skill_ids">
                            {(msg) => (
                              <div className="input__error">{msg}</div>
                            )}
                          </ErrorMessage>
                        </div>

                        <div className="col-md-4" style={{ marginTop: "20px" }}>
                          {/* <label>Job Expiry:</label> */}
                          <Tooltip title="Job Expiry">
                          <InputField
                            type="date"
                            placeholder="Job Expiry:"
                            name="application_deadline"
                            value={values?.application_deadline}
                          />
                          </Tooltip>
                        </div>
                        

                        <div
                          className="col-md-4 margintopinputs"
                         
                        >
                          <CountryComponent
                            setFieldValue={setFieldValue}
                            singleJob={singleJob}
                            selectedValue={values?.companyCountry}
                          />

                          <ErrorMessage name="companyCountry">
                            {(msg) => (
                              <div className="input__error">{msg}</div>
                            )}
                          </ErrorMessage>
                        </div>

                        <div
                          className="col-md-4 margintopinputs"
                      
                        >
                          <LocationsComponent
                            setFieldValue={setFieldValue}
                            singleJob={singleJob}
                            countryId={values?.companyCountry}
                            selectedValue={values?.companyLocation}
                          />
                          <ErrorMessage name="companyLocation">
                            {(msg) => (
                              <div className="input__error">{msg}</div>
                            )}
                          </ErrorMessage>
                        </div>

                  

                        <div className="col-md-2" style={{ marginTop: "20px" }}>
                          
                          <InputField
                            placeholder="Min. Experience"
                            name="minimum_experience"
                            value={values?.minimum_experience}
                          />
                        </div>
                        <div className="col-md-2" style={{ marginTop: "20px" }}>
                          
                          <InputField
                            placeholder="Max. Experience"
                            name="maximum_experience"
                            value={values?.maximum_experience}
                          />
                        </div>
                        <div className="col-md-4" style={{ marginTop: "20px" }}>
                          
                          <Select
                            showSearch
                            placeholder="Job Mode:"
                            optionFilterProp="children"
                            // onChange={onModeChange}
                            onChange={(value) => setFieldValue("mode", value)}
                            onSearch={onSearch}
                            filterOption={filterOption}
                            value={values?.mode}
                            options={[
                              {
                                value: "",
                                label: "Select",
                              },
                              {
                                value: "onsite",
                                label: "Onsite",
                              },
                              {
                                value: "remote",
                                label: "Remote",
                              },
                              {
                                value: "hybride",
                                label: "Hybride",
                              },
                            ]}
                            style={{ width: "100%" }}
                          />
                          <ErrorMessage name="mode">
                            {(msg) => (
                              <div className="input__error">{msg}</div>
                            )}
                          </ErrorMessage>
                        </div>
                        <div className="col-md-4" style={{ marginTop: "20px" }}>
                          {/* <InputField
                                placeholder="Job Type"
                                name="job_type"
                                value={values?.job_type}
                            /> */}

                          

                          <Select
                            showSearch
                            placeholder="Job Type:"
                            optionFilterProp="children"
                            // onChange={onJobTypeChange}
                            onChange={(value) =>
                              setFieldValue("job_type", value)
                            }
                            onSearch={onSearch}
                            filterOption={filterOption}
                            value={values?.job_type}
                            options={[
                              {
                                value: "",
                                label: "Select",
                              },
                              {
                                value: "fulltime",
                                label: "FullTime",
                              },
                              {
                                value: "parttime",
                                label: "PartTime",
                              },
                              {
                                value: "contract",
                                label: "Contract",
                              },
                              {
                                value: "internship",
                                label: "Internship",
                              },
                              {
                                value: "temporary",
                                label: "Temporary",
                              },
                            ]}
                            style={{ width: "100%" }}
                          />
                          <ErrorMessage name="job_type">
                            {(msg) => (
                              <div className="input__error">{msg}</div>
                            )}
                          </ErrorMessage>
                        </div>
                        </div>

                        <div
                          className="col-md-6 "
                          style={{ marginTop: "20px" }}
                        >
                          <button className="submit" type="submit">
                            Submit
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}

                {/* {formTable && <div style={{ marginTop: "20px" }}>
                <a href='javascript:void(0)' className='loginbtn' onClick={()=> { setFormTable(false)}}>Create Job</a> */}

                {formTable && (
                  <div>
                    {data?.user?.role !== "Super Admin" && (
                      <a
                        href="javascript:void(0)"
                        className="loginbtn"
                        onClick={() => setFormTable(false)}
                      >
                        Create Job
                      </a>
                    )}
                    <Table
                      className="margintop2p tableScroll"
                      dataSource={jobs}
                      columns={
                        data?.user?.role === "Super Admin"
                          ? desiredColumns
                          : columns
                      }
                      pagination={false}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default ManageJobs;
