import { ErrorMessage, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import UserService from "services/AuthService/auth.service";
import ManageCompanyTypes from "services/MetaList/company.types";
import InputField from "shared/components/InputField";
import { LocalStorage } from "shared/utils/localStorageHelpers";
import CompanyDashboadrPanel from "../shared";
import CompanyTypeComponent from "views/companyType";
import LocationsComponent from "views/locations";
import CountryComponent from "views/country";
import { useNavigate } from "react-router-dom";
import './companyaccsetting.css'

import {
  Input,
  Tooltip,
  Button,
  Checkbox,
  Select,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { settingValidationSchema } from "./settingValidationSchema";

const CompanyAccount = () => {

    //Using Inline Function and the The Logical Not (!) to toggle state
    const [toggle, setToggle] = useState(false)

  const navigate = useNavigate();
  const { getCompanyTypes, companyTypes } = ManageCompanyTypes();

  const currentUser = LocalStorage.getItem("USER");
  const { getCompanyAdm, singleUser, updateCompanyAdm } = UserService();
  const [updateStatus, setUpdateStatus] = useState<any>("");
  const data = LocalStorage.getItem("USER");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [customErr, setCustomErr] = useState("");

  const [profile, setProfile] = useState<any>();

  //dash menu toggle
  const toggleContainer = useRef<HTMLDivElement>(null);



  useEffect(() => {
    getCompanyAdm(currentUser?.user.id);
    getCompanyTypes();

    const handleClickOutside = (event: MouseEvent) => {
      if (toggleContainer.current && !toggleContainer.current.contains(event.target as Node)) {
        setToggle(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up event listeners
      document.removeEventListener("mousedown", handleClickOutside);
    };

  }, [toggle]);




  const handleChange: UploadProps["onChange"] = (info) => {
    let newFileList = [...info.fileList];

    if (info.file && info.file.originFileObj) {
      setProfile(info.file.originFileObj);
    }

    if (info.fileList.length > 0 && newFileList.length > 0) {
      // 1. Limit the number of uploaded files
      // Only to show two recent uploaded files, and old ones will be replaced by the new
      newFileList = newFileList.slice(-2);

      // 2. Read from response and show file link
      newFileList = newFileList.map((file) => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.url;
        }
        return file;
      });
    }

    setFileList(newFileList);
  };

  const props = {
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    onChange: handleChange,
    multiple: false,
  };

  // console.log("singleUser", singleUser)

  return (
    <>
      <div className="mt-50"></div>
      <main id="main">
        <div className="page-title">
          <nav className="breadcrumbs">
            <div className="container">
              <ol>
                <li>
                  <a href="index.php">Home</a>
                </li>
                <li className="current">Profile</li>
              </ol>
            </div>
          </nav>
        </div>
        <section className="f0f5f3">
          <div className="container">
            <Formik
              validationSchema={settingValidationSchema}
              initialValues={{
                company_name: singleUser?.company_data.company_name || "",
                companyLogo: singleUser?.company_data.company_logo || "",
                company_description:
                  singleUser?.company_data.company_description || "",
                company_website: singleUser?.company_data.company_website || "",
                company_email: singleUser?.company_data.company_email || null,
                company_phone_number:
                  singleUser?.company_data.company_phone_number || "",
                company_history:
                  singleUser?.company_data.company_history || null,
                type_of_industry:
                  singleUser?.company_data.type_of_industry || null,
                company_size: singleUser?.company_data.company_size || null,
                products: singleUser?.company_data.products || null,
                social_media_link_1:
                  singleUser?.company_data.social_media_link_1 || null,
                social_media_link_2:
                  singleUser?.company_data.social_media_link_1 || null,
                testmonial: singleUser?.company_data.testmonial || null,
                company_address:
                  singleUser?.company_data.company_address || null,
                company_city: singleUser?.company_data.company_city || "",
                company_state: singleUser?.company_data.company_state || null,
                // company_country: singleUser?.company_data.company_country || "",
                company_postal_code:
                  singleUser?.company_data.company_postal_code || "",
                companyType: singleUser?.company_data?.company_type || [],
                companyLocation:
                  singleUser?.company_data?.company_location?.id || "",
                companyCountry:
                  parseInt(singleUser?.company_data?.company_country) || "",
              }}
              // validate={(values) => {
              //   const errors: any = {};
              //   if (values.companyType?.length === 0) {
              //     errors.companyType = "Company Type is required";
              //   }
              //   if (values.company_description === "") {
              //     errors.company_description =
              //       "Company Description is required";
              //   }

              //   if (values.company_history === "") {
              //     errors.company_history = "Company history is required";
              //   }
              //   if (values.companyLocation === "") {
              //     errors.companyLocation =
              //       "Please select at least one location";
              //   }
              //   if (values.companyCountry === "") {
              //     errors.companyCountry = "Please select at least one country";
              //   }
              //   if (values.testmonial === null) {
              //     errors.testmonial = "Testmonial is required";
              //   }
              //   return errors;
              // }}
              enableReinitialize
              onSubmit={async (values) => {
                // Create a new FormData instance
                const formData = new FormData();

                let compy_types: any;

                if (
                  Array.isArray(values?.companyType) &&
                  values?.companyType.length > 0 &&
                  typeof values?.companyType[0] === "object"
                ) {
                  // compy_types = values?.companyType?.map((item: any) => item.id).filter((id: any) => id !== undefined);
                  compy_types = values?.companyType
                    ?.map((item: any) => item.id)
                    .filter((id: any) => id !== undefined)
                    .map(Number);
                } else {
                  compy_types = values?.companyType;
                }

                // Replace this line to rename the key
                const updatedValues = {
                  ...values,
                  company_location_id: values.companyLocation,
                  company_type_ids: compy_types,
                  company_country: values?.companyCountry,
                };

                delete updatedValues.companyType;
                delete updatedValues.companyLocation;

                // Append form fields to FormData
                // Object.entries(updatedValues).forEach(([key, value]) => {
                //   formData.append(key, typeof value === 'boolean' ? String(value) : value);
                // });

                // Append form fields to FormData
                Object.entries(updatedValues).forEach(([key, value]) => {
                  if (key === "company_type_ids" && Array.isArray(value)) {
                    // If the key is 'company_type_ids' and its value is an array, append each value separately
                    value.forEach((item, index) => {
                      // formData.append(`${key}[${index}]`, item);
                      formData.append(`${key}`, item);
                    });
                    // const companyTypeArray = JSON.stringify(value)
                    // formData.append('company_type_ids',companyTypeArray);
                  } else {
                    formData.append(
                      key,
                      typeof value === "boolean" ? String(value) : value
                    );
                  }
                });

                // formData.append('company_type_ids', JSON.stringify(compy_types))

                // console.log(compy_types, '.....values?.companyType')
                // console.log(typeof(compy_types),'..... typeof values?.companyType')

                // console.log(formData.get('company_type_ids'), '...company Type')
                // console.log(typeof (formData.get('company_type_ids')), '...company Type')

                if (profile) {
                  // Assuming 'resume' is a File object obtained from a file input
                  const allowedFormats = ["png", "jpg", "jpeg"]; // Add or modify allowed file formats
                  const maxFileSizeInBytes = 1 * 1024 * 1024; // 1 MB, adjust as needed

                  // Check file format
                  const fileName = profile.name;
                  const fileFormat = fileName.split(".").pop().toLowerCase();
                  if (!allowedFormats.includes(fileFormat)) {
                    setCustomErr(
                      "Invalid file format. Please upload a PNG, JPG, or JPEG file."
                    );
                    return; // or handle the error accordingly
                  }

                  // Check file size
                  if (profile.size > maxFileSizeInBytes) {
                    setCustomErr(
                      "File size exceeds the maximum allowed size (5 MB)."
                    );
                    return; // or handle the error accordingly
                  }

                  // If validation passes, append the file to form data
                  formData.append("company_logo", profile);
                } else {
                  formData.delete("company_logo");
                }

                // if (profile instanceof File) {
                //   // Assuming 'resume' is a File object obtained from a file input
                //   const allowedFormats = ['png', 'jpg', 'jpeg']; // Add or modify allowed file formats
                //   const maxFileSizeInBytes = 1 * 1024 * 1024; // 1 MB, adjust as needed
                //   const maxThumbnailSize = 100; // Set the maximum width or height of the thumbnail

                //   // Check file format
                //   const fileName: any = profile.name;
                //   const fileFormat = fileName.split('.').pop().toLowerCase();
                //   if (!allowedFormats.includes(fileFormat)) {
                //     setCustomErr('Invalid file format. Please upload a PNG, JPG, or JPEG file.');
                //     return; // or handle the error accordingly
                //   }

                //   // Check file size
                //   if (profile.size > maxFileSizeInBytes) {
                //     setCustomErr('File size exceeds the maximum allowed size (1 MB).');
                //     return; // or handle the error accordingly
                //   }

                //   // Create a thumbnail using canvas
                //   const thumbnail:any = await generateThumbnail(profile, maxThumbnailSize);

                //   formData.append("company_logo", thumbnail, profile.name);
                //   // formData.append("company_logo_thumbnail", thumbnail as Blob, "thumbnail.jpg");
                // } else {
                //   formData.delete('company_logo');
                //   // formData.delete('company_logo_thumbnail');
                // }

                // // Function to generate thumbnail using canvas
                // async function generateThumbnail(file: File, maxSize: number) {
                //   return new Promise((resolve, reject) => {
                //     const reader = new FileReader();
                //     reader.onload = (e: any) => {
                //       const img = new Image();
                //       img.onload = () => {
                //         const canvas = document.createElement('canvas');
                //         const ctx = canvas.getContext('2d');

                //         // Calculate the new size while maintaining the aspect ratio
                //         let newWidth, newHeight;
                //         if (img.width > img.height) {
                //           newWidth = maxSize;
                //           newHeight = (maxSize / img.width) * img.height;
                //         } else {
                //           newWidth = (maxSize / img.height) * img.width;
                //           newHeight = maxSize;
                //         }

                //         canvas.width = newWidth;
                //         canvas.height = newHeight;
                //         ctx?.drawImage(img, 0, 0, newWidth, newHeight);

                //         // Convert the canvas to data URL
                //         const thumbnailDataUrl = canvas.toDataURL('image/jpeg');

                //         resolve(thumbnailDataUrl);
                //       };
                //       img.src = e.target?.result;
                //     };
                //     reader.readAsDataURL(file);
                //   });
                // }

                delete values?.companyType;
                delete values?.companyLocation;
                // JSON.parse(q)?.map(item => parseInt(item));

                // Now, call the updateCompanyAdm function with the updated values
                const updateResp = await updateCompanyAdm(
                  singleUser?.company,
                  formData
                );

                if (updateResp?.status === 200) {
                  setUpdateStatus("Profile is updated successfully");
                  navigate("/company-profile");
                }
              }}
            >
              {({ values, setFieldValue }) => (
                <Form data-aos="fade-up" data-aos-delay="200">
                  <div className="row">

                    
                    <div className="col-md-3">



                    <div className='mobilesidenavmenu'  ref={toggleContainer}>
                        <button className='sidenavmenu btn sidenavmenubtn  mb-5' onClick={() => setToggle(!toggle)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-bar-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M6 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L12.293 7.5H6.5A.5.5 0 0 0 6 8m-2.5 7a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5"/>
                          </svg>
                          </button>
                            {
                              toggle &&
                               <div className="cmp-side-nav">
                                  <CompanyDashboadrPanel />
                                </div>
                            }   
                           </div>
                          
            
                <div className="cmp-side-nav desktopsidenavmenu">
                 <CompanyDashboadrPanel />
                </div>






                    </div>





                    <div className="col-md-9 padding-left-2rem pbg">
                      {/* <form
                  action="forms/contact.php"
                  method="post"
                  className="php-email-form aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-delay="200"
                > */}
                      <span style={{ color: "green" }}>
                        {updateStatus ? updateStatus : null}
                      </span>
                      <div className="row gy-4">
                        <h4>Account Settings</h4>

                        <div className="col-md-7">
                          {/* <input type="text" className="form-control" placeholder="Company Name" required fdprocessedid="9mhrl" readOnly /> */}
                          {/* <label>Company Name</label> */}
                          <InputField
                            type="text"
                            placeholder="Company Name"
                            name="company_name"
                            value={values?.company_name}
                          />
                        </div>

                        <div className="col-md-3">
                          <Upload
                            {...props}
                            fileList={fileList}
                            name="company_logo"
                          >
                            <Button icon={<UploadOutlined />}>
                              Upload Profile
                            </Button>
                          </Upload>
                          {/* {values?.companyLogo ? <p>{values?.companyLogo}</p> : null} */}
                          <img
                            src={`http://localhost:8000${singleUser?.company_data?.company_logo}`}
                            alt="Logo"
                            className="img-fluid"
                           
                          />
                          <span style={{ fontSize: "12px", color: "red" }}>
                            {customErr ? customErr : null}
                          </span>
                        </div>

                        <div className="col-md-10">
                          <CompanyTypeComponent
                            singleUser={singleUser}
                            setFieldValue={setFieldValue}
                          />
                          <ErrorMessage name="companyType">
                            {(msg) => (
                              <div style={{ color: "#c9583e" }}>{msg}</div>
                            )}
                          </ErrorMessage>
                        </div>

                        {/* <div className="col-md-7">
                          <div style={{ display: "flex", alignItems: "center" }}>
                              <label>Company Logo &nbsp; &nbsp; &nbsp;</label>
                            <div>
                              <input
                                type="file"
                                id="companyLogo"
                                placeholder="Upload Company Logo"
                                accept="image/*"
                              />
                            </div>
                          </div>
                        </div> */}

                        <div className="col-md-10">
                          <label>Company Description</label>
                          <ReactQuill
                            theme="snow"
                            value={values?.company_description}
                            onChange={(value) =>
                              setFieldValue("company_description", value)
                            }
                          />
                          <ErrorMessage name="company_description">
                            {(msg) => (
                              <div className="input__error">{msg}</div>
                            )}
                          </ErrorMessage>
                        </div>

                        <div className="col-md-4">
                          {/* <label>Company Website</label> */}
                          <InputField
                            type="text"
                            placeholder="Company Website"
                            name="company_website"
                            value={values?.company_website}
                          />
                        </div>

                        <div className="col-md-3">
                          {/* <label>Company Email</label> */}
                          <InputField
                            className="borderradius"
                            type="mail"
                            placeholder="Email Address"
                            name="company_email"
                            value={values?.company_email}
                            disabled
                          />
                        </div>

                        <div className="col-md-3">
                          {/* <label>Company Phone Number</label> */}
                          {/* <InputField
                            type="text"
                            placeholder="Phone Number"
                            name="company_phone_number"
                            value={values?.company_phone_number}
                          /> */}
                          <InputField
                            type="number"
                            placeholder="Phone Number"
                            name="companyPhoneNumber"
                            onChange={(e) => {
                              e.preventDefault();
                              const { value } = e.target;
                              // Check if the length of the input is within the limit
                              if (value.length <= 10) {
                                const regex =
                                  /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                                if (
                                  value === "" ||
                                  regex.test(value.toString())
                                ) {
                                  setFieldValue("companyPhoneNumber", value);
                                }
                              }
                            }}
                            value={values?.company_phone_number}
                          />
                        </div>

                        <div className="col-md-10">
                          <label>Company History</label>
                          <ReactQuill
                            theme="snow" // 'snow' is a standard theme, but you can choose other themes as well
                            value={values?.company_history}
                            onChange={(value) =>
                              setFieldValue("company_history", value)
                            }
                          />
                          <ErrorMessage name="company_history">
                            {(msg) => (
                              <div className="input__error">{msg}</div>
                            )}
                          </ErrorMessage>
                        </div>

                        <div className="col-md-3">
                          {/* <label>Type of Industry</label> */}
                          <InputField
                            type="text"
                            placeholder="Type Of Industry"
                            name="type_of_industry"
                            value={values?.type_of_industry}
                          />
                        </div>
                        <div className="col-md-3">
                          {/* <label>Company Size</label> */}
                          <InputField
                            type="text"
                            placeholder="Company Size"
                            name="company_size"
                            value={values?.company_size}
                          />
                        </div>

                        <div className="col-md-4">
                          {/* <label>Products/Services</label> */}
                          <InputField
                            type="text"
                            placeholder="Products/Services"
                            name="products"
                            value={values?.products}
                          />
                        </div>
                        <div className="col-md-5">
                          {/* <label> Social Media Link 1</label> */}
                          <InputField
                            type="text"
                            placeholder="Social Media Link 1"
                            name="social_media_link_1"
                            value={values?.social_media_link_1}
                          />
                        </div>

                        <div className="col-md-5">
                          {/* <label> Social Media Link 2</label> */}
                          <InputField
                            type="text"
                            placeholder="Social Media Link 2"
                            name="social_media_link_2"
                            value={values?.social_media_link_2}
                          />
                        </div>

                        <div className="col-md-10">
                          <label>Testmonial</label>
                          <ReactQuill
                            theme="snow" // 'snow' is a standard theme, but you can choose other themes as well
                            value={values?.testmonial}
                            onChange={(value) =>
                              setFieldValue("testmonial", value)
                            }
                          />
                          <ErrorMessage name="testmonial">
                            {(msg) => (
                              <div className="input__error">{msg}</div>
                            )}
                          </ErrorMessage>
                        </div>

                        

                        <div className="col-md-5">
                          <CountryComponent
                            setFieldValue={setFieldValue}
                            singleJob={values}
                            selectedValue={values?.companyCountry}
                          />
                          <ErrorMessage name="companyCountry">
                            {(msg) => (
                              <div style={{ color: "#c9583e" }}>{msg}</div>
                            )}
                          </ErrorMessage>
                        </div>

                        <div className="col-md-5">
                          <LocationsComponent
                            setFieldValue={setFieldValue}
                            singleJob={values}
                            selectedValue={values?.companyLocation}
                            countryId={values?.companyCountry}
                          />
                          <ErrorMessage name="companyLocation">
                            {(msg) => (
                              <div style={{ color: "#c9583e" }}>{msg}</div>
                            )}
                          </ErrorMessage>
                        </div>

                        {/* <p> Company Location </p> */}
                        <div className="col-md-6">
                          {/* <label>Company Address</label> */}
                          <InputField
                            type="text"
                            placeholder="Enter Address"
                            name="company_address"
                            value={values?.company_address}
                          />
                        </div>

                       {/* <div className="col-md-5">
                          <InputField
                            type="text"
                            placeholder="City"
                            name="company_city"
                            value={values?.company_city}
                          />
                        </div> 

                        <div className="col-md-4">
                          <InputField
                            type="text"
                            placeholder="State"
                            name="company_state"
                            value={values?.company_state}
                          />
                        </div>

                         <div className="col-md-3">
                          <InputField
                            type="text"
                            placeholder="Country"
                            name="company_country"
                            value={values?.company_country}

                          />
                        </div> */}

                        <div className="col-md-4">
                          {/* <label>Company Pin Code</label> */}
                          <InputField
                            type="text"
                            placeholder="Pin Code"
                            name="company_postal_code"
                            value={values?.company_postal_code}
                          />
                        </div>

                        <div className="col-md-12 ">
                            <button type="submit" className="button-submit">
                              Update
                            </button>
                        </div>
                      </div>
                      {/* </form> */}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </section>
      </main>
    </>
  );
};

export default CompanyAccount;
