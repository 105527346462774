import React from "react";
import { useNavigate } from "react-router-dom";
import UserService from "services/AuthService/auth.service";
import { LocalStorage } from 'shared/utils/localStorageHelpers';




const SuperAdminDashboadrPanel = (props: any) => {

    const { updateCompanyAdm, updateCompanyEmployee } = UserService()

    const { setEmployee, setIsJobManage } = props
    const navigate = useNavigate()
    const data = LocalStorage.getItem("USER")

    console.log('dataInfo', data)

    const delUser = () => {

        // Display a prompt and capture the entered value
        //  const userInput = window.prompt('Are you sure you want to delete your account?');
        const userConfirmed = window.confirm('Are you sure you want to delete your account?');

        // Check if the user clicked "Cancel" or entered an empty value
        if (userConfirmed) {



            // Assuming `updateJobSeekerUser` is an asynchronous function
            //  updateCompanyAdm(data?.user?.id, { active: false })
            updateCompanyEmployee(data?.user?.id, { active: false })
                .then(() => {
                    // Display an alert when the update is successful
                    alert('User account deleted successfully!');
                    // LocalStorage.clear();
                    LocalStorage.removeItem("USER")
                    navigate("/");
                })
                .catch((error: any) => {
                    // Handle errors if the update fails
                    console.error('Error updating user:', error);
                    alert('Failed to update user. Please try again.');
                });

        }


    }



    return (


        <div className="side-nav-item d-flex justify-content-center">
                    <ul>
                      
                      {/* <li><a href="">
                        <img src="assets/img/icons/dashboard.png" alt="" className="img-fluid" />
                        <p>Dashboard</p></a>
                      </li> */}

                      <li onClick={() => navigate('/super-admin-dashboard')} style={{cursor:'pointer'}}>
                        <img src="assets/img/icons/dashboard.png" alt="" className="img-fluid" />
                        <p>Dashboard</p>
                      </li>
                      
                      {/* <li onClick={() => setEmployee(true)}>
                        <img src="assets/img/icons/Profile.png" alt="" className="img-fluid" />
                        <p style={{cursor:'pointer'}}>Manage Employee</p>
                      </li> */}

                      <li onClick={() => navigate('/manage-jobs')}>
                        <img src="assets/img/icons/Profile.png" alt="" className="img-fluid" />
                        <p style={{cursor:'pointer'}}>Manage Jobs</p>
                      </li>

                      <li onClick={() => navigate('/company-profile')} style={{cursor:'pointer'}}>
                        <img src="assets/img/icons/Profile.png" alt="" className="img-fluid" />
                        <p>Profile</p>
                      </li>


                      {/* <li onClick={() => { setEmployee(false); setIsJobManage(true) }}>
                      <img src="assets/img/icons/JobPosted.png" alt="" className="img-fluid" />
                        <p style={{cursor:'pointer'}}>Manage jobs</p>                        
                      </li> */}

                    <li onClick={() => navigate('/manage-admins')}>
                      <img src="assets/img/icons/JobPosted.png" alt="" className="img-fluid" />
                        <p style={{cursor:'pointer'}}>Manage Company Admins</p>                        
                      </li>

                      <li onClick={() => navigate('/manage-employee')}>
                      <img src="assets/img/icons/JobPosted.png" alt="" className="img-fluid" />
                        <p style={{cursor:'pointer'}}>Manage Company Employees</p>                        
                      </li>

                      <li onClick={() => navigate('/manage-jobseekers')}>
                      <img src="assets/img/icons/JobPosted.png" alt="" className="img-fluid" />
                        <p style={{cursor:'pointer'}}>Manage Jobseekers</p>                        
                      </li>


                      {/* <li><a href="">
                        <img src="assets/img/icons/JobPosted.png" alt="" className="img-fluid" />
                        <p>Manage jobs</p></a>
                      </li> */}

                      {/* <li><a href="">
                        <img src="assets/img/icons/savedresumes.png" alt="" className="img-fluid" />
                        <p>Job Applications</p></a>
                      </li> */}

                      <li onClick={() => {navigate('/company-account-settings')}} style={{cursor:'pointer'}}>
                        <img src="assets/img/icons/AccountSettings.png" alt="" className="img-fluid" />
                        <p>Account Settings</p>
                      </li>
                      {/* <li><a href="">
                        <img src="assets/img/icons/DeleteAccount.png" alt="" className="img-fluid" />
                        <p>Delete Account</p></a>
                      </li> */}
                      <li><a href="">
                        <img src="assets/img/icons/Logout.png" alt="" className="img-fluid" />
                        <p>Logout</p></a>
                      </li>
                    </ul>

                  </div>
    )
}

export default SuperAdminDashboadrPanel