import React, { useEffect, useState } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import ManageJobFunctions from 'services/MetaList/job.functions.service'


export interface Group {
  id: number;
  name: string;
}

function JobFunctionsComponent(props: any) {

    const { getJobFunctions, jobFucntions, createJobFunctions, singleJobFunction } = ManageJobFunctions()
    const { setFieldValue, singleJob }    =   props;

  

  // const [Groups, setGroups] = useState<Group[]>([])

  const [getGrp, setGrp] = useState<string | undefined>();
  const [GroupData, setGroupData] = useState<Group[] | undefined>();

  const [selectedJobFuns, setSelectedJobFuns] = useState<Group[]>([]);

  const [GroupRes, setGroupRes] = useState(true);
  const [transformedOptions, setTransformedOptions] = useState<Group[] | undefined>();
  // let transformedOptions: Group[] | undefined;
  let getObjects: Group[] | undefined;

  
  useEffect(() => {    
    getJobFunctions();
    // setSelectedJobFuns([1, 3])

    // console.log("singleJob", singleJob.job_function)

    if(singleJob?.job_function) {
        setSelectedJobFuns(singleJob?.job_function)
    }

  }, []);


  const InsertGroup = async () => {
    if (getGrp) {

      console.log('getGrp', getGrp)
      const getGroup: any = await createJobFunctions({name:getGrp})

      console.log('getGroup', getGroup)

        const newItem = { id: getGroup?.data?.id, name: getGroup?.data?.name };    

      setSelectedJobFuns([...selectedJobFuns, newItem])

      let newList: any = singleJob?.job_function?.map((obj: any) => obj.id) || [];
      
      // setFieldValue('job_function_ids', newList)
      setFieldValue('job_function_ids', [...newList, newItem?.id]);
      setGrp(undefined);


    };
      // const newGroup = { label: getGroup.data.createGroup.name, value: getGroup.data.createGroup.id };    

      // setSelectedJobFuns([...selectedGroups, newGroup])
      
     

    //   // Update the state or props as needed
    //   if (props?.InputOptionCount) {
    //     console.log('InputOptionCount', InputOptionCount)
    //     const updatedInputDetailsArray = await InputDetails.map((item: any, index: any) =>
    //       index === optionIndex ? { ...item, groups: [...item.groups, newGroup.value] } : item
    //     );
    //     console.log('updatedInputDetailsArray', updatedInputDetailsArray)
    //     setInputDetails(updatedInputDetailsArray);
    //   } else {
    //     setInputDetails((prevState: any) => ({ ...prevState, groups: [...(prevState.groups || []), newGroup.value] }))
    //   }
    //   setGroupRes(false);
    //   setGrp(undefined);
      
    // }
  };

  const handleSearch = async (e: string) => {
    let Group = await jobFucntions?.find(function (element:any) {
      return element.name === e;
    });
    if (Group) {
      setGrp(undefined);
    } else {
      setGrp(e);
    }
  };

  

  return (
    <div className="form-group mt-3">
      {/* <label>Select Job Category*:</label> */}

      <div >
        <div >
          <Multiselect
            style={{ borderRadius: "0px" }}
            isObject={true}
            placeholder='Job Category'
            onRemove={async (removed) => {
            
              let grpRmv: any = removed.map((obj: any) => obj.id);
              // console.log('removed groups', grpRmv)
              //   console.log('removed', removed)

              setSelectedJobFuns(removed)
            //   setJobInfo({...jobInfo, job_function_ids: grpRmv})
              setFieldValue('job_function_ids', grpRmv)
              setGrp(undefined);
             
            }}
            onSearch={handleSearch}
            onSelect={async (add) => {
                // console.log('add grps', add)
              let grpAdd: any = await add.map((obj: any) => obj.id);

              // console.log('grpAdd', grpAdd)
              
              setSelectedJobFuns(add);

            //   setJobInfo({...jobInfo, job_function_ids: grpAdd})
            setFieldValue('job_function_ids', grpAdd)
            setGrp(undefined);
             

            }}
            options={jobFucntions}
            displayValue="name"
            showCheckbox
            selectedValues={((selectedJobFuns?.length > 0) || (!GroupRes)) ? selectedJobFuns : null}
          // selectedValues={selectedGroups}
          />
        </div>
        <div className="col-md-2 px-0">
          {getGrp ? (
            <button type='button' className="btn btn-dark btn-md" onClick={InsertGroup}>
              Add
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default JobFunctionsComponent;