import { Select, Switch, Table, Input } from 'antd';
import { Form, Formik } from 'formik'
import { User } from 'models/user.model';
import React, { useEffect, useState } from 'react'
import ManageJobsService from 'services/ManageJobsService/manage.jobs.service';
import { LocalStorage } from 'shared/utils/localStorageHelpers';
import InputField from 'shared/components/InputField'
import JobFunctionsComponent from '../../jobFunctions'
import { useLocation, useNavigate } from 'react-router-dom';
import CompanyDashboadrPanel from '../shared';

// import JobSkillsComponent from "../../jobSkills"


// import TextEditor from './TextEditor';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import ReactQuill from 'react-quill';

const JobApplications = () => {


    const location = useLocation();
    const navigate = useNavigate()
    const jobPostId = location.state?.jobID
    const data = LocalStorage.getItem("USER")




    // const [isCreation, setIsCreation] = useState(false)
    const [skills, setSkills] = useState([]);

    const { updateJobApplicationStatus, getJobPosts, getSingleJobPost, updateJobPost, deleteJobPost, jobAppliedStatus, jobs, singleJob, jobAppliedUsers } = ManageJobsService()

    // console.log('jobPostId', jobPostId);
    console.log('jobAppliedUsers', jobAppliedUsers)


    //fetch the job seekers details from the array
    // const appliedJobSeekersInfo = jobAppliedUsers.map((obj:any) => obj.job_seeker);
    // const JobSeekersInfo = appliedJobSeekersInfo.flat()

    const JobSeekersInfo = jobAppliedUsers.map((item: any) => {
        const jobSeekers = item.job_seeker.map((seeker: any) => ({
            ...seeker,
            jobAppStatus: item.status
        }));
        return jobSeekers;
    }).flat();

    console.log('appliedJobSeekersInfo', JobSeekersInfo)

    const [formTable, setFormTable] = useState(true)
    const [updateApplicationStatus, setUpdateApplicationStatus] =   useState(false)
    // const [postID, setPostID] = useState();
    const [successMessage, setSuccessMessage] = useState('');
    const [recordDelete, setRecordDelete] = useState(false);
    let arrayOfIds

    const [jobInfo, setJobInfo] = useState({
        title: "",
        job_details: "",
        requirements: "",
        salary: "",
        application_deadline: "",
        maximum_experience: "",
        minimum_experience: "",
        mode: "",
        job_type: "",
        status: "",
        job_function_ids: [],
        company_ids: "",
        location_ids: "",
        // skills: []

    });

    useEffect(() => {
        getJobPosts()
        jobAppliedStatus(jobPostId)
        setUpdateApplicationStatus(false)
    }, [formTable, updateApplicationStatus])

    

    // const filterOption = (input: string, option?: { label: string; value: string }) =>
    //     (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const handleSelectChange = () => {

    }

    const columns: any = [
        {
            title: 'User name',
            dataIndex: 'user_name',
            key: 'user_name',
            filter: true,
            onFilter: (value: any, record: any) => record.user_name.toLowerCase().includes(value.toLowerCase()),
            render: (value: any, record: any) => <span style={{cursor:'pointer', color:'#43c5FF'}} title='View Job Applicant Details' onClick={()=> {
                // console.log('record', record)
                navigate('/jobApplicant', {state: {applicantId: record.id}})
            }}>{record.user_name}</span>
        },            
        {
            title: 'E-Mail',
            dataIndex: 'email',
            key: 'email',
            filter: true,
            onFilter: (value: any, record: any) => record.email.toLowerCase().includes(value.toLowerCase())
            // render: (name: any, data: any) => <p>{data?.company?.company_name}</p>
        },
        {
            title: 'Mobile',
            dataIndex: 'phone_number',
            key: 'phone_number'
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender'
        },
        {
            title: 'Status',
            dataIndex: 'jobAppStatus',
            key: 'jobAppStatus',
            filter: true,
            onFilter: (value: any, record: any) => record.jobAppStatus.toLowerCase().includes(value.toLowerCase())
        },
        {
            title: 'Actions',
            dataIndex: '',
            key: 'actions',
            render: (action: any, data: any) =>

          {  
            return <>
                    <select className='managedropdown' value={data?.jobAppStatus} onChange={async (e) => {
                        await updateJobApplicationStatus(jobPostId, data?.id, e.target.value)
                        setUpdateApplicationStatus(true)
                    }}>
                        <option value="">Select Status</option>
                        <option value="Applied">Applied</option>
                        <option value="Shortlisted">Shortlisted</option>
                        <option value="Rejected">Rejected</option>
                        <option value="Hired">Hired</option>
                    </select>
                </>
          }


        }
    ];

    // Add unique keys to each column
    columns.forEach((column: any, index: any) => {
        column.key = index.toString();
    });


    if (singleJob?.job_function) {
        // Extracting only the 'id' values and storing them in a new array
        arrayOfIds = singleJob?.job_function.map((obj: any) => obj.id);
        console.log('arrayOfIds', arrayOfIds)
    }

    console.log('singleJob', singleJob)



    //pagination

    const { Search } = Input;
    const [filteredData, setFilteredData] = useState([]);

    const onSearch = (value: string) => {
        const lowercasedValue = value.toLowerCase();
        const filteredData = JobSeekersInfo.filter((record: any) =>
            Object.values(record).some((val: any) =>
                String(val).toLowerCase().includes(lowercasedValue)
            )
        );
        setFilteredData(filteredData);
    };

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10, // Change this based on your desired page size
    });

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        setPagination({
            ...pagination,
        });
    };


    return (

        <>
            <div className="mt-50"></div>
            <main id="main">
                <div className="page-title">
                    <nav className="breadcrumbs">
                        <div className="container">
                            <ol>
                                <li><a href="index.php">Home</a></li>
                                <li className="current">Company Dashboard</li>
                            </ol>
                        </div>
                    </nav>
                </div>
                <section className="f0f5f3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="side-nav ">
                                    {/* <div className="profile text-center">
                                        <img src="assets/img/Companylogos/Companylogos1.png" alt="Salesforce Logo" className="img-fluid" />
                                        <h4>{data?.user?.name}</h4>
                                    </div> */}
                                    <CompanyDashboadrPanel />
                                </div>
                            </div>
                            <div className="col-md-9 ">
                                {formTable && <div style={{ marginTop: "20px" }}>
                                    {/* <button onClick={() => { setFormTable(false) }}>Create Job</button> */}
                                    <Search
                            placeholder="Search by user name, email, etc."
                            onSearch={onSearch}
                            style={{ marginBottom: 16, width: '350px' }}
                        />

                                    <Table
                                        // dataSource={JobSeekersInfo}
                                        className='tableScroll tableScroll'
                                        dataSource={filteredData.length > 0 ? filteredData : JobSeekersInfo}
                                        columns={columns}
                                        pagination={{
                                            ...pagination,
                                            total: JobSeekersInfo.length, // Set the total number of items
                                        }}
                                        onChange={handleTableChange}
                                        // pagination={false}
                                        /></div> }


                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default JobApplications