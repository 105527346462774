import React, { useState, useContext } from "react";
import axiosInstance from "interceptor/axiosInstance";
import { Token } from "models/token.model";
import { JobSeekerUser, User } from "models/user.model";
import { useNavigate } from "react-router-dom";
import { ApiRoutes } from "routes/routeConstants/apiRoutes";
import { deserialize, serialize } from "serializr";
import { LocalStorage } from "shared/utils/localStorageHelpers";
import ContextData from "views/useContext";
// import ContextData from 'views/useContext';

const CertificationTypes = () => {
    

    const { setData } = useContext<any>(ContextData);

    const [error, setError] = useState<Error | unknown>();

    const [loading, setLoading] = useState(false);
    const [certificationTypes, setCertificationTypes] = useState<any>([])
    


    const getCertificationTypes = async () => {
        try {
            setLoading(true);
            const desiredData = await axiosInstance.get(
                ApiRoutes.CERTIFICATION_TYPES
            );
            // const desiredData = deserialize(User, desiredInfo.data?.results as User[]);
            // setCompanyEmployees(desiredData);

            // console.log('companyTypeData', desiredData?.data?.currency)
            setCertificationTypes(desiredData?.data?.certification)
            return desiredData?.data?.certification;
        } catch (error) {
            console.log(error);
        }
    };

    

    



    return {
        loading,
        getCertificationTypes,
        certificationTypes
    };
};

export default CertificationTypes;
