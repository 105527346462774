import React, { useEffect, useState } from 'react';
// import Sel from 'multiselect-react-dropdown';
import { Select } from 'antd';
// import ManageSkills from 'services/MetaList/skills.service';
import ManageLocations from 'services/MetaList/locations.service';


export interface Group {
  id: number;
  name: string;
}

function CountryComponent(props: any) {

  const Option = Select.Option;

  // const { getSkills, skills, createJobSkills, singleSkill } = ManageSkills()
  const { getCountry, country } = ManageLocations()
  const [formatLocations, setFormatLocations] = useState<any>([])
  const { setFieldValue, singleJob, selectedValue } = props;



  // const [Groups, setGroups] = useState<Group[]>([])

  const [getGrp, setGrp] = useState<string | undefined>();
  // const [GroupData, setGroupData] = useState<Group[] | undefined>();

  const [selectedSkills, setSelectedSkills] = useState<Group[]>([]);

  // const [GroupRes, setGroupRes] = useState(true);
  // const [transformedOptions, setTransformedOptions] = useState<Group[] | undefined>();
  // let transformedOptions: Group[] | undefined;
  // let getObjects: Group[] | undefined;


  useEffect(() => {
    // getSkills();
    // getLocations();
    getCountry();
    // setSelectedJobFuns([1, 3])

    // console.log("singleJob", singleJob?.skills)

    // if (singleJob?.skills) {
    //   setSelectedSkills(singleJob?.skills)
    // }

  }, []);

  // useEffect(() => {
  //   // Map the locations to the desired format
  //   let infoLocaiton = country?.map((loc: any) => {
  //     return { label: loc.name, value: loc.id };
  //   });

  //   // Set the formatted locations in the state
  //   setFormatLocations(infoLocaiton);
  // }, [country]); // Add locations as a dependency to useEffect




  // const handleSearch = async (e: string) => {
  //   let Group = await country?.find(function (element: any) {
  //     return element.name === e;
  //   });
  //   if (Group) {
  //     setGrp(undefined);
  //   } else {
  //     setGrp(e);
  //   }
  // };

  const onSelectChange = (value: any) => {
    setFieldValue('companyCountry', value);

    
  }

  //   let infoLocaiton = locations?.map((loc: any) => {
  //     return {label: loc.name, value: loc.id}
  //   })

  //   setFormatLocations(infoLocaiton)

  // console.log("singleJob", singleJob)



  return (
    <>
    
          {/* <Select
            showSearch
            placeholder="Country"
            optionFilterProp="children"
            onChange={onSelectChange}
            value={selectedValue}
            // onSearch={onSearch}
            // filterOption={filterOption}
            options={formatLocations}
            style={{ width: "100%" }}
          /> */}

<Select
        showSearch
        style={{ width: '100%' }}
        placeholder="Select Country"
        optionFilterProp="children"
        onChange={onSelectChange}
        value={selectedValue}
        filterOption={(input, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {
          country?.map((item: any) => {
            return (
              <Option value={item?.id}>{item?.name}</Option>
            )
          })
        }
      </Select>
        
        {/* <div className="col-md-2 px-0">
          {getGrp ? (
            <button type='button' className="btn btn-dark btn-md" onClick={InsertItem}>
              Add
            </button>
          ) : null}
        </div> */}
    </>
  );
}

export default CountryComponent;