import { Select, Switch, Table } from "antd";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { User } from "models/user.model";
import React, { useEffect, useState } from "react";
// import ManageJobsService from 'services/ManageJobsService/manage.jobs.service';
import UserService from "services/AuthService/auth.service";
import InputField from "shared/components/InputField";
import { LocalStorage } from "shared/utils/localStorageHelpers";
import JobFunctionsComponent from "../../jobFunctions";
import CompanyDashboadrPanel from "../shared";
import SuperAdminDashboadrPanel from "../shared/SuperAdminDashboardPanel";
// import JobSkillsComponent from "../../jobSkills"

// import TextEditor from './TextEditor';
import "react-quill/dist/quill.snow.css"; // Import the styles
import ReactQuill from "react-quill";

const ManageAdmins = (props: any) => {
  const navigate = useNavigate();
  const [isCreation, setIsCreation] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [gender, setGender] = useState("");

  // const [isCreation, setIsCreation] = useState(false)
  const [skills, setSkills] = useState([]);
  const data = LocalStorage.getItem("USER");

  console.log(data, '...authData')

  const {
    
    getCompanyAdmins,
    admins,
    updateCompanyAdm,
    updateCompanyEmployee
  } = UserService();

  // const { registerCompanyEmployee, getCompanyEmployee, companyEmployees, updateCompanyEmployee, deleteCompanyEmployee, getSingleCompanyEmployee, singleCompanyEmployees } = UserService()

  const [formTable, setFormTable] = useState(true);
  // const [postID, setPostID] = useState();
  const [successMessage, setSuccessMessage] = useState("");

  const [recordDelete, setRecordDelete] = useState(false);
  let arrayOfIds;

  const [jobInfo, setJobInfo] = useState({
    title: "",
    job_details: "",
    requirements: "",
    salary: "",
    application_deadline: "",
    maximum_experience: "",
    minimum_experience: "",
    mode: "",
    job_type: "",
    status: "",
    job_function_ids: [],
    company_ids: "",
    location_ids: "",
    // skills: []
  });

  // useEffect(()=>{
  //     getJobPosts()
  // },[formTable])

  useEffect(() => {
    getCompanyAdmins();
  }, [isUpdated, !isCreation]);


  const onSearch = (value: string) => {
    console.log("search:", value);
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  // const columns:any = [

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      render: (active: any, data: User) => {
        console.log(active,data,"..active")
        return(
        <Switch
          defaultChecked={active}
          onChange={(value: boolean) => {
            updateCompanyEmployee(data?.id!, { active: value });
            setIsUpdated(true);
          }}
        />
      )},
    }
  ];

  const onSelectChange = (value: string) => {
    console.log(`selected ${value}`);
    setGender(value);
  };

  const desiredColumns = columns?.filter(
    (column: any) => column?.title !== "Actions"
  );

  const adminFilerList = admins?.filter((admin: any) => admin?.role === 'Company Admin');

  console.log(adminFilerList, "...admins");

  return (
    <>
      <div className="mt-50"></div>
      <main id="main">
        <div className="page-title">
          <nav className="breadcrumbs">
            <div className="container">
              <ol>
                <li>
                  <a href="index.php">Home</a>
                </li>
                <li className="current">Company Dashboard</li>
              </ol>
            </div>
          </nav>
        </div>
        <section className="f0f5f3">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <div className="side-nav ">
                  <div className="profile text-center">
                    <img
                      src="assets/img/Companylogos/Companylogos1.png"
                      alt="Salesforce Logo"
                      className="img-fluid"
                    />
                    <h4>{data?.user?.name}</h4>
                  </div>
                  { data?.user?.role === 'Super Admin' ? <SuperAdminDashboadrPanel /> : <CompanyDashboadrPanel />}
                </div>
              </div>
              <div className="col-md-9 ">
                <div className="row mt-10" style={{ marginTop: "20px" }}>
                  {/* <h3>Employee Enrollment</h3> */}

                 
                    <div>

                      <Table
                        dataSource={adminFilerList}
                        columns={columns}
                        pagination={false}
                        className="margintop2p"
                      />
                    </div>
                </div>

                {/* {formTable && <div style={{ marginTop: "20px" }}>
                <button onClick={()=> { setFormTable(false)}}>Create Job</button>
                <Table
                dataSource={jobs}
                columns={columns} pagination={false} />
                </div>} */}
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default ManageAdmins;
