import React, { useEffect, useState } from 'react';
// import Sel from 'multiselect-react-dropdown';
import { Select } from 'antd';
// import ManageSkills from 'services/MetaList/skills.service';
import ManageLocations from 'services/MetaList/locations.service';




export interface Group {
  id: number;
  name: string;
}

function LocationsComponent(props: any) {

  const Option = Select.Option;

  // const { getSkills, skills, createJobSkills, singleSkill } = ManageSkills()
  const { locations, getLocations } = ManageLocations()
  const [formatLocations, setFormatLocations] = useState<any>([])

  const [filteredOptions, setFilteredOptions] = useState<any>([])
  const { setFieldValue, singleJob, selectedValue, name, countryId } = props;



  // const [Groups, setGroups] = useState<Group[]>([])

  const [getGrp, setGrp] = useState<string | undefined>();
  const [GroupData, setGroupData] = useState<Group[] | undefined>();

  const [selectedSkills, setSelectedSkills] = useState<Group[]>([]);

  const [GroupRes, setGroupRes] = useState(true);
  const [transformedOptions, setTransformedOptions] = useState<Group[] | undefined>();
  // let transformedOptions: Group[] | undefined;
  let getObjects: Group[] | undefined;


  useEffect(() => {
    getLocations();

    if (singleJob?.skills) {
      setSelectedSkills(singleJob?.skills)
    }

  }, []);

  // useEffect(() => {
  //   // Map the locations to the desired format
  //   let infoLocaiton = locations?.map((loc: any) => {
  //     return { label: loc.name, value: loc.id };
  //   });

  //   // Set the formatted locations in the state
  //   // setFormatLocations([{'label': 'All', 'value': ''}, ...infoLocaiton]);
  //   setFormatLocations(infoLocaiton);
  // }, [locations]); // Add locations as a dependency to useEffect


  // const InsertItem = async () => {
  //   if (getGrp) {

  //     console.log('getGrp', getGrp)
  //     const getGroup: any = await createJobSkills({name:getGrp})

  //     // console.log('getGroup', getGroup)

  //       const newItem = { id: getGroup?.data?.id, name: getGroup?.data?.name };    

  //       setSelectedSkills([...selectedSkills, newItem])

  //     let newList: any = singleJob?.skills.map((obj: any) => obj.id);

  //     // setFieldValue('skill_ids', newList)
  //     setFieldValue('skill_ids', [...newList, newItem?.id]);
  //     setGrp(undefined);


  //   };

  // };

  // const handleSearch = async (e: string) => {
  //   let Group = await locations?.find(function (element:any) {
  //     return element.name === e;
  //   });
  //   if (Group) {
  //     setGrp(undefined);
  //   } else {
  //     setGrp(e);
  //   }
  // };

  // const handleSearch = async (searchText: any) => {
  //   const filtered = await formatLocations.filter((item: any) =>
  //     item.label.toLowerCase().includes(searchText.toLowerCase())
  //   );
  //   filtered && setFilteredOptions(filtered)
  // };


  const onSelectChange = (value: any) => {
    setFieldValue('companyLocation', value);
  }

  //   let infoLocaiton = locations?.map((loc: any) => {
  //     return {label: loc.name, value: loc.id}
  //   })

  //   setFormatLocations(infoLocaiton)

  // console.log("filteredOptions", filteredOptions)

  // console.log(countryId, '...countryId')
  // console.log(locations, '...locations')



  return (
    <>
      {/* <label>Select Skills*:</label> */}


      <Select
        showSearch
        style={{ width: '100%', color:'#000', display:'Block'}}
        placeholder="Select Location"
        optionFilterProp="children"
        onChange={onSelectChange}
        value={selectedValue}
        filterOption={(input, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {/* {
          locations.map((item: any) => {
            return (
              <Option value={item?.id}>{item?.name}</Option>
            )
          })
        } */}
        
        {
          locations
          .filter((item:any) => item.country === countryId)
          .map((location:any) => (
            <Option value={location.id}>{location.name}</Option>
          ))
        }
      </Select>


      {/* <div className="col-md-2 px-0">
          {getGrp ? (
            <button type='button' className="btn btn-dark btn-md" onClick={InsertItem}>
              Add
            </button>
          ) : null}
        </div> */}


    </>

  );
}

export default LocationsComponent;

