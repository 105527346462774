import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { LocalStorage } from 'shared/utils/localStorageHelpers';
import ManageJobsService from 'services/ManageJobsService/manage.jobs.service';
import ContextData from 'views/useContext';

const AppliedJobs = () => {

    const navigate = useNavigate();

    const { postJob, getJobPosts, applyJobPost, appliedJobsList, appliedJobs, jobs, singleJob } = ManageJobsService()

    const data = LocalStorage?.getItem("USER")

    //   if (LocalStorage?.getItem('USER')) {
    //     const localStorageUser = LocalStorage?.getItem('USER')
    //     token = localStorageUser?.user?.access_token;
    //     console.log(token)
    // }

    useEffect(() => {
        getJobPosts()
        appliedJobsList(data?.user)
    }, [])

    const applyJob = (async (jobID: any) => {

        // Get the current date
        const now = new Date();

        // Extract year, month, and day
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(now.getDate()).padStart(2, '0');

        // Format the date as "yyyy-mm-dd"
        const formattedDate = `${year}-${month}-${day}`;

        let jobInfo = {
            "job_seeker_ids": [data?.user?.id],
            "job_post_ids": jobID,    // Replace with the ID of the job post
            "application_date": formattedDate,
            "status": "Applied"  // Use a valid status value from your choices
        }
        // console.log('jobInfo', jobInfo)

        await applyJobPost(jobInfo)
    })


    console.log('appliedJobs', appliedJobs)


    const jobList = () => {


        return appliedJobs?.map((job: any, index: any) => {

            return (

                <div className="jobs-box">
                    <div className="row flexing align-items-center">

                        <div className="col-md-1 col-3">
                            <div className="company-logo">
                                <img src="assets/img/Companylogos/Companylogos1.png" alt="Salesforce Logo" className="img-fluid" />

                            </div>
                        </div>
                        <div className="col-md-4 col-9">
                            <div className="company-info "  style={{ paddingLeft: '5px' }}>
                                <ul style={{ paddingLeft: '0px' }}>
                                    {

                                        job?.job_post?.job_function?.map((item: any, index: any) => {
                                            return (
                                                <li className="green-text" style={{ fontSize: '10px', listStyle: 'none', width: '150px' }}>{item?.name}</li>

                                            )
                                        })
                                    }

                                </ul>

                                <h4>{job?.job_post?.title}</h4>
                                <div className="address">
                                    <span> 52 Irving Pl, NY</span>
                                    <span>{job?.application_date}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-12">

                            <span className="float-left desktop-view"><b>${job?.job_post?.salary}/month <br /> {job?.job_post?.location?.name}</b> </span>
                            <span className="float-left mobile-view"><b>${job?.job_post?.salary}/month - {job?.job_post?.location?.name}</b> </span>


                        </div>

                        <div className="col-md-2 col-6">
                            <div className="pricing">
                                <span className=" applyViewMore"><a href="javascript:void(0)" style={{cursor:'pointer'}} onClick={()=>navigate('/job-details', {state: {jobPostId: job?.job_post?.id}})}>{}View JD</a></span>

                            </div>
                        </div>

                        <div className="col-md-2 col-6">
                            <div className="pricing">
                                {
                                    job?.status === 'Rejected' ? 
                                    
                                        <a href="#" className='redbtn text-center'> {job?.status}</a>
                                    
                                    :

                                    job?.status === 'Shortlisted' ?


                                    <a href="#" className='orange text-center'> {job?.status}</a>

                                    :

                                    job?.status === 'Applied' ?


                                    <a href="#" className='blue text-center'> {job?.status}</a>

                                    :

                                    <a href="#" className='green text-center'> {job?.status}</a>
                                }
                                    {/* <a href="#" className='redbtn text-center'> {job?.status}</a> */}
                            </div>
                        </div>

                        {/* <div className="col-md-2 ">
                            <div className="pricing">
                                <span className=" apply"><a href="#">edit</a></span>
                            </div>
                        </div> */}

                    </div>


                </div>
            )
        }

        )
    }



    return (
        <>
            {/* <div className="mt-50"></div> */}
            {/* <main id="main">

                           


                            <section className="f0f5f3">
                                <div className="container">
                                    <div className="row"> */}

            <div className="col-md-9 ">
                <div className="container">
                    <h4>Jobs Applied</h4>
                    {jobList()}


                </div>
            </div>

            {/* </div>
                                </div>
                            </section>



                        </main> */}
        </>
    )
}

export default AppliedJobs;