import * as Yup from "yup";

export const validationSchema = Yup.object({
  name: Yup.string().required('Name is required').min(3, 'Name must be at least 3 characters'),
  password: Yup.string().required('Password is required')
              .min(8, 'Password must be at least 8 characters long')
              .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
              .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
              .matches(/^(?=.*[0-9])/,'Password must contain at least one number')
              .matches(/^(?=.*[!@#$%^&*])/,'Password must contain at least one special character'),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required")
    .test("valid-domain", "Invalid domain extension", (value) => {
      // Custom validation for domain extension
      if (value) {
        const domainRegex =
          /^[A-Za-z0-9._%+-]+@([A-Za-z0-9-]+\.)+[A-Za-z]{2,}$/;
        return domainRegex.test(value);
      }
      return true; // Allow empty values, Yup's required handles that
    }),
  companyName: Yup.string().required("Company Name Required"),
  companyDescription: Yup.string().required("Company Description is Required").min(5, "Description must be at least 5 characters"),
  companyPhoneNumber: Yup.string()
    .matches(/^[0-9]+$/, "Mobile number must only contain numbers")
    .required("Mobile number is required"),
  companyWebsite: Yup.string().url("Invalid URL").required("Company website required"),
  companyStreet: Yup.string().required("Street is required"),
  //  companyCity: Yup.string().required('Required'),
  companyCountry: Yup.string().required("Country  is required"),
  // state: Yup.string().required('Required'),
  companyEmail: Yup.string()
    .email("Invalid email format")
    .required("Email is required")
    .test("valid-domain", "Invalid domain extension", (value) => {
      // Custom validation for domain extension
      if (value) {
        const domainRegex =
          /^[A-Za-z0-9._%+-]+@([A-Za-z0-9-]+\.)+[A-Za-z]{2,}$/;
        return domainRegex.test(value);
      }
      return true; // Allow empty values, Yup's required handles that
    }),
  companyPostalCode: Yup.string().required("Postal code required"),
  companyIsdCode: Yup.string().required("Isd Code required"),
  companyProvince: Yup.string().required("Province is required"),
  companyType: Yup.array()
    .min(1, "Please select at least one type")
    .required("Type selection is required"),
  companyLocation: Yup.string().required("Location  is required"),

  // productID: Yup.string().required('Product is required').min(1,'Product is required'), //minimum value should be greater than 0 ,
  // collectionTypeID: Yup.string().max(255).required('Collection Type is required'),
});
