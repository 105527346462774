import React, { useContext, useEffect, useState } from "react";
import { ErrorMessage, Form, Formik } from "formik";
import InputField from "shared/components/InputField";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router-dom";
import { LocalStorage } from "shared/utils/localStorageHelpers";
import ManageJobsService from "services/ManageJobsService/manage.jobs.service";
import ContextData from "views/useContext";
import UserService from "services/AuthService/auth.service";
import "./jobseekerAS.css"
import {
  Input,
  Tooltip,
  Button,
  Checkbox,
  Select,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import LocationsComponent from "views/locations";
import CountryComponent from "views/country";
import { UploadOutlined } from "@ant-design/icons";
import { string } from "yup";
import { jobseekerProfileUpdateSchema } from "./jobseekerProfileUpdateSchema";
import CertificationTypeComponent from "views/certificationType";

const JobseekerProfileUpdate = () => {
  const navigate = useNavigate();

  const {
    postJob,
    getJobPosts,
    applyJobPost,
    appliedJobsList,
    appliedJobs,
    jobs,
    singleJob,
  } = ManageJobsService();
  const { getJobSeekerUser, singleUser, updateJobSeekerUser } = UserService();
  const [updateStatus, setUpdateStatus] = useState<any>("");
  const [customErr, setCustomErr] = useState("");
  const [profileCustomErr, setProfileCustomErr] = useState<any>("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [profileFileList, setProfileFileList] = useState<UploadFile[]>([]);
  const [resume, setResume] = useState<any>();
  const [profile, setProfile] = useState<any>();

  const currentUser = LocalStorage?.getItem("USER");

  useEffect(() => {
    getJobPosts();
    appliedJobsList(currentUser?.user);
    getJobSeekerUser(currentUser?.user?.id);
  }, []);

  // const handleChange: UploadProps['onChange'] = (info) => {

  //   if (info.file && info.file.originFileObj) {
  //     setResume(info.file.originFileObj);
  //   }

  //   let newFileList = [...info.fileList];

  //   // 1. Limit the number of uploaded files
  //   // Only to show two recent uploaded files, and old ones will be replaced by the new
  //   newFileList = newFileList.slice(-2);

  //   // 2. Read from response and show file link
  //   newFileList = newFileList.map((file) => {
  //     // console.log(file, "...fiel")
  //     if (file.response) {
  //       // Component will show file.url as link
  //       file.url = file.response.url;
  //     }
  //     return file;
  //   });

  //   setFileList(newFileList);
  // };

  // const handleChangeProfile: UploadProps['onChange'] = (info) => {

  //   if (info.file && info.file.originFileObj) {
  //     setProfile(info.file.originFileObj);
  //   }

  //   let newFileList = [...info.fileList];

  //   // 1. Limit the number of uploaded files
  //   // Only to show two recent uploaded files, and old ones will be replaced by the new
  //   newFileList = newFileList.slice(-2);

  //   // 2. Read from response and show file link
  //   newFileList = newFileList.map((file) => {
  //     // console.log(file, "...fiel")
  //     if (file.response) {
  //       // Component will show file.url as link
  //       file.url = file.response.url;
  //     }
  //     return file;
  //   });

  //   setProfileFileList(newFileList);
  // };

  const handleChange: UploadProps["onChange"] = (info) => {
    let newFileList = [...info.fileList];

    if (info.file && info.file.originFileObj) {
      setResume(info.file.originFileObj);
    }

    if (info.fileList.length > 0 && newFileList.length > 0) {
      // 1. Limit the number of uploaded files
      // Only to show two recent uploaded files, and old ones will be replaced by the new
      newFileList = newFileList.slice(-2);

      // 2. Read from response and show file link
      newFileList = newFileList.map((file) => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.url;
        }
        return file;
      });
    }

    setFileList(newFileList);
  };

  const handleChangeProfile: UploadProps["onChange"] = (info) => {
    let newFileList = [...info.fileList];

    if (info.file && info.file.originFileObj) {
      setProfile(info.file.originFileObj);
    }

    if (info.fileList.length > 0 && newFileList.length > 0) {
      // 1. Limit the number of uploaded files
      // Only to show two recent uploaded files, and old ones will be replaced by the new
      newFileList = newFileList.slice(-2);

      // 2. Read from response and show file link
      newFileList = newFileList.map((file) => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.url;
        }
        return file;
      });
    }

    setProfileFileList(newFileList);
  };

  const props = {
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    onChange: handleChange,
    multiple: false,
  };

  const profileProps = {
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    onChange: handleChangeProfile,
    multiple: false,
  };


  console.log({singleUser})


  return (
    <>
      <div className="col-md-9 padding-left-2rem pbg">
        <Formik
          initialValues={{
            user_name: singleUser?.user_name || "",
            isd_code: singleUser?.isd_code || "",
            phone_number: singleUser?.phone_number,
            email: singleUser?.email,
            date_of_birth: singleUser?.date_of_birth || "",
            skills: singleUser?.skills || "",
            bio: singleUser?.bio || "",
            gender: singleUser?.gender || "",
            available_date: singleUser?.available_date || "",
            street: singleUser?.street || "",
            province: singleUser?.province || "",
            state: singleUser?.state || "",
            city: singleUser?.city || "null",
            postal_code: singleUser?.postal_code || "",
            country: singleUser?.country || "",

            certification_one: singleUser?.certification_one || [],
            certification_two: singleUser?.certification_two || "null",
            portfolio: singleUser?.portfolio || "",
            linkedin: singleUser?.linkedin || "",

            job_type: singleUser?.job_type || "",
            location_one: singleUser?.location_one || "null",
            location_two: singleUser?.location_two || "null",
            location_three: singleUser?.location_three || "null",
            salary_expect: singleUser?.salary_expect || "",
            languages: singleUser?.languages || "",

            education_level: singleUser?.education_level || "",
            degree_name: singleUser?.degree_name || "null",
            college_name: singleUser?.college_name || "",

            university: singleUser?.university || "",
            specialization: singleUser?.specialization || "",
            degree_type: singleUser?.degree_type || "",
            start_date: singleUser?.start_date || "",
            end_date: singleUser?.end_date || "",
            high_qual: singleUser?.high_qual || "null",
            trail_blaizer_profile: singleUser?.trail_blaizer_profile || "",
            companyLocation: singleUser?.location?.id || "",
            companyCountry: parseInt(singleUser?.country) || "",
            resume: singleUser?.resume || "",
            display_picture: singleUser?.display_picture || "",

            // termsAndConditions: singleUser?.terms_accepted_at || false,
          }}
          enableReinitialize
          validationSchema={jobseekerProfileUpdateSchema}
          // validate={(values) => {

          //   const errors: any = {};

          //   if (values?.degree_type === "") {
          //     errors.degree_type = "Please select at least one option";
          //   }
          //   if (values.high_qual === "") {
          //     errors.high_qual = "Please select at least one option";
          //   }
          //   if (values.currencyType_ids?.length === 0) {
          //     errors.currencyType_ids =
          //       "Please select at least one option";
          //   }
          //   if (values.skill_ids?.length === 0) {
          //     errors.skill_ids = "Please select at least one option";
          //   }
          //   if (values.job_function_ids?.length === 0) {
          //     errors.job_function_ids =
          //       "Please select at least one option";
          //   }
          //   if (values.companyLocation === "") {
          //     errors.companyLocation = "Please select at least one location";
          //   }
          //   if (values.companyCountry === "") {
          //     errors.companyCountry = "Please select at least one country";
          //   }

          //   if (values.job_type === "") {
          //     errors.job_type = "Job Type is required";
          //   }
          //   if (values.bio === "") {
          //     errors.bio = "bio";
          //   }

          //   return errors;
          // }}
          onSubmit={async (values) => {

            // console.log("values", values)

            // const desiredValues = { ...values, country: values?.companyCountry, location: values?.companyLocation }

            // const updateResp = await updateJobSeekerUser(currentUser?.user.id, desiredValues);

            // Create a new FormData instance
            const formData = new FormData();

            // Append form fields to FormData
            // Object.entries(values).forEach(([key, value]) => {
            //   formData.append(
            //     key,
            //     typeof value === "boolean" ? String(value) : value
            //   );
            // });


            // Iterate through the values object
            Object.entries(values).forEach(([key, value]) => {
              // Check if the value is an array and if it is the certification_one array
              if (Array.isArray(value) && key === "certification_one") {
                // Iterate through the array and append each element separately
                value.forEach((certification) => {
                  formData.append(key, certification);
                });
              } else {
                // For other values, append them to the FormData
                formData.append(
                  key,
                  typeof value === "boolean" ? String(value) : value
                );
              }
            });

            if (resume) {
              // Assuming 'resume' is a File object obtained from a file input
              const allowedFormats = ["pdf", "doc", "docx"]; // Add or modify allowed file formats
              const maxFileSizeInBytes = 1 * 1024 * 1024; // 1 MB, adjust as needed

              // Check file format
              const fileName = resume.name;
              const fileFormat = fileName.split(".").pop().toLowerCase();
              if (!allowedFormats.includes(fileFormat)) {
                setCustomErr(
                  "Invalid file format. Please upload a PDF, DOC, or DOCX file."
                );
                return; // or handle the error accordingly
              }

              // Check file size
              if (resume.size > maxFileSizeInBytes) {
                setCustomErr(
                  "File size exceeds the maximum allowed size (5 MB)."
                );
                return; // or handle the error accordingly
              }

              // If validation passes, append the file to form data
              formData.append("resume", resume);
            } else {
              formData.delete("resume");
            }

            if (profile) {
              // Assuming 'resume' is a File object obtained from a file input
              const allowedFormats = ["png", "jpg", "jpeg"]; // Add or modify allowed file formats
              const maxFileSizeInBytes = 1 * 1024 * 1024; // 1 MB, adjust as needed

              // Check file format
              const fileName = profile.name;
              const fileFormat = fileName.split(".").pop().toLowerCase();
              if (!allowedFormats.includes(fileFormat)) {
                setProfileCustomErr(
                  "Invalid file format. Please upload a PNG, JPG, or JPEG file."
                );
                return; // or handle the error accordingly
              }

              // Check file size
              if (profile.size > maxFileSizeInBytes) {
                setProfileCustomErr(
                  "File size exceeds the maximum allowed size (5 MB)."
                );
                return; // or handle the error accordingly
              }

              // If validation passes, append the file to form data
              formData.append("display_picture", profile);
            } else {
              formData.delete("display_picture");
            }

            formData.append("country", String(values?.companyCountry));
            formData.append("location_id", values?.companyLocation);
            // formData.append('active', '1')


            // console.log('formData', ...formData)

            const updateResp = await updateJobSeekerUser(
              currentUser?.user.id,
              formData
            );

            // console.log('updateResp', updateResp?.status)

            if (updateResp?.status === 200) {
              setUpdateStatus("Profile is updated successfully");
            }
          }}
        >
          {({ values, setFieldValue }) => (
            <Form data-aos="fade-up" data-aos-delay="200">
              <div>
                <span style={{ color: "green" }}>
                  {updateStatus ? updateStatus : null}
                </span>
                <h4>Account Settings</h4>
                {/* <form action="forms/contact.php" method="post" className="php-email-form aos-init aos-animate" data-aos="fade-up" data-aos-delay="200"> */}
                <div className="row gy-4">
                  <div className="col-md-4">
                    <InputField
                      type="text"
                      name="user_name"
                      placeholder="User Name"
                      value={values?.user_name}
                    />
                  </div>

                  {/* <div className="col-md-4">
                    <input type="text" className="form-control" placeholder="Last Name" required  />
                  </div> */}

                  {/* <div className="col-md-4">
                    <label>Profile Image: </label>
                    <InputField type="file" placeholder="Upload Profile Image" name="profile_image" />
                  </div> */}

                  <div className="col-md-4">
                    <Upload
                      {...profileProps}
                      fileList={profileFileList}
                      name="display_picture"
                    >
                      <Button icon={<UploadOutlined />}>
                        Upload Profile Image
                      </Button>
                    </Upload>
                    <img
                      src={
                        values?.display_picture
                          ? `http://localhost:8000${values?.display_picture}`
                          : ""
                      }
                      alt="Salesforce Logo"
                      width="50"
                      height="50"
                      className="img-fluid"
                    />
                    <span style={{ fontSize: "12px", color: "red" }}>
                      {profileCustomErr ? profileCustomErr : null}
                    </span>
                  </div>

                  <div className="col-md-4">
                    <Tooltip title="Date of Birth">
                      <InputField
                        type="date"
                        name="date_of_birth"
                        placeholder="Date of Birth"
                        value={values?.date_of_birth}
                      />
                    </Tooltip>
                    {/* <label style={{ marginRight: '10px' }}>Date Of Birth: </label> */}
                  </div>

                  <div className="col-md-4">
                    <InputField
                      type="mail"
                      name="email"
                      placeholder="Email Address"
                      value={values?.email}
                    />
                  </div>
                  <div className="col-md-4">
                    <InputField
                      type="text"
                      placeholder="ISD Code"
                      value={values?.isd_code}
                      name="isd_code"
                    />
                  </div>

                  <div className="col-md-4">
                    <InputField
                      type="text"
                      placeholder="Mobile Number"
                      value={values?.phone_number}
                      name="phone_number"
                    />
                  </div>

                  {/* <div className="col-md-4">
                    <InputField type="text" placeholder="Skills" value={values?.skills} name='skills' />
                  </div> */}

                  <div className="col-md-12">
                    <label>Professional Summary: </label>
                    <ReactQuill className="Professional-Summary"
                      theme="snow"
                      value={values?.bio}
                      onChange={(value) => setFieldValue("bio", value)}
                    />
                    <ErrorMessage name="bio">
                      {(msg) => 
                      <div style={{ color: "#c9583e" }}>{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <h5>Educational Details</h5>
                  <div className="col-md-4">
                    <InputField
                      type="text"
                      name="education_level"
                      placeholder="Highest Education"
                      value={values?.education_level}
                    />
                  </div>

                  {/* <div className="col-md-4">
                    <InputField
                      type="text"
                      name="degree_name"
                      placeholder="Degree Name"
                      value={values?.degree_name}
                    />
                  </div> */}

                  <div className="col-md-4">
                    <InputField
                      type="text"
                      name="college_name"
                      placeholder="College Name"
                      value={values?.college_name}
                    />
                  </div>

                  <div className="col-md-4">
                    <InputField
                      type="text"
                      name="university"
                      placeholder="University/Board"
                      value={values?.university}
                    />
                  </div>

                  <div className="col-md-4">
                    <InputField
                      type="text"
                      name="specialization"
                      placeholder="Specialization"
                      value={values?.specialization}
                    />
                  </div>

                  <div className="col-md-4">
                    <select
                      name="degree_type"
                      // placeholder='Degree Type'
                      value={values?.degree_type}
                      onChange={(e) =>
                        setFieldValue("degree_type", e.target.value)
                      }
                    >
                      <option value="">Education Type </option>
                      <option value="fullTime">Full-Time</option>
                      <option value="partTime">Part-Time</option>
                      <option value="distance">
                        Distance Learning/Correspondence
                      </option>
                    </select>
                    <ErrorMessage name="degree_type">
                      {(msg) => <div className="input__error">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="col-md-2">
                    <Tooltip title="Start Date">
                      <InputField
                        type="date"
                        name="start_date"
                        placeholder="Start Date"
                        value={values?.start_date}
                      />
                    </Tooltip>
                  </div>

                  <div className="col-md-2">
                    <Tooltip title="End Date">
                      <InputField
                        type="date"
                        name="end_date"
                        placeholder="End Date"
                        value={values?.end_date}
                      />
                    </Tooltip>
                  </div>

                  {/* <div className="col-md-4">
                    <select
                      name="high_qual"
                      value={values?.high_qual}
                      onChange={(e) =>
                        setFieldValue("high_qual", e.target.value)
                      }
                      style={{ width: "100%" }}
                    >
                      <option value=""> Is this Highest Qualification </option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                    <ErrorMessage name="high_qual">
                      {(msg) => <div style={{ color: "#c9583e" }}>{msg}</div>}
                    </ErrorMessage>
                  </div> */}

                  <h5>Certifications</h5>
                  <div className="col-md-12">
                    {/* <InputField
                      type="text"
                      name="certification_one"
                      placeholder="Certifications"
                      value={values?.certification_one}
                    /> */}

                    <CertificationTypeComponent className="companyTypebar" setFieldValue={setFieldValue} singleUser={singleUser} />
                  </div>



                  {/* <div className="col-md-6">
                    <InputField
                      type="text"
                      name="certification_two"
                      placeholder="Certification 2"
                      value={values?.certification_two}
                    />
                  </div> */}

                  <div className="col-md-6">
                    <InputField
                      type="text"
                      name="portfolio"
                      placeholder="Portfolio URL"
                      value={values?.portfolio}
                    />
                  </div>

                  <div className="col-md-6">
                    <InputField
                      type="text"
                      name="linkedin"
                      placeholder="LinkedIn URL"
                      value={values?.linkedin}
                    />
                  </div>

                  {/* <div className="col-md-4">
                    <InputField
                      type="text"
                      name="location_one"
                      placeholder="Location 1"
                      value={values?.location_one}
                    />
                  </div>

                  <div className="col-md-4">
                    <InputField
                      type="text"
                      name="location_two"
                      placeholder="Location 2"
                      value={values?.location_two}
                    />
                  </div>

                  <div className="col-md-4">
                    <InputField
                      type="text"
                      name="location_three"
                      placeholder="Location 3"
                      value={values?.location_three}
                    />
                  </div> */}

                  <div className="col-md-4">
                    <InputField
                      type="text"
                      name="job_type"
                      placeholder="Job Type"
                      value={values?.job_type}
                    />
                  </div>

                  <div className="col-md-4">
                    <InputField
                      type="text"
                      name="salary_expect"
                      placeholder="Salary Expectations"
                      value={values?.salary_expect}
                    />
                  </div>

                  <div className="col-md-4">
                    <InputField
                      type="text"
                      name="languages"
                      placeholder="Languages Known"
                      value={values?.languages}
                    />
                  </div>

                  <div className="col-md-2">
                    {/* <label htmlFor="date" style={{ marginRight: '10px' }}>Availability Date:</label> */}
                    <Tooltip title="Availability Date">
                      <InputField
                        type="date"
                        name="available_date"
                        placeholder="availability date"
                        value={values?.available_date}
                      />
                    </Tooltip>
                  </div>

                  <div className="col-md-6">
                    <InputField
                      placeholder="Trail Blaizer Profile"
                      name="trail_blaizer_profile"
                      value={values?.trail_blaizer_profile}
                    />
                  </div>

                  <h5> Address Details </h5>
                  <div className="col-md-4">
                    <InputField
                      type="text"
                      name="street"
                      placeholder="Enter Address"
                      value={values?.street}
                    />
                  </div>

                  <div className="col-md-4">
                    <InputField
                      type="text"
                      name="province"
                      placeholder="Province"
                      value={values?.province}
                    />
                  </div>

                  {/* <div className="col-md-4">
                    <InputField type="text" name='city' placeholder="City" value={values?.city} />
                  </div> */}

                  {/* <div className="col-md-4">
                    <InputField type="text" name='state' placeholder="State" value={values?.state} />
                  </div> */}



                  <div className="col-md-4">
                    <CountryComponent
                      setFieldValue={setFieldValue}
                      singleJob={values}
                      selectedValue={values?.companyCountry}
                    />
                    <ErrorMessage name="companyCountry">
                      {(msg) => <div style={{ color: "#c9583e" }}>{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="col-md-4">
                    <LocationsComponent
                      setFieldValue={setFieldValue}
                      singleJob={values}
                      selectedValue={values?.companyLocation}
                      countryId={values?.companyCountry}
                    />
                    <ErrorMessage name="companyLocation">
                      {(msg) => <div style={{ color: "#c9583e" }}>{msg}</div>}
                    </ErrorMessage>
                  </div>

                  {/* <div className="col-md-4">
                    <InputField type="text" name='country' placeholder="Country" value={values?.country} />
                  </div> */}

                  <div className="col-md-4">
                    <InputField
                      type="text"
                      name="postal_code"
                      placeholder="Pin Code"
                      value={values?.postal_code}
                    />
                  </div>

                  {/* <div className="col-md-4">
                    <label htmlFor="resume">Upload Resume</label>
                    <InputField type="file" placeholder="Upload resume" className="form-control" name="resume" />
                  </div> */}

                  <div className="col-md-4">
                    <Upload {...props} fileList={fileList} name="resume">
                      <Button icon={<UploadOutlined />}>Upload Resume</Button>
                    </Upload>
                    {values?.resume ? <p>{values?.resume}</p> : null}
                    <span style={{ fontSize: "12px", color: "red" }}>
                      {customErr ? customErr : null}
                    </span>
                  </div>

                  <div className="col-md-12 ">
                    <a href="#">
                      {" "}
                      <button type="submit" className="button-submit">
                        Save
                      </button>
                    </a>
                  </div>
                </div>
                {/* </form> */}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default JobseekerProfileUpdate;
