import {serializable, alias, primitive} from 'serializr';

export class Token {
    @serializable(alias('access_token', primitive()))
    accessToken?: string;

    @serializable(alias('token_type', primitive()))
    tokenType?: "bearer";

    @serializable(alias('expires_in', primitive()))
    expiresIn?: string;

    @serializable(alias('refresh_token', primitive()))
    refreshToken?: string;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;
}
