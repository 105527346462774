import React, { useState, useContext } from "react";
import axiosInstance from "interceptor/axiosInstance";
import { Token } from "models/token.model";
import { JobSeekerUser, User } from "models/user.model";
import { useNavigate } from "react-router-dom";
import { ApiRoutes } from "routes/routeConstants/apiRoutes";
import { deserialize, serialize } from "serializr";
// import { LocalStorage } from "shared/utils/localStorageHelpers";
import ContextData from "views/useContext";
// import ContextData from 'views/useContext';

const ManageJobFunctions = () => {
    const navigate = useNavigate();

    const { setData } = useContext<any>(ContextData);

    const [error, setError] = useState<Error | unknown>();

    const [loading, setLoading] = useState(false);
    const [jobFucntions, setJobFunctions] = useState<any>([])
    const [singleJobFunction, setSingleJobFunction] = useState<any>()




    const createJobFunctions = async (data: any) => {
        try {
            setLoading(true);
            let resp = await axiosInstance.post(ApiRoutes.JOB_FUNCTIONS, data);
            setSingleJobFunction(resp?.data)

            return resp;
        } catch (error) {
            console.log(error);
        }
    };

    

    const getJobFunctionsWithoutLogin = async () => {
        try {
            setLoading(true);
            const desiredData = await axiosInstance.get(
                ApiRoutes.JOB_FUNCTIONS_WIHTOUT_LOGIN 
            );
            // const desiredData = deserialize(User, desiredInfo.data?.results as User[]);
            // setCompanyEmployees(desiredData);

            // console.log('desiredData', desiredData?.data?.job_function)



            setJobFunctions(desiredData?.data?.job_function)
            return jobFucntions;
        } catch (error) {
            console.log(error);
        }
    };



    const getJobFunctions = async () => {
        try {
            setLoading(true);
            const desiredData = await axiosInstance.get(
                ApiRoutes.JOB_FUNCTIONS
            );
            // const desiredData = deserialize(User, desiredInfo.data?.results as User[]);
            // setCompanyEmployees(desiredData);

            // console.log('desiredData', desiredData?.data?.job_function)



            setJobFunctions(desiredData?.data?.job_function)
            return jobFucntions;
        } catch (error) {
            console.log(error);
        }
    };

    // const getSingleJobPost = async (jobID: number) => {
    //     try {
    //         setLoading(true);
    //         const desiredInfo = await axiosInstance.get(
    //             ApiRoutes.POST_JOB + `/${jobID}`
    //         );

    //         // const desiredData = deserialize(User, desiredInfo.data as User);
    //         // setSingleCompanyEmployees(desiredData);

    //         console.log('jobList', desiredInfo?.data)


    //         // setJobs(desiredInfo?.data)
    //         setSingleJob(desiredInfo?.data)
    //         return desiredInfo;
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    // const updateJobPost = async (jobID: number, job: any) => {

    //     try {
    //         delete job?.id

    //         console.log('jobID', jobID)
    //         console.log('job', job)

    //         setLoading(true);
    //         const desiredInfo = await axiosInstance.put(
    //             ApiRoutes.POST_JOB + `/${jobID}`, job
    //         );

    //         console.log('desiredInfo', desiredInfo)
    //         return desiredInfo?.data;
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    // const deleteJobPost = async (jobID: number) => {
    //     try {
    //         setLoading(true);
    //         const desiredInfo = await axiosInstance.delete(
    //             ApiRoutes.POST_JOB + `/${jobID}`
    //         );
    //         return desiredInfo?.data;
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };



    return {
        loading,
        getJobFunctions,
        jobFucntions,
        createJobFunctions,
        getJobFunctionsWithoutLogin,
        singleJobFunction
    };
};

export default ManageJobFunctions;
