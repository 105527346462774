type KEY = "ACCESS_TOKEN" | "REFRESH_TOKEN" | "USER" | "TOKEN_TYPE" | "VENDOR_ID" | "PROJECT" | "EMAIL" | "COMPANY_INFO" | "COMPANY_ADMIN_INFO" | 'JOBSEEKER_INFO' | 'JOBSEEKER_INFO_ONE';

export class LocalStorage {
    static setItem(key: KEY, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    static getItem(key: KEY) {
        const data = localStorage.getItem(key);
        if (data && data !== "undefined") {
            return JSON.parse(data);
        }
        return null
    }

    static removeItem(key: KEY) {
        localStorage.removeItem(key);
    }

    static clear() {
        localStorage.clear();
    }
}