import React, { useEffect, useState } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import ManageCompanyTypes from 'services/MetaList/company.types'



export interface Group {
  id: number;
  name: string;
}

function CompanyTypeComponent(props: any) {

    const { getCompanyTypes, companyTypes } = ManageCompanyTypes()
    const { compTypeInfo, setCompTypeInfo, setFieldValue, singleJob, singleUser }    =   props;

    // console.log('singleUSER', singleUser)

  

  // const [Groups, setGroups] = useState<Group[]>([])

  const [getGrp, setGrp] = useState<string | undefined>();
  const [GroupData, setGroupData] = useState<Group[] | undefined>();

  const [selectedJobFuns, setSelectedJobFuns] = useState<Group[]>([]);

  const [GroupRes, setGroupRes] = useState(true);
  const [transformedOptions, setTransformedOptions] = useState<Group[] | undefined>();
  // let transformedOptions: Group[] | undefined;
  let getObjects: Group[] | undefined;

  
  
  useEffect(() => {    
    getCompanyTypes();
    // if(singleUser) {
    //     setSelectedJobFuns(singleUser?.company_data?.company_type)
    // }

  }, []);



//   const InsertGroup = async () => {
//     if (getGrp) {

//       console.log('getGrp', getGrp)
//       const getGroup: any = await createJobFunctions({name:getGrp})

//       console.log('getGroup', getGroup)

//         const newItem = { id: getGroup?.data?.id, name: getGroup?.data?.name };    

//       setSelectedJobFuns([...selectedJobFuns, newItem])

//       let newList: any = singleJob?.job_function.map((obj: any) => obj.id);
      
//       // setFieldValue('job_function_ids', newList)
//       setFieldValue('job_function_ids', [...newList, newItem?.id]);
//       setGrp(undefined);

//     };
//   };

  const handleSearch = async (e: string) => {
    let Group = await companyTypes?.find(function (element:any) {
      return element.name === e;
    });
    if (Group) {
      setGrp(undefined);
    } else {
      setGrp(e);
    }
  };

  

  return (
    <div>
      {/* <label>Company Type*:</label> */}

      {/* <div className="row col-md-6"> */}
        <div>
          <Multiselect
            style={{ borderRadius: "0px" }}
            isObject={true}
            placeholder='Company Type'
            onRemove={async (removed) => {
            //   console.log('removed', removed)
              let grpRmv: any = removed.map((obj: any) => obj.id);

              console.log('removed groups', grpRmv)

              setSelectedJobFuns(removed)
            //   setJobInfo({...jobInfo, job_function_ids: grpRmv})
              setFieldValue('companyType', grpRmv)
              setGrp(undefined);
             
            }}
            onSearch={handleSearch}
            onSelect={async (add) => {
                // console.log('add grps', add)
              let grpAdd: any = await add.map((obj: any) => obj.id);

              console.log('grpAdd', grpAdd)
              
              setSelectedJobFuns(add);

            //   setJobInfo({...jobInfo, job_function_ids: grpAdd})
            setFieldValue('companyType', grpAdd)
            setGrp(undefined);
             

            }}
            options={companyTypes}
            displayValue="name"
            showCheckbox
            selectedValues={ singleUser ? singleUser?.company_data?.company_type : null}
          // selectedValues={selectedGroups}
          />
        </div>
        {/* <div className="col-md-2 px-0">
          {getGrp ? (
            <button type='button' className="btn btn-dark btn-md" onClick={InsertGroup}>
              Add
            </button>
          ) : null}
        </div> */}
      {/* </div> */}
    </div>
  );
}

export default CompanyTypeComponent;