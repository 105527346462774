import React, { useEffect, useState } from "react";
import ManageJobsService from 'services/ManageJobsService/manage.jobs.service';
import ManageJobFunctions from 'services/MetaList/job.functions.service'
import ManageCompanyTypes from 'services/MetaList/company.types'
import { Select } from 'antd';

import { useNavigate } from "react-router-dom";
import ManageLocations from 'services/MetaList/locations.service';
import './home.css'

const HomePage = () => {

  const navigate = useNavigate();

  const { getJobPosts, jobs, getJobPostswithoutLogin } = ManageJobsService()
  const { companyTypes, getCompanyTypes, getCompanyList, companyList } = ManageCompanyTypes()
  const { getJobFunctions, jobFucntions, getJobFunctionsWithoutLogin } = ManageJobFunctions()

  const [searchJobs, setSearchJobs] = useState({role: '', location: '', jobType: ''})

  const { locations, getLocations } = ManageLocations();
  const [formatLocations, setFormatLocations] = useState<any>([])
  const [jobLocation, setJobLocation] = useState();

  const [jobFilter, setJobFilter] = useState<boolean>(true);

  const [finalFilteredList, setFinalFilteredList] = React.useState([]);

  let companyListRender: any;



  useEffect(() => {

    // const initIsotopeLayout = () => {
    //   document.querySelectorAll('.isotope-layout').forEach(function(isotopeItem) {
    //     let layout = isotopeItem.getAttribute('data-layout') ?? 'masonry';
    //     let filter = isotopeItem.getAttribute('data-default-filter') ?? '*';
    //     let sort = isotopeItem.getAttribute('data-sort') ?? 'original-order';

    //     let initIsotope = new Isotope(isotopeItem.querySelector('.isotope-container'), {
    //       itemSelector: '.isotope-item',
    //       layoutMode: layout,
    //       filter: filter,
    //       sortBy: sort
    //     });

    //     isotopeItem.querySelectorAll('.isotope-filters li').forEach(function(filters) {
    //       filters.addEventListener('click', function() {
    //         isotopeItem?.querySelector('.isotope-filters .filter-active').classList.remove('filter-active');
    //         this.classList.add('filter-active');
    //         initIsotope.arrange({
    //           filter: this.getAttribute('data-filter')
    //         });
    //         if (typeof aosInit === 'function') {
    //           aosInit();
    //         }
    //       }, false);
    //     });

    //   });
    // }

    // initIsotopeLayout()

    // getJobPosts()    
    // getJobFunctions()

    getJobPostswithoutLogin()
    getJobFunctionsWithoutLogin()
    getCompanyTypes()
    getCompanyList()
    getLocations();

  }, [])


  // useEffect(() => {
  //   const formattedLocations = locations?.map((loc: any) => ({
  //     label: loc.name,
  //     value: loc.id
  //   }));

  //   const additionalLocation = {
  //     label: 'All Locations', // Replace with your desired label
  //     value: 'all' // Replace with your desired value
  //   };

  //   if (formattedLocations) {
  //     // setFormatLocations(formattedLocations);
  //     setFormatLocations([additionalLocation, ...formattedLocations]);

  //   }
  // }, [locations]);

  // console.log(companyList, '...companyList')
  // console.log(companyTypes, '...companyTypes')
  // window.addEventListener('load', initIsotopeLayout);

  const jobListing = jobs && jobs.slice(0, 3).map((job: any, index: any) => {
    return (
      <div className="Newjoblisting" key={job?.id}>
        <div className="Newjoblisting-items">
          <img
            src="assets/img/Companylogos/Companylogos1.png"
            alt="Salesforce Logo"
          /> <h3>{job?.title}</h3>
        </div>
        <div className="Newjoblisting-items">
          <h6>{job?.location?.name}</h6>
          <p>{job?.job_type + ', ' + job?.mode}</p>
        </div>
        <div className="Newjoblisting-items">
          <button type="button" className="btn btn-apply" onClick={() => navigate('/job-details', { state: { jobPostId: job?.id } })}>                  View JD

          </button>
          {/* <input type="submit" className="btn btn-apply" value="Apply" /> */}
        </div>
      </div>
    )
  })

  // console.log(jobFucntions, '......jobFucntions')

  const companyTypesRender = companyTypes?.slice(0, 10).map((companyType: any, index: any) => {
    return (
      <li onClick={() => filterSelection(companyType?.id)}>{companyType?.name}</li>
    )
  })

  // const filterSelection = (id: any) => {

  //   console.log(id, '....id')

  //   companyListRender = companyList?.map((company: any, index: any) => {

  //     if (company?.company_type?.includes(id)) {
  //       return (
  //         <a href="javascript:void(0)">
  //           <div className="domaincompanies-item isotope-item ITServices">
  //             <div className="boxes">
  //               <img
  //                 src="assets/img/Companylogos/Companylogos5.png"
  //                 alt="Salesforce Logo"
  //               />
  //               <h6>{company?.company_name}</h6>
  //               <span>
  //                 {company?.company_type.map((item: any) => {
  //                   return <span>{item?.name}</span>
  //                 })}
  //               </span>
  //             </div>
  //           </div>
  //         </a>
  //       )
  //     }})
  // }


  const filterSelection = async (id: any) => {

    setJobFilter(false)

    try {
      const filteredList = await Promise.all(
        companyList?.map(async (company: any, index: any) => {
          // Use the 'some' function to check if any company_type has the desired id
          const hasTypeId = await company?.company_type?.some(
            (type: any) => type.id === id
          );
  
          // If the company has the desired type, return the company object
          if (hasTypeId) {
            return company;
          }
  
          // If not, return null
          return null;
        })
      );
  
      // Remove null values from the filtered list
      const info:any = filteredList.filter((item) => item !== null);
      setFinalFilteredList(info)
  
      console.log(id, '....id');
      console.log(finalFilteredList, '....filteredList');
      // companyListRender = finalFilteredList
    } catch (error) {
      console.error('Error filtering list:', error);
    }
  };
  
  
  

  companyListRender = !jobFilter ? 
  finalFilteredList?.slice(0, 8).map((company: any, index: any) => {

    // console.log(company, '...companyListRender')

    return (

      <div className="col-lg-3 col-md-3 col-6">
      <a href="javascript:void(0)" onClick={() => navigate('/company', { state: { companyId: company?.id } })}>
        <div className="domaincompanies-item isotope-item ITServices">
          <div className="boxes">
            <img
              src="assets/img/Companylogos/Companylogos5.png"
              alt="Salesforce Logo"
            />
            <h6>{company?.company_name}</h6>
              {company?.company_type && company?.company_type.map((item: any) => {
                return <span>{item?.name}</span>
              })}
          </div>
        </div>
      </a>
      </div>
    )
  })
  
  : companyList?.slice(0, 8).map((company: any, index: any) => {

    // console.log(company, '...companyListRender')
    return (
      <div className="col-lg-3 col-md-3  col-6">
      <a href="javascript:void(0)" onClick={() => navigate('/company', { state: { companyId: company?.id } })}>
        <div className="domaincompanies-item isotope-item ITServices">
          <div className="boxes">
            <img
              src="assets/img/Companylogos/Companylogos5.png"
              alt="Salesforce Logo"
            />
            <h6>{company?.company_name}</h6>
            {/* <span> */}
              {company?.company_type.map((item: any) => {
                return <span>{item?.name}</span>
              })}
            {/* </span> */}
          </div>
        </div>
      </a>
      </div>
    )
  })





  const jobCategoryListing = jobFucntions?.slice(0, 6).map((jobCat: any, index: any) => {


    const filteredResults = jobs.filter((job: any) =>
      job.job_function.some((jobFunc: any) => jobFunc.id === jobCat?.id)
    );

    // console.log(companyListRender, '...companyListRender')




    // Get the length of the filtered results
    // const resultsLength = filteredResults.length;

    return (
      <div className="col-lg-4 col-6" data-aos="fade-up" data-aos-delay="200" key={jobCat?.id}>
        <div className="jobcategory-item">
          <div className="icon flex-shrink-0">
            <img
              src="assets/img/category/Salesforce Developer.png"
              className="img-fluid"
              alt=""
            />
          </div>
          <div>
            <h4 className="title">
              {jobCat?.name}
            </h4>
            <p className="description">{filteredResults?.length > 1 ? `${filteredResults?.length} jobs avaialble` : `${filteredResults?.length} job avaialble`}</p>
            <span><a href="javascript:void(0)" onClick={() => navigate('/jobs', { state: { jobCatId: jobCat?.id } })} style={{ color: '#14A077' }} >Explore Jobs</a></span>
          </div>
        </div>
      </div>
    )
  })


  

  return (
    <main id="main">
      <section id="hero" className="hero">
        <img src="assets/img/hero-bg.jpg" alt="" data-aos="fade-in" />

        <div className="container">
          <div className="row">
            <div className="col-lg-10">
              <h2 data-aos="fade-up" data-aos-delay="100">
                Welcome to Our Website
              </h2>
              <p data-aos="fade-up" data-aos-delay="200">
                Jobs & Job search. Find jobs in global. Executive jobs & work.
              </p>
            </div>
            <div className="col-lg-8">
              <form
                action="#"
                className="sign-up-form"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div className=" homepage-filter">
                  
                  

                  <div className="filter-role">
                    <span>Role</span>
                    <div className="custom-select">
                      <select
                        className="form-control"
                        id="jobType"
                        name="jobType"
                        style={{width: '250px'}}
                        onChange={(e) => setSearchJobs((prevSearchJobs) => ({
                          ...prevSearchJobs,
                          role: e.target.value,
                        }))}
                      >
                        <option value="">Select</option>
                        { jobFucntions.map((item: any) => {
                          return <option value={item?.id}>{item?.name}</option>
                        })}                        
                      </select>
                      <div className="select-icon">
                        <i className="bi bi-chevron-down"></i>
                      </div>
                    </div>
                  </div>

                  <div className="filter-Location">
                    <span>Location</span>
                    <div className="custom-select">                  

                      <select
                        className="form-control"
                        id="jobType"
                        name="jobType"
                        onChange={(e) => setSearchJobs((prevSearchJobs) => ({
                          ...prevSearchJobs,
                          location: e.target.value,
                        }))}
                      >
                        <option value=''>Select</option>
                        {
                          locations.map((item: any) => {
                            return <option value={item?.id}>{item?.name}</option>
                          })
                        }
                        
                      </select>
                      <div className="select-icon">
                        <i className="bi bi-chevron-down"></i>
                      </div>
                    </div>
                  </div>

                  <div className="filter-JobType">
                    <span>Job Type</span>
                    <div className="custom-select">
                      <select
                        className="form-control"
                        id="jobType"
                        name="jobType"
                        style={{marginRight: '15px'}}
                        onChange={(e) => setSearchJobs((prevSearchJobs) => ({
                          ...prevSearchJobs,
                          jobType: e.target.value,
                        }))}
                      >
                        <option value="">Select </option>
                        <option value="fulltime">Full-Time</option>
                        <option value="parttime">Part-Time</option>
                        <option value="contract">Contract</option>
                        <option value="internship">Internship</option>
                        <option value="temporary">Temporary</option>
                      </select>


                      <div className="select-icon">
                        <i className="bi bi-chevron-down"></i>
                      </div>
                    </div>
                  </div>

                  <input
                    // type="submit"
                    type="button"
                    className="btn btn-primary"
                    value="Search"
                    onClick={() => navigate('/jobs', { state: {jobSearch: searchJobs} })}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <section id="jobcategory" className="jobcategory">
        <div className="container">
          <div className="row gy-4">
            {jobCategoryListing}
          </div>
        </div>
      </section>



      <section id="" className="">
        <div className="container section-title" data-aos="fade-up">
          <h2 className="text-center">New job listing</h2>

          <div className="job-posts-container">
            {jobListing}
          </div>
        </div>
      </section>

      <section id="" className="">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="" data-aos="fade-up">
                <h2>What can we do ?</h2>
                <p>
                  Streamline your hiring process with strategic channels to
                  reach qualified candidates
                </p>
                <img src="assets/img/homepage/map.png" className="img-fluid" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="icon-text-section">
                <div className="iconicon">
                  <img src="assets/img/category/1.png" alt="Icon" />
                </div>
                <div className="icontext">
                  <h4>Access to Millions of Candidates</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div>
              </div>
              <div className="icon-text-section">
                <div className="iconicon">
                  <img src="assets/img/category/2.png" alt="Icon" />
                </div>
                <div className="icontext">
                  <h4>Automate Candidate Engagement</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div>
              </div>
              <div className="icon-text-section">
                <div className="iconicon">
                  <img src="assets/img/category/3.png" alt="Icon" />
                </div>
                <div className="icontext">
                  <h4>
                    Boost Performance with an Outsourced Talent - Acquisition
                    Team
                  </h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div>
              </div>
              <div className="icon-text-section">
                <div className="iconicon">
                  <img src="assets/img/category/4.png" alt="Icon" />
                </div>
                <div className="icontext">
                  <h4>Pipeline Management on Your Terms</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="domaincompanies" className="domaincompanies">
        <div className="container section-title" data-aos="fade-up">
          <h2 className="text-center">Sponsored companies</h2>
          <div
            className="isotope-layout"
            data-default-filter="*"
            data-layout="masonry"
            data-sort="original-order"
          >
            <ul
              className="domaincompanies-filters isotope-filters"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <li data-filter="*" className="filter-active" onClick={() => setJobFilter(true)}>
                All
              </li>
              {companyTypesRender}
            </ul>

            <div className="row " data-aos="fade-up" data-aos-delay="200">
              <div className="isotope-container">
                {/* <div className="col-md-3"> */}
                  {companyListRender}
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section id="recent-posts" className="recent-posts">
        <div className="container section-title text-center" data-aos="fade-up">
          <h2>Latest News</h2>
          <p>Jobtex’s Blog provides valuable content to the job seeker</p>
        </div>

        <div className="container">
          <div className="row gy-4">
            <div
              className="col-xl-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <article>
                <div className="post-img">
                  <img
                    src="assets/img/blog/blog-1.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="post-category">
                  <span>Entertainment</span>
                </div>
                <br />
                <h2 className="title">
                  <a href="blog-details.html">
                    Possimus soluta ut id suscipit ea ut in quo quia et soluta
                  </a>
                </h2>
                <div className="d-flex align-items-center">
                  <img
                    src="assets/img/blog/blog-author-1.jpg"
                    alt=""
                    className="img-fluid post-author-img flex-shrink-0"
                  />
                  <div className="post-meta">
                    <p className="post-author">Mark Dower</p>
                    <p className="post-date">
                      <time dateTime="2022-01-01">Jun 22, 2022</time>
                    </p>
                  </div>
                </div>
              </article>
            </div>

            <div
              className="col-xl-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <article>
                <div className="post-img">
                  <img
                    src="assets/img/blog/blog-2.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="post-category">
                  <span>Entertainment</span>
                </div>
                <br />
                <h2 className="title">
                  <a href="blog-details.html">
                    Possimus soluta ut id suscipit ea ut in quo quia et soluta
                  </a>
                </h2>
                <div className="d-flex align-items-center">
                  <img
                    src="assets/img/blog/blog-author-2.jpg"
                    alt=""
                    className="img-fluid post-author-img flex-shrink-0"
                  />
                  <div className="post-meta">
                    <p className="post-author">Mark Dower</p>
                    <p className="post-date">
                      <time dateTime="2022-01-01">Jun 22, 2022</time>
                    </p>
                  </div>
                </div>
              </article>
            </div>

            <div
              className="col-xl-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <article>
                <div className="post-img">
                  <img
                    src="assets/img/blog/blog-3.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="post-category">
                  <span>Entertainment</span>
                </div>
                <br />
                <h2 className="title">
                  <a href="blog-details.html">
                    Possimus soluta ut id suscipit ea ut in quo quia et soluta
                  </a>
                </h2>
                <div className="d-flex align-items-center">
                  <img
                    src="assets/img/blog/blog-author-3.jpg"
                    alt=""
                    className="img-fluid post-author-img flex-shrink-0"
                  />
                  <div className="post-meta">
                    <p className="post-author">Mark Dower</p>
                    <p className="post-date">
                      <time dateTime="2022-01-01">Jun 22, 2022</time>
                    </p>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section> */}
    </main>
  );
};

export default HomePage;