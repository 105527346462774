import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Home from './views/Home';
import ContextData from 'views/useContext';

// import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400&display=swap');

function App() {
  const [ data, setData ] = useState(null)
  return (
    <div className="App">
      <Router>
      <ContextData.Provider value={{ data, setData }}>
        <Home />
      </ContextData.Provider>
      </Router>      
    </div>
  );
}

export default App;
