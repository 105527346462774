import React, { useState, useContext } from "react";
import axiosInstance from "interceptor/axiosInstance";
import { Token } from "models/token.model";
import { JobSeekerUser, User } from "models/user.model";
import { useNavigate } from "react-router-dom";
import { ApiRoutes } from "routes/routeConstants/apiRoutes";
import { deserialize, serialize } from "serializr";
import { LocalStorage } from "shared/utils/localStorageHelpers";
import ContextData from "views/useContext";
// import ContextData from 'views/useContext';

const ReportsService = () => {

    const navigate = useNavigate();
    const { setData } = useContext<any>(ContextData);
    const [error, setError] = useState<Error | unknown>();
    const [loading, setLoading] = useState(false);
    const [jobSeekersList, setJobSeekersList] = useState<any>([])
    const [companiesList, setCompaniesList] = useState<any>([])
    const [jobsList, setJobsList] = useState<any>()
    // const [singleJobFunction, setSingleJobFunction] = useState<any>()


    const getTotalJobSeekersList = async () => {
        try {
            setLoading(true);
            const desiredData = await axiosInstance.get(
                ApiRoutes.TOTAL_JOBSEEKET_LIST+`?type=univers`
            );
            // const desiredData = deserialize(User, desiredInfo.data?.results as User[]);
            // setCompanyEmployees(desiredData);

            // console.log('companyTypeData', desiredData?.data)
            setJobSeekersList(desiredData?.data)
            return desiredData?.data;
        } catch (error) {
            console.log(error);
        }
    };

    const getTotalCompaniesList = async () => {
        try {
            setLoading(true);
            const desiredData = await axiosInstance.get(
                ApiRoutes.TOTAL_COMP_LIST+`?type=univers`
            );
            // const desiredData = deserialize(User, desiredInfo.data?.results as User[]);
            // setCompanyEmployees(desiredData);

            // console.log('companyTypeData', desiredData?.data?.results)
            setCompaniesList(desiredData?.data)
            return desiredData?.data;
        } catch (error) {
            console.log(error);
        }
    };


    

    const getTotalJobsList = async () => {
        try {
            setLoading(true);
            const desiredData = await axiosInstance.get(
                ApiRoutes.TOTAL_JOB_POST_LIST+`?type=univers`
            );
            // const desiredData = deserialize(User, desiredInfo.data?.results as User[]);
            // setCompanyEmployees(desiredData);

            // console.log('companyTypeData', desiredData?.data?.results)
            setJobsList(desiredData?.data)
            return desiredData?.data;
        } catch (error) {
            console.log(error);
        }
    };

    const getTotalCompaniesListByCountry = async (country: string) => {

        const parts = country.split('_');

        if(parts.length > 0) {
            country = parts[1]
        }
        
        try {
            setLoading(true);
            const desiredData = await axiosInstance.get(
                ApiRoutes.TOTAL_COMP_LIST+`?type=country`
            );
            // const desiredData = deserialize(User, desiredInfo.data?.results as User[]);
            // setCompanyEmployees(desiredData);

            // console.log('companyTypeData', desiredData?.data?.results)
            const desiredList = desiredData?.data?.companies_by_country.find((item: any) => item.company_country === country)
            setCompaniesList(desiredList)
            return desiredList;
        } catch (error) {
            console.log(error);
        }
    };

    const getTotalJobsListByCountry = async (country: string) => {

        // console.log(country, '....country')
        // let countryInfo:any

        const parts = country.split('_');

        if(parts.length > 0) {
            country = parts[0]
        }

        try {
            setLoading(true);
            const desiredData = await axiosInstance.get(
                ApiRoutes.TOTAL_JOB_POST_LIST+`?type=country`
            );
            const desiredList = desiredData?.data?.job_posts_by_location.find((item: any) => item.country__name === country)
            // console.log('desiredList', desiredList)
            setJobsList(desiredList)
            return desiredList;
        } catch (error) {
            console.log(error);
        }
    };


    const getTotalJobSeekersListByCountry = async (country: string) => {

        // let countryInfo:any

        const parts = country.split('_');

        if(parts.length > 0) {
            country = parts[1]
        }


        console.log(`'${country}'`, '....country')

        try {
            setLoading(true);
            const desiredData = await axiosInstance.get(
                ApiRoutes.TOTAL_JOBSEEKET_LIST+`?type=country`
            );
            const desiredList = desiredData?.data?.job_seekers_by_country.find((item: any) => item.country === country)
            console.log('desiredList', desiredList)
            setJobSeekersList(desiredList)
            return desiredList;
        } catch (error) {
            console.log(error);
        }
    };

    

    

    // const getSingleJobPost = async (jobID: number) => {
    //     try {
    //         setLoading(true);
    //         const desiredInfo = await axiosInstance.get(
    //             ApiRoutes.POST_JOB + `/${jobID}`
    //         );

    //         // const desiredData = deserialize(User, desiredInfo.data as User);
    //         // setSingleCompanyEmployees(desiredData);

    //         console.log('jobList', desiredInfo?.data)


    //         // setJobs(desiredInfo?.data)
    //         setSingleJob(desiredInfo?.data)
    //         return desiredInfo;
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    // const updateJobPost = async (jobID: number, job: any) => {

    //     try {
    //         delete job?.id

    //         console.log('jobID', jobID)
    //         console.log('job', job)

    //         setLoading(true);
    //         const desiredInfo = await axiosInstance.put(
    //             ApiRoutes.POST_JOB + `/${jobID}`, job
    //         );

    //         console.log('desiredInfo', desiredInfo)
    //         return desiredInfo?.data;
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    // const deleteJobPost = async (jobID: number) => {
    //     try {
    //         setLoading(true);
    //         const desiredInfo = await axiosInstance.delete(
    //             ApiRoutes.POST_JOB + `/${jobID}`
    //         );
    //         return desiredInfo?.data;
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };



    return {
        loading,
        getTotalCompaniesList,
        getTotalJobSeekersList,
        getTotalJobsList,
        getTotalJobsListByCountry,
        getTotalJobSeekersListByCountry,
        getTotalCompaniesListByCountry,
        companiesList,
        jobsList,
        jobSeekersList
    };
};

export default ReportsService;
