import { Select, Switch, Table } from 'antd';
import { Form, Formik } from 'formik';
import { User } from 'models/user.model';
import React, { useContext, useEffect, useRef } from 'react'
import { useState } from 'react';
import UserService from 'services/AuthService/auth.service';
import InputField from 'shared/components/InputField';
import { LocalStorage } from 'shared/utils/localStorageHelpers';
import ContextData from 'views/useContext';
import ManageJobs from '../ManageJobs';
import JobApplications from '../JobApplications';
import CompanyDashboadrPanel from '../shared';
import "./CompanyDashboard.css"
// import CompantDashboadrPanel from '../shared';

const CompanyDashboard = () => {
  const data = LocalStorage.getItem("USER")
  const [employee, setEmployee] = useState(false)
  const [gender, setGender] = useState("");
  const [successMessage, setSuccessMessage] = useState('')
  const [isCreation, setIsCreation] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)
  const [isJobManage, setIsJobManage] = useState(false)
  const [isJobApplications, setISJobApplications] = useState(false)

  //Using Inline Function and the The Logical Not (!) to toggle state
  const [toggle, setToggle] = useState(false)

  //dash menu toggle
  const toggleContainer = useRef<HTMLDivElement>(null);



  const { registerCompanyEmployee, getCompanyEmployee, companyEmployees, updateCompanyEmployee, deleteCompanyEmployee, getSingleCompanyEmployee, singleCompanyEmployees } = UserService()

  const onSelectChange = (value: string) => {
    console.log(`selected ${value}`);
    setGender(value)
  };

  const onSearch = (value: string) => {
    console.log('search:', value);
  };

  useEffect(() => {
    getCompanyEmployee()

    const handleClickOutside = (event: MouseEvent) => {
      if (toggleContainer.current && !toggleContainer.current.contains(event.target as Node)) {
        setToggle(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up event listeners
      document.removeEventListener("mousedown", handleClickOutside);
    };


  }, [toggle, isUpdated, !isCreation])




  
  // Filter `option.label` match the user type `input`
  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Status',
      dataIndex: 'active',
      key: 'active',
      render: (active: any, data: User) => <Switch defaultChecked={active} onChange={(value: boolean) => { updateCompanyEmployee(data?.id!, { active: value }); setIsUpdated(true) }} />
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: 'actions',
      render: (action: any, data: User) => <><span style={{ marginRight: "10px" }} onClick={async () => {
        const desiredData = await getSingleCompanyEmployee(data?.id!)
        setIsCreation(true)
        setGender(desiredData?.data?.gender)

      }}>Edit</span>
        <span onClick={() => {

          const isConfirmed = window.confirm('Are you sure you want to delete?');

          // If the user confirms, proceed with deletion
          if (isConfirmed) {
            deleteCompanyEmployee(data?.id!); setIsUpdated(true); getCompanyEmployee()
          }

        }}>Delete</span></>
    }
  ];

  return (
    <>
      <div className="mt-50"></div>
      <main id="main">
        <div className="page-title">
          <nav className="breadcrumbs">
            <div className="container">
              <ol>
                <li><a href="index.php">Home</a></li>
                <li className="current">Company Dashboard</li>
              </ol>
            </div>
          </nav>
        </div>
        <section className="f0f5f3">
          <div className="container">





            <div className="row">
              <div className="col-md-3">
                
               <div className='mobilesidenavmenu'  ref={toggleContainer}>
                        <button className='sidenavmenu btn sidenavmenubtn  mb-5' onClick={() => setToggle(!toggle)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-bar-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M6 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L12.293 7.5H6.5A.5.5 0 0 0 6 8m-2.5 7a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5"/>
                          </svg>
                          </button>
                            {
                              toggle &&

                                        <div className="cmp-side-nav ">
                                        <CompanyDashboadrPanel />
                                        </div>
                          }   
                </div>
                          
            
                <div className="cmp-side-nav  desktopsidenavmenu">
                 <CompanyDashboadrPanel />
                </div>


              </div>
              <div className="col-md-9 ">

                {/* <div className="result-bar">
                  <div className="row">
                    <div className="col-md-8">
                      Showing 1 – 10 of 11 results
                    </div>
                    <div className="col-md-4 float-right ">
                      <div className="pagination ">
                        <ul>
                          <li><a href="#">1</a></li>
                          <li className="active"><a href="#">2</a></li>
                          <li><a href="#">3</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  </div> */}


                <div className="container">




                  {/* {!employee && <div className="row jobs-view">
                    <div className="col-md-8 d-flex">

                      <input type="text" className="form-control" placeholder="User Name" required />
                      <select className="form-control" id="jobType" name="jobType">
                        <option value="">Roles</option>
                        <option value="salesforceDeveloper">Salesforce Developer</option>
                        <option value="salesforceAdministrator">Salesforce Administrator</option>
                        <option value="salesforceConsultant">Salesforce Consultant</option>
                        <option value="salesforceArchitect">Salesforce Architect</option>
                        <option value="salesforceManager">Salesforce Manager</option>
                      </select>


                    </div>
                    <div className="col-md-3 d-flex">

                    </div>
                  </div>} */}

                  <div className="row">


                    {/* <!------Job Item Start --- */}
                   

                    {/* {isJobApplications && <JobApplications />} */}

                    {/* <div className="result-bar">
                      <div className="row">
                        <div className="col-md-8">
                          Showing 1 – 10 of 11 results
                        </div>
                        <div className="col-md-4 float-right ">
                          <div className="pagination ">
                            <ul>
                              <li><a href="#">1</a></li>
                              <li className="active"><a href="#">2</a></li>
                              <li><a href="#">3</a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    <div className="count-box">

                      <div className="">
                        <div className="count-box1">
                          <div>
                            <span className="count">89</span>
                            <p>New Resumes</p>
                          </div>

                          <div className="count-img">
                            <img src="assets/img/icons/Resume.png" alt="" />
                          </div>
                        </div>
                      </div>

                      <div className="">
                        <div className="count-box2">
                          <div>
                            <span className="count">24</span>
                            <p>Saved Resumes</p>
                          </div>

                          <div className="count-img">
                            <img src="assets/img/icons/savedresumes.png" alt="" />
                          </div>
                        </div>
                      </div>

                      <div className="">
                        <div className="count-box3">
                          <div>
                            <span className="count">4</span>
                            <p>Jobs Posted</p>
                          </div>

                          <div className="count-img">
                            <img src="assets/img/icons/JobPosted.png" alt="" />
                          </div>
                        </div>
                      </div>


                    </div>

                    <div className='row margintop2p'>
                      {/* <div className="col-md-6">
                        <div className="result-box">
                          <div className="d-flex">

                            <div className="user-img text-center">
                              <img src="assets/img/blog-author-3.jpg" alt="" className="img-fluid" />
                            </div>
                            <div className="jobseeker-info " style={{ marginTop: "20px" }}>
                              <h4 className="">Jai shankar kumara swamy </h4>
                            </div>

                          </div>


                          <div className=" d-flex job-flex" style={{}}>
                            <div className="jobseeker-info ">
                              <p className="green-text">Salesforce Administrator</p>
                              <div className="address">
                                <span> 5+ years Experience</span>
                              </div>
                              <span className="tags">Developer</span>
                              <span className="tags">Business Analyst</span><br />
                              <span className="tags">Architect</span>
                              <span className="tags">AppExchange</span>

                            </div>
                          </div>
                          <div className="pricing text-center">
                            <div className="salary">
                              <span className="">Salary</span>
                              <span className=""><h6>$30k/mo</h6></span>
                            </div>
                            <div className="location">
                              <span className="">Location</span>
                              <span className=""><h6>New York, US</h6></span>

                            </div>
                          </div>
                          <div className="pricing">
                            <span className="float-right applyViewMore"><a href="">View Profile</a></span>
                            <span className="float-right apply"><a href="">Download CV</a></span>
                          </div>
                        </div>
                      </div> */}

                     
                    </div>








                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

export default CompanyDashboard