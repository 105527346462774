import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { LocalStorage } from 'shared/utils/localStorageHelpers';
import ManageJobsService from 'services/ManageJobsService/manage.jobs.service';
import AppliedJobs from '../AppliedJobs';
// import JobDetails from '../JobDetails';
import "./Jobseekerdasshboard.css"
import ContextData from 'views/useContext';
import JobseekerProfile from '../jobseekerProfile';
import JobseekerProfileUpdate from '../jobseekerAccountSettings';
import UserService from 'services/AuthService/auth.service';
// import { log } from 'console';
import JobSekerChangePassword from '../jobSeekerChangePassword';

const JobSeekerDashboard = () => {

  const navigate = useNavigate()

  const toggleContainer = useRef<HTMLDivElement>(null);

  const { postJob, getJobPosts, applyJobPost, appliedJobsList, deleteJobPost, jobs, singleJob } = ManageJobsService()
  const { getJobSeekerUser, singleUser, updateJobSeekerUser, logOut } = UserService()

  const data = LocalStorage?.getItem("USER")
    //Using Inline Function and the The Logical Not (!) to toggle state
    const [toggle, setToggle] = useState(false)


  // console.log(data?.user, 'userData')

  const [dashboardSec, setDashBoardSec] = useState(true)
  const [appliedJobsSec, setApplliedJobsSec] = useState(false)
  const [profileSec, setProfileSec] = useState(false)
  const [profileSettingsSec, setProfileSettingsSec] = useState(false)
  const [changePwd, setChangePwd] = useState(false)
  // const [statusMsg, setStatusMsg]             =   useState('')
  // const [jobDetailsSec, setJobDetailsSec]     =   useState(false)

  useEffect(() => {
    getJobPosts()
    getJobSeekerUser(data?.user?.id)

  // side nav off screen none
    const handleClickOutside = (event: MouseEvent) => {
      if (toggleContainer.current && !toggleContainer.current.contains(event.target as Node)) {
        setToggle(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up event listeners
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // appliedJobsList(data?.user)
  }, [toggle])




  // console.log('jobList', jobs)


  const jobList = () => {


    return jobs?.map((job: any, index: any) => {

      // console.log('jobInfo', job)

      return (
        <div className="col-md-6">
        <div className="result-box">

          <div className="d-flex job-flex" style={{}}>
            <div className="company-logo">
              <img src="assets/img/Companylogos/Companylogos1.png" alt="Salesforce Logo" className="img-fluid" />
            </div>
            <div className="company-info ">
              <ul style={{ paddingLeft: '0px' }}>{

                job?.job_function.map((jobCat: any, index: any) => {

                  return (
                    <li className="green-text" style={{ listStyle: 'none', fontSize: '10px', display: 'block' }}>{jobCat?.name}</li>
                  )

                })

              }
              </ul>

              {/* <p className="green-text">IT & Networking</p> */}
              <h4>{job?.title}</h4>
              <div className="address">
                {/* <span> {job?.skills?.map((item:any) => {
                  <p>{item}</p>
                })}</span> */}
                <span> {job?.application_deadline}</span>
              </div>
              <span className="tags">{job?.job_type}</span>
              <span className="tags" style={{ marginLeft: '10px' }}>{job?.location?.name}</span>
            </div>
          </div>
          <div className="pricing">
            <span className="float-left">{job?.currencyType?.name} &nbsp;{job?.salary} / {job?.salaryType}</span>
            {/* <span className="float-left"><b>{job?.location?.name}</b></span> */}
            <span className="float-right applyViewMore"><a href="javascript:void(0)" onClick={() => navigate('/job-details', { state: { jobPostId: job?.id } })}>View JD</a></span>
            {/* <span className="float-right apply"><a href="javascript:void(0)" onClick={() => applyJob(job?.id)}> Apply</a></span> */}
          </div>
        </div>
        </div>
      )
    })
  }

  const deluser = () => {

     // Display a prompt and capture the entered value
    //  const userInput = window.prompt('Are you sure you want to delete your account?');
    const userConfirmed = window.confirm('Are you sure you want to delete your account?');
    
     // Check if the user clicked "Cancel" or entered an empty value
     if (userConfirmed) {
      //  alert('You canceled or entered an empty value.');
     
       // Call your update function with the entered value
      //  updateJobSeekerUser(data?.user?.id, { active: false });
     

      // Assuming `updateJobSeekerUser` is an asynchronous function
      updateJobSeekerUser(data?.user?.id, { active: false })
      .then(() => {
        // Display an alert when the update is successful
        alert('User account deleted successfully!');
        // LocalStorage.clear();
        LocalStorage.removeItem("USER")
        navigate("/");
      })
      .catch((error) => {
        // Handle errors if the update fails
        console.error('Error updating user:', error);
        alert('Failed to update user. Please try again.');
      });

    } else {

    }


  }

  // console.log(singleUser, '....singleUser')



  return (
    <>
      <div className="mt-50"></div>
      <main id="main">

        <div className="page-title">
          <nav className="breadcrumbs">
            <div className="container">
              <ol>
                <li><a href="index.php">Home</a></li>
                <li className="current">Jobseeker Dashboard</li>
              </ol>
            </div>
          </nav>
        </div>



        <section className="f0f5f3">
          <div className="container">




            <div className="row">
              <div className="col-md-3">




                      <div className='mobilesidenavmenu' ref={toggleContainer}>
                        <button className='sidenavmenu btn sidenavmenubtn  mb-5' onClick={() => setToggle(!toggle)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-bar-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M6 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L12.293 7.5H6.5A.5.5 0 0 0 6 8m-2.5 7a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5"/>
                          </svg>
                          </button>
                            {
                              toggle &&
                              <div className="job-side-nav">
                              <div className="profile text-center">
                                <img
                                    src={`http://localhost:8000${singleUser?.display_picture_url}`}
             
                                  alt="Salesforce Logo"
                                  className="img-fluid" />
                                <h4>{data?.user?.name}</h4>
                              </div>
                              <div className="side-nav-item d-flex justify-content-center">
                                <ul className="">
                                  <li><a href="javascript:void(0)" style={{ cursor: 'pointer' }}>
                                    <img src="assets/img/icons/dashboard.png" alt="" className="img-fluid" />
                                    <p onClick={() => {
                                      setDashBoardSec(true)
                                      setApplliedJobsSec(false)
                                      setProfileSec(false)
                                      setProfileSettingsSec(false)
                                      setChangePwd(false)
                                    }}>Dashboard</p></a>
                                  </li>
                                  <li><a href="javascript:void(0)" style={{ cursor: 'pointer' }}>
                                    <img src="assets/img/icons/Profile.png" alt="" className="img-fluid" />
                                    <p onClick={() => {
                                      setDashBoardSec(false)
                                      setApplliedJobsSec(false)
                                      setProfileSec(true)
                                      setProfileSettingsSec(false)
                                      setChangePwd(false)
            
                                    }}>Profile</p></a>
                                  </li>
                                  <li><a href="javascript:void(0)" style={{ cursor: 'pointer' }}>
                                    <img src="assets/img/icons/edit.png" alt="" className="img-fluid" />
                                    <p onClick={() => {
                                      setDashBoardSec(false)
                                      setApplliedJobsSec(true)
                                      setProfileSec(false)
                                      setProfileSettingsSec(false)
                                      setChangePwd(false)
                                    }}>Applied Jobs</p></a>
                                  </li>
            
                                  <li><a href="javascript:void(0)" style={{ cursor: 'pointer' }}>
                                    <img src="assets/img/icons/AccountSettings.png" alt="" className="img-fluid" />
                                    <p onClick={() => {
                                      setDashBoardSec(false)
                                      setApplliedJobsSec(false)
                                      setProfileSec(false)
                                      setProfileSettingsSec(true)
                                      setChangePwd(false)
                                    }}
                                    >Account Settings</p></a>
                                  </li>
            
                                  <li>
                                    <a href="javascript:void(0)" style={{ cursor: 'pointer' }}>
                                    <img src="assets/img/icons/AccountSettings.png" alt="" className="img-fluid" />
                                    <p onClick={() => {
                                      setDashBoardSec(false)
                                      setApplliedJobsSec(false)
                                      setProfileSec(false)
                                      setProfileSettingsSec(false)
                                      setChangePwd(true)
                                    }}
                                    >Change Password</p>
                                    </a>
                                  </li>
            
                                  {/* <li>
                                    <a href="javascript:void(0)" style={{ cursor: 'pointer' }}>
                                    <img src="assets/img/icons/AccountSettings.png" alt="" className="img-fluid" />
                                    <p onClick={() => { navigate('/jobseekerUpdatePwd')
                                    }}
                                    >Change Password</p>
                                    </a>
                                  </li> */}
            
                                  <li><a href="javascript:void(0)" onClick={deluser}>
                                    <img src="assets/img/icons/DeleteAccount.png" alt="" className="img-fluid" />
                                    <p>Delete Account</p></a>
                                  </li>
                                  <li><a href="javascript:void(0)" onClick={logOut}>
                                    <img src="assets/img/icons/Logout.png" alt="" className="img-fluid" />
                                    <p>Logout</p></a>
                                  </li>
                                </ul>
            
                              </div>
                            </div>
                            }   
                           </div>
                          
            
                <div className="desktopsidenavmenu stickyposition">
                <div className="job-side-nav ">
                  <div className="profile text-center">
                    <img
                        src={`http://localhost:8000${singleUser?.display_picture_url}`}
 
                      alt="Salesforce Logo"
                      className="img-fluid" />
                    <h4>{data?.user?.name}</h4>
                  </div>
                  <div className="side-nav-item d-flex justify-content-center">
                    <ul className="">
                      <li><a href="javascript:void(0)" style={{ cursor: 'pointer' }}>
                        <img src="assets/img/icons/dashboard.png" alt="" className="img-fluid" />
                        <p onClick={() => {
                          setDashBoardSec(true)
                          setApplliedJobsSec(false)
                          setProfileSec(false)
                          setProfileSettingsSec(false)
                          setChangePwd(false)
                        }}>Dashboard</p></a>
                      </li>
                      <li><a href="javascript:void(0)" style={{ cursor: 'pointer' }}>
                        <img src="assets/img/icons/Profile.png" alt="" className="img-fluid" />
                        <p onClick={() => {
                          setDashBoardSec(false)
                          setApplliedJobsSec(false)
                          setProfileSec(true)
                          setProfileSettingsSec(false)
                          setChangePwd(false)

                        }}>Profile</p></a>
                      </li>
                      <li><a href="javascript:void(0)" style={{ cursor: 'pointer' }}>
                        <img src="assets/img/icons/edit.png" alt="" className="img-fluid" />
                        <p onClick={() => {
                          setDashBoardSec(false)
                          setApplliedJobsSec(true)
                          setProfileSec(false)
                          setProfileSettingsSec(false)
                          setChangePwd(false)
                        }}>Applied Jobs</p></a>
                      </li>

                      <li><a href="javascript:void(0)" style={{ cursor: 'pointer' }}>
                        <img src="assets/img/icons/AccountSettings.png" alt="" className="img-fluid" />
                        <p onClick={() => {
                          setDashBoardSec(false)
                          setApplliedJobsSec(false)
                          setProfileSec(false)
                          setProfileSettingsSec(true)
                          setChangePwd(false)
                        }}
                        >Account Settings</p></a>
                      </li>

                      <li>
                        <a href="javascript:void(0)" style={{ cursor: 'pointer' }}>
                        <img src="assets/img/icons/AccountSettings.png" alt="" className="img-fluid" />
                        <p onClick={() => {
                          setDashBoardSec(false)
                          setApplliedJobsSec(false)
                          setProfileSec(false)
                          setProfileSettingsSec(false)
                          setChangePwd(true)
                        }}
                        >Change Password</p>
                        </a>
                      </li>

                      {/* <li>
                        <a href="javascript:void(0)" style={{ cursor: 'pointer' }}>
                        <img src="assets/img/icons/AccountSettings.png" alt="" className="img-fluid" />
                        <p onClick={() => { navigate('/jobseekerUpdatePwd')
                        }}
                        >Change Password</p>
                        </a>
                      </li> */}

                      <li><a href="javascript:void(0)" onClick={deluser}>
                        <img src="assets/img/icons/DeleteAccount.png" alt="" className="img-fluid" />
                        <p>Delete Account</p></a>
                      </li>
                      <li><a href="javascript:void(0)" onClick={logOut}>
                        <img src="assets/img/icons/Logout.png" alt="" className="img-fluid" />
                        <p>Logout</p></a>
                      </li>
                    </ul>

                  </div>
                </div>
                </div>



              </div>


              {dashboardSec &&
                <div className="col-md-9">
                  <div className="container">
                    {/* <div className="result-bar">
                      <div className="row">
                        <div className="col-md-8">
                          Showing 1 – 10 of 11 results
                        </div>
                        <div className="col-md-4 float-right ">
                          <div className="pagination ">
                            <ul>
                              <li><a href="#">1</a></li>
                              <li className="active"><a href="#">2</a></li>
                              <li><a href="#">3</a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="dashboard-count">
                      <div className="">
                        <div className="count-box1">
                          <div>
                            <span className="count">89</span>
                            <p>Total Visitor</p>
                          </div>

                          <div className="count-img">
                            <img src="assets/img/icons/account.png" alt="" className="img-fluid" />
                          </div>
                        </div>
                      </div>

                      <div className="">
                        <div className="count-box2">
                          <div>
                            <span className="count">24</span>
                            <p>Saved Jobs</p>
                          </div>

                          <div className="count-img">
                            <img src="assets/img/icons/saved.png" alt="" className="img-fluid" />
                          </div>
                        </div>
                      </div>

                      <div className="">
                        <div className="count-box3">
                          <div>
                            <span className="count">4</span>
                            <p>Shortlisted</p>
                          </div>

                          <div className="count-img">
                            <img src="assets/img/icons/viewed.png" alt="" className="img-fluid" />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="count-box4">
                          <div>
                            <span className="count">104</span>
                            <p>Applied Jobs</p>
                          </div>

                          <div className="count-img">
                            <img src="assets/img/icons/edit.png" alt="" className="img-fluid" />
                          </div>
                        </div>
                      </div>

                      
                    </div>
                    

                    <div className="row" style={{marginTop:'50px'}}>
                     
                        {jobList()}
                      
                    </div>
                  </div>
                </div>
              }

              {appliedJobsSec && <AppliedJobs />}

              {profileSec && <JobseekerProfile setDashBoardSec = {setDashBoardSec} setApplliedJobsSec = {setApplliedJobsSec} setProfileSec = {setProfileSec} setProfileSettingsSec = {setProfileSettingsSec} setChangePwd = {setChangePwd} /> }

              {profileSettingsSec && <JobseekerProfileUpdate />}

              { changePwd && <JobSekerChangePassword />}

              {/* { jobDetailsSec && <JobDetails /> } */}





            </div>
          </div>
        </section>




      </main>
    </>
  )
}

export default JobSeekerDashboard
