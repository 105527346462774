import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { LocalStorage } from 'shared/utils/localStorageHelpers';
import ManageJobsService from 'services/ManageJobsService/manage.jobs.service';
import ContextData from 'views/useContext';
import './jobdetails.css'

const JobDetails = () => {

    const navigate = useNavigate()

    const data = LocalStorage?.getItem("USER")

    const [toggle, setToggle] = useState(false)

    const location = useLocation();
    const jobPostId =   location.state?.jobPostId;
    const userId    =   data?.user?.id



    const [statusMsg, setStatusMsg]             =   useState('')
    // const jobStatus =   location.state?.status;

    // console.log('satte', location.state)


    const { postJob, getJobPosts, applyJobPost, appliedJobsList, jobAppliedStatus, appliedJobs, jobs, jobAppliedUsers, singleJob, getSingleJobPost } = ManageJobsService()

    
    // const currJobId =   singleJob?.id;
    // console.log('JobID', jobPostId)
    // console.log('userID', userId)
    // debugger
    // console.log('jobAppliedUsers', jobAppliedUsers)

    useEffect(() => {        
        getSingleJobPost(jobPostId)
        userId && jobAppliedStatus(jobPostId)
    }, [])

   
    

    const isExist = jobAppliedUsers?.some((application:any) => {
        return application?.job_seeker?.some((seeker:any) => seeker?.id === userId);
      });

    //   console.log('jobAppliedUsers', jobAppliedUsers)

    const applyJob = (async (jobID: any) => {

        // Get the current date
        const now = new Date();

        // Extract year, month, and day
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(now.getDate()).padStart(2, '0');

        // Format the date as "yyyy-mm-dd"
        const formattedDate = `${year}-${month}-${day}`;


        

            let jobInfo = {
                "job_seeker_ids": [data?.user?.id],
                "job_post_ids": jobID,    // Replace with the ID of the job post
                "application_date": formattedDate,
                "status": "Applied"  // Use a valid status value from your choices
            }
            // console.log('jobInfo', jobInfo)
    
            const applyStatus = await applyJobPost(jobInfo)
    
            // const applyStatus = await applyJobPost(jobInfo)
            // console.log('applyStatus', applyStatus?.data.status)
        
            if(applyStatus?.data.status === 'Applied') {
        
            setStatusMsg('You are successfully applied for the job')
        
            }

        

        
    })


    // console.log('singleJob', singleJob)

    

    const singleJobCategoryInfo = () => {

        return singleJob?.job_function.map((category: any) => {
            return (
                <>
                    <li style={{ listStyle: 'none', fontSize: '12px', padding: '5px 0 0 0' }}>{category?.name}</li>
                </>
            )
        })
    }



    return (
        <>

            <div className="mt-50"></div>

            <main id="main">
                <div className="page-title">
                    <nav className="breadcrumbs">
                        <div className="container">
                            <ol>
                                <li><a href="index.php">Home</a></li>
                                <li className="current">Job Details</li>
                            </ol>
                        </div>
                    </nav>
                </div>


                <section className="f0f5f3">
                    <div className="container">

                    { statusMsg ? <span style={{color:'green'}}>{statusMsg}</span> : null }

                        <div className="row">

                            <div className='mobile-view'>
                                   <div className="jobs-nav-item ">
                                        <img src="assets/img/Companylogos/Companylogos1.png" alt="Salesforce Logo" />
                                        <div>
                                        <h5 style={{ marginTop: '10px' }}>{singleJob?.company?.company_name}</h5>
                                        <a href={singleJob?.company?.company_website} target='_blank' >{singleJob?.company?.company_website}</a>
                                    
                                        </div>
                                        </div>
                            </div>

                            <div className="col-md-9 ">
                                <div className="container jobsdetails">

                                    {/* <h6>IT & Networking</h6> */}
                                    <h2>{singleJob?.title}</h2> <br />

                                    {/* <div className="jobsdetails-box">
                                        <div className="flexing">
                                            <span className="number">1</span>
                                            <h5>Overview</h5>
                                        </div>
                                        <p>When team members told us they needed more flexibility around where and how they worked, we acted, creating two options to accommodate two different styles of work. One non-negotiable principle along the way? We had to retain our deep culture of collaboration, both among ourselves and with our clients. Introducing Work From Near and Work From Anywhere at WillowTree. Please indicate which location(s) you're interested.</p>
                                    </div> */}

                                     <div className="jobsdetails-box">
                                        <div className="flexing">
                                            {/* <span className="number">2</span> */}
                                            <h5>Job Description</h5>
                                        </div>
                                        {/* <p>{singleJob?.job_details}</p> */}
                                        <p dangerouslySetInnerHTML={{ __html: singleJob?.job_details }} />
                                    </div>

                                    {/*<div className="jobsdetails-box">
                                        <div className="flexing">
                                            <span className="number">3</span>
                                            <h5>Responsibilities</h5>
                                        </div>
                                        <p>As a Product Designer at WillowTree, you’ll give form to ideas by being the voice and owner of product decisions. You’ll drive the design direction, and then make it happen!</p>
                                        <ul>
                                            <li data-content="Collaborate daily with a multidisciplinary team of Software Engineers, Researchers, Strategists, and Project Managers."></li>
                                            <li data-content="Co-lead ideation sessions, workshops, demos, and presentations with clients on-site."></li>
                                            <li data-content="Push for and create inclusive, accessible design for all."></li>
                                            <li data-content="Maintain quality of the design process and ensure that when designs are translated into code they accurately reflect the design specifications."></li>
                                            <li data-content="Sketch, wireframe, build IA, motion design, and run usability tests."></li>
                                            <li data-content="Design pixel-perfect responsive UIs and understand that adopting common interface patterns is better for UX than reinventing the wheel."></li>
                                            <li data-content="Ensure content strategy and design are perfectly in-sync."></li>
                                            <li data-content="Give and receive design critique to help constantly refine and push our work."></li>
                                        </ul>
                                    </div> */}

                                    {/* <div className="jobsdetails-box">
                                        <div className="flexing">
                                            <span className="number">4</span>
                                            <h5>Required Skills</h5>
                                        </div>
                                        <ul>
                                            <li>You’ve been designing digital products for 2+ years.</li>
                                            <li>A portfolio that exemplifies strong visual design and a focus on defining the user experience.</li>
                                            <li>You’ve proudly shipped and launched several products.</li>
                                            <li>You have some past experience working in an agile environment – Think two-week sprints.</li>
                                            <li>Experience effectively presenting and communicating your design decisions to clients and team members.</li>
                                            <li>Up-to-date knowledge of design software like Figma, Sketch, etc.</li>
                                        </ul>
                                    </div> */}
                                </div>
                            </div>

                            <div className="col-md-3 mobile-view">
                            

                            <div className="  ">
                                <div className="jobs-nav ">



                                    <div className="deatils">
                                        <span>Location:</span><br />
                                        <p>{singleJob?.location?.name}</p>
                                    </div>

                                    <div className="deatils">
                                        <span>Email:</span><br />
                                        <p>{singleJob?.company?.company_email}</p>
                                    </div>

                                    <div className="deatils">
                                        <span>Phone:</span><br />
                                        <p>+{singleJob?.company?.company_isd_code} {singleJob?.company?.company_phone_number}</p>
                                    </div>

                                    <div className="deatils">
                                        <span>Category:</span><br />
                                        <ul style={{ paddingLeft: '0px' }}>
                                            {singleJobCategoryInfo()}

                                        </ul>
                                    </div>
                                </div>
                                <center>
                                       
                                       { !userId ? <a className="loginbtn text-center" href="javascript:void(0)" onClick={()=>navigate('/jobseeker-login')} style={{ padding: '10px 30px' }}>Login to Apply</a> : 
                                       (!isExist && !statusMsg) ? 
                                       
                                       <a className="loginbtn text-center" href="javascript:void(0)" onClick={() => applyJob(singleJob?.id)}> Apply</a> : <p>You are already applied for this job</p>}
                                       
                                   </center>
                            </div>
                        </div>

                            <div className="col-md-3 desktop-view">
                            

                                <div className="jobdetailssidenav ">
                                    <div className="side-nav-item jobs-nav ">

                                        <div className="jobs-nav-item text-center">
                                            <img src="assets/img/Companylogos/Companylogos1.png" alt="Salesforce Logo" />
                                            <h5 style={{ marginTop: '10px' }}>{singleJob?.company?.company_name}</h5>
                                            <a href={singleJob?.company?.company_website} target='_blank' style={{ fontSize: '10px', fontWeight: '600' }}>{singleJob?.company?.company_website}</a>
                                        </div>

                                        <div className="deatils">
                                            <span>Location:</span><br />
                                            <p>{singleJob?.location?.name}</p>
                                        </div>

                                        {/* <div className="deatils">
            <span>Size:</span><br />
              <p>5000+</p>
            </div> */}

                                        <div className="deatils">
                                            <span>Email:</span><br />
                                            <p>{singleJob?.company?.company_email}</p>
                                        </div>

                                        <div className="deatils">
                                            <span>Phone:</span><br />
                                            <p>+{singleJob?.company?.company_isd_code} {singleJob?.company?.company_phone_number}</p>
                                        </div>

                                        <div className="deatils">
                                            <span>Category:</span><br />
                                            <ul style={{ paddingLeft: '0px' }}>
                                                {singleJobCategoryInfo()}

                                            </ul>
                                        </div>
                                        <center>
                                           
                                            { !userId ? <a className="loginbtn text-center" href="javascript:void(0)" onClick={()=>navigate('/jobseeker-login')} style={{ padding: '10px 30px' }}>Login to Apply</a> : 
                                            (!isExist && !statusMsg) ? 
                                            
                                            <a className="loginbtn text-center" href="javascript:void(0)" onClick={() => applyJob(singleJob?.id)}> Apply</a> : <p>You are already applied for this job</p>}
                                            
                                        </center>


                                    </div>
                                </div>
                            </div>






                        </div>
                    </div>
                </section>



            </main>


        </>
    )
}

export default JobDetails;