import React, { FC } from "react";
import { Field, ErrorMessage } from "formik";
import { Input } from "antd";
import { TextAreaProps } from "antd/lib/input/TextArea";
import Error from "../Error";
import "./inputField.scss";

interface InputFieldProps {
  type?: "textarea" | "search" | string;
  name: string;
  label?: string;
  placeholder: string | undefined;
  className?: string;
  addonAfter?: React.ReactElement;
  disabled?: boolean;
  value?: string | number;
  enterButton?: string;
  onSearch?: (e?: any) => void;
  onBlur?: (e?: any) => void;
  onChange?: (e?: any) => void;
  defaultValue?: string | number,
  autoComplete?: string;
  rows?: number;
  autoSize?: TextAreaProps["autoSize"];
}


const InputField: FC<InputFieldProps> = (props) => {
  const { name, label, className, type = "text", placeholder, addonAfter, disabled, enterButton, onSearch, value, onBlur, defaultValue } = props;
  // console.log(props?.name,"...props")
  return (
    <div className={`input-field ${className}`}>
      <ErrorMessage name={name}>
        {(message: string) =>{
          console.log(message,"...message")
         return <Error message={message} />}
         }
      </ErrorMessage>
      <Field as={type == "textarea" ? Input.TextArea : type == "search" ? Input.Search : Input}
        type={type} data-testid={name} value={value}
        {...(type == "textarea") ? { ...({ autoSize: true }) } : {}} addonAfter={addonAfter}
        disabled={disabled} enterButton={type == "search" && enterButton}
        onSearch={type == "search" && onSearch} onBlur={onBlur} {...props} />
      {label && <p className="input-field__label">{label}</p>}
    </div>
  );
};

export default InputField;
