import { Select, Switch, Table } from "antd";
import { useNavigate } from "react-router-dom";
import { ErrorMessage, Form, Formik } from "formik";
import { User } from "models/user.model";
import React, { useEffect, useState } from "react";
// import ManageJobsService from 'services/ManageJobsService/manage.jobs.service';
import UserService from "services/AuthService/auth.service";
import InputField from "shared/components/InputField";
import { LocalStorage } from "shared/utils/localStorageHelpers";
import JobFunctionsComponent from "../../jobFunctions";
import CompanyDashboadrPanel from "../shared";
import SuperAdminDashboadrPanel from "../shared/SuperAdminDashboardPanel";
// import JobSkillsComponent from "../../jobSkills"

// import TextEditor from './TextEditor';
import "react-quill/dist/quill.snow.css"; // Import the styles
import ReactQuill from "react-quill";
import { employeeSchema } from "./employeeSchema";

const ManageEmployee = (props: any) => {
  const navigate = useNavigate();
  const [isCreation, setIsCreation] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [gender, setGender] = useState("");

  // const [isCreation, setIsCreation] = useState(false)
  const [skills, setSkills] = useState([]);
  const data = LocalStorage.getItem("USER");

    //Using Inline Function and the The Logical Not (!) to toggle state
    const [toggle, setToggle] = useState(false)

  // console.log(data, '...userDATA')

  const {
    companyEmployees,
    singleCompanyEmployees,
    updateCompanyEmployee,
    registerCompanyEmployee,
    getSingleCompanyEmployee,
    getCompanyEmployee,
    deleteCompanyEmployee,
  } = UserService();

  // const { registerCompanyEmployee, getCompanyEmployee, companyEmployees, updateCompanyEmployee, deleteCompanyEmployee, getSingleCompanyEmployee, singleCompanyEmployees } = UserService()

  const [formTable, setFormTable] = useState(true);
  // const [postID, setPostID] = useState();
  const [successMessage, setSuccessMessage] = useState("");

  const [recordDelete, setRecordDelete] = useState(false);
  let arrayOfIds;

  const [jobInfo, setJobInfo] = useState({
    title: "",
    job_details: "",
    requirements: "",
    salary: "",
    application_deadline: "",
    maximum_experience: "",
    minimum_experience: "",
    mode: "",
    job_type: "",
    status: "",
    job_function_ids: [],
    company_ids: "",
    location_ids: "",
    // skills: []
  });

  // useEffect(()=>{
  //     getJobPosts()
  // },[formTable])

  useEffect(() => {
    getCompanyEmployee();
  }, [isUpdated, !isCreation]);

  console.log("singleCompanyEmployees", singleCompanyEmployees);

  const onSearch = (value: string) => {
    console.log("search:", value);
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  // const columns:any = [

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      render: (active: any, data: User) => (
        <Switch
          defaultChecked={active}
          onChange={(value: boolean) => {
            updateCompanyEmployee(data?.id!, { active: value });
            setIsUpdated(true);
          }}
        />
      ),
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "actions",
      render: (action: any, data: User) => (
        <>
          <span
            style={{ marginRight: "10px", cursor: "pointer" }}
            onClick={async () => {
              const desiredData = await getSingleCompanyEmployee(data?.id!);
              setIsCreation(true);
              setGender(desiredData?.data?.gender);
            }}
          >
            <i className="bi bi-pencil"></i>
          </span>
          {/* <span style={{cursor: 'pointer'}} onClick={() => {

                    const isConfirmed = window.confirm('Are you sure you want to delete?');

                    // If the user confirms, proceed with deletion
                    if (isConfirmed) {
                        // deleteCompanyEmployee(data?.id!); setIsUpdated(true); getCompanyEmployee()
                    }

                }}>
                    <i className="bi bi-trash"></i></span> */}
        </>
      ),
    },
  ];

  const onSelectChange = (value: string) => {
    console.log(`selected ${value}`);
    setGender(value);
  };

  const desiredColumns = columns?.filter(
    (column: any) => column?.title !== "Actions"
  );

  return (
    <>
      <div className="mt-50"></div>
      <main id="main">
        <div className="page-title">
          <nav className="breadcrumbs">
            <div className="container">
              <ol>
                <li>
                  <a href="index.php">Home</a>
                </li>
                <li className="current">Company Dashboard</li>
              </ol>
            </div>
          </nav>
        </div>
        <section className="f0f5f3">
          <div className="container">
            
            
            <div className="row">
              <div className="col-md-3">

                {/* <div className="side-nav ">
                  {data?.user?.role === "Super Admin" ? (
                    <SuperAdminDashboadrPanel />
                  ) : (
                    <CompanyDashboadrPanel />
                  )}
                </div> */}


           <div className='mobilesidenavmenu'>
                        <button className='sidenavmenu btn sidenavmenubtn  mb-5' onClick={() => setToggle(!toggle)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-bar-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M6 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L12.293 7.5H6.5A.5.5 0 0 0 6 8m-2.5 7a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5"/>
                          </svg>
                          </button>
                            {
                              toggle &&

                              <div className="cmp-side-nav  ">
                              {data?.user?.role === "Super Admin" ? (
                                <SuperAdminDashboadrPanel />
                              ) : (
                                <CompanyDashboadrPanel />
                              )}
                            </div>
                          }   
                </div>
                          
            
                <div className="desktopsidenavmenu">
                <div className="cmp-side-nav  ">
                  {data?.user?.role === "Super Admin" ? (
                    <SuperAdminDashboadrPanel />
                  ) : (
                    <CompanyDashboadrPanel />
                  )}
                </div>
                </div>




                
              </div>
              <div className="col-md-9 ">
                <div className="row mt-10" style={{ marginTop: "20px" }}>
                  {/* <h3>Employee Enrollment</h3> */}

                  {successMessage ? (
                    <p className="text-info">{successMessage}</p>
                  ) : (
                    ""
                  )}
                  {isCreation && (
                    <Formik
                      enableReinitialize
                      initialValues={{
                        name: singleCompanyEmployees?.name || "",
                        email: singleCompanyEmployees?.email || "",
                        password: singleCompanyEmployees?.password || "",
                        gender: singleCompanyEmployees?.gender || "",
                        role: singleCompanyEmployees?.role || "",
                      }}
                      validationSchema={employeeSchema}
                      // validate={(values) => {
                      //   const errors: any = {};

                      //   if (values?.gender === "") {
                      //     errors.gender = "Please select at least one option";
                      //   }

                      //   if (!singleCompanyEmployees && (values?.password === "" || values?.password.length < 8)) {
                      //     errors.password = "Password required, must be at least 8 characters";
                      //   }

                      //   return errors;
                      // }}
                      onSubmit={async (values, { resetForm }) => {
                        console.log('singleCompanyEmployees', singleCompanyEmployees );
                        if (singleCompanyEmployees?.id) {
                          const data = await updateCompanyEmployee(
                            singleCompanyEmployees?.id,
                            values
                          );
                          if (data?.status === "success") {
                            setIsCreation(false);
                            setSuccessMessage("");
                            resetForm();
                            setIsUpdated(true);
                          } else {
                            setSuccessMessage("Something went wrong");
                          }
                        } else {
                          // const user = await registerCompanyEmployee({ ...values, gender })
                          const user = await registerCompanyEmployee({
                            ...values,
                          });
                          if (user?.data?.status === "success") {
                            setIsCreation(false);
                            setSuccessMessage("");
                            resetForm();
                            setIsUpdated(true);
                          } else {
                            setSuccessMessage("Something went wrong");
                          }
                        }
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form
                          className="php-email-form"
                          data-aos="fade-up"
                          data-aos-delay="200"
                        >
                          <div
                            className="col-md-8"
                            style={{ marginTop: "20px" }}
                          >
                            <label>Name:</label>
                            <InputField
                              placeholder="Enter Employe name"
                              name="name"
                              value={values?.name}
                            />
                          </div>
                          <div
                            className="col-md-8"
                            style={{ marginTop: "20px" }}
                          >
                            <label>Email:</label>
                            <InputField
                              placeholder="Enter Email"
                              name="email"
                              value={values?.email}
                            />
                          </div>

                          {singleCompanyEmployees ? null : (
                            <div
                              className="col-md-8"
                              style={{ marginTop: "20px" }}
                            >
                              <label>Password</label>
                              <InputField
                                placeholder="Enter Password"
                                name="password"
                                type="password"
                                value={values?.password}
                              />
                              {/* <ErrorMessage name="password">
                              {(msg) => (
                                <div style={{ color: "#c9583e" }}>{msg}</div>
                              )}
                            </ErrorMessage> */}
                            </div>
                           )}
                          <div
                            className="col-md-8"
                            style={{ marginTop: "20px" }}
                          >
                            <label>Gender:</label>
                            <Select
                              showSearch
                              placeholder="Select a gender"
                              optionFilterProp="children"
                              value={values?.gender}
                              onChange={(value) =>
                                setFieldValue("gender", value)
                              }
                              onSearch={onSearch}
                              filterOption={filterOption}
                              options={[
                                {
                                  value: "",
                                  label: "Select",
                                },
                                {
                                  value: "male",
                                  label: "Male",
                                },
                                {
                                  value: "female",
                                  label: "Female",
                                },
                              ]}
                              style={{ width: "100%" }}
                            />
                            <ErrorMessage name="gender" >
                              {(msg) => (
                                <div className="input__error" style={{ color: "#c9583e" }}>{msg}</div>
                              )}
                            </ErrorMessage>
                          </div>

                          {values?.role ? (
                            <div
                              className="col-md-8"
                              style={{ marginTop: "20px" }}
                            >
                              <label>Role:</label>
                              <Select
                                showSearch
                                placeholder="Select a Role"
                                optionFilterProp="children"
                                // onChange={onSelectChange}
                                value={values?.role}
                                // onChange={(e) => setFieldValue('gender', e.target.value)}
                                onChange={(value) =>
                                  setFieldValue("role", value)
                                }
                                onSearch={onSearch}
                                filterOption={filterOption}
                                options={[
                                  {
                                    value: "",
                                    label: "Select",
                                  },
                                  {
                                    value: "Company Employee",
                                    label: "Company Employee",
                                  },
                                  {
                                    value: "Company Admin",
                                    label: "Company Admin",
                                  },
                                ]}
                                style={{ width: "100%" }}
                              />
                            </div>
                          ) : null}

                          <div
                            className="col-md-6 "
                            style={{ marginTop: "20px" }}
                          >
                            <button className="submit" type="submit">
                              Submit
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  )}

                  {!isCreation && (
                    <div>
                      {data?.user?.role !== "Super Admin" && (
                        <a
                          href="javascript:void(0)"
                          className="loginbtn"
                          onClick={() => setIsCreation(true)}
                        >
                          Create Employee
                        </a>
                      )}

                      <Table
                        dataSource={companyEmployees}
                        columns={
                          data?.user?.role === "Super Admin"
                            ? desiredColumns
                            : columns
                        }
                        pagination={false}
                        className="margintop2p tableScroll"
                      />
                    </div>
                  )}
                </div>

                {/* {formTable && <div style={{ marginTop: "20px" }}>
                <button onClick={()=> { setFormTable(false)}}>Create Job</button>
                <Table
                dataSource={jobs}
                columns={columns} pagination={false} />
                </div>} */}
              </div>
            </div>

            
          </div>
        </section>
      </main>
    </>
  );
};

export default ManageEmployee;
