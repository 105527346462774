import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { LocalStorage } from 'shared/utils/localStorageHelpers';
import ManageJobsService from 'services/ManageJobsService/manage.jobs.service';
import ContextData from 'views/useContext';
import UserService from 'services/AuthService/auth.service';

const JobseekerProfile = (props: any) => {

  const navigate = useNavigate();

  const  { setDashBoardSec, setApplliedJobsSec, setProfileSec, setProfileSettingsSec, setChangePwd } = props;   

  const { getJobSeekerUser, singleUser } = UserService()

  const { postJob, getJobPosts, applyJobPost, appliedJobsList, appliedJobs, jobs, singleJob } = ManageJobsService()

  const data = LocalStorage?.getItem("USER")

  useEffect(() => {
    getJobPosts()
    appliedJobsList(data?.user)
    getJobSeekerUser(data?.user?.id)
  }, [])


  const accountSettingsActive = () => {
    setDashBoardSec(false)
    setApplliedJobsSec(false)
    setProfileSec(false)
    setProfileSettingsSec(true)
    setChangePwd(false)
  }

  // console.log(singleUser, "...singleUser")





  return (
    <>
      <div className="col-md-9 padding-left-2rem pbg">
        <div className="row gy-4">
          {/* <h4>
            Profile
          </h4>

          <div className="companyprofile-info">
            <img src="assets/img/icons/Profile.png" alt="Salesforce Logo" className="img-fluid" />
            <h5>
              {singleUser?.user_name}
            </h5>
          </div> */}

          <div className="Description">
            <h5>
              Professional Summary
           <span onClick={accountSettingsActive}><svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-pencil penciledit" viewBox="0 0 16 16"><path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"></path></svg></span>
           </h5><p>
              <div dangerouslySetInnerHTML={{ __html: singleUser?.bio }} />
              {/* {singleUser?.bio} */}
            </p>
          </div>

          <div className="col-md-4">
            <p><i className="bi bi-globe2"></i> {singleUser?.linkedin}</p>
          </div>

          <div className="col-md-4">
            <p><i className="bi bi-mailbox"></i> {singleUser?.email}</p>
          </div>

          <div className="col-md-4">
            <p><i className="bi bi-phone"></i>{singleUser?.isd_code + ' ' + singleUser?.phone_number}</p>
          </div>

          <div className="col-md-4">
            <p>
              {singleUser?.date_of_birth}
            </p>
          </div>

          <h5>Skills<span onClick={accountSettingsActive}><svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-pencil penciledit" viewBox="0 0 16 16"><path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"></path></svg></span>
           </h5>
          <div className="col-md-6">
            <p>{singleUser?.skills}</p>
          </div>

          <h6>Educational Details</h6>
          <div className="col-md-3">
            <p>{singleUser?.education_level}</p>
          </div>

          <div className="col-md-3">
            <p>{singleUser?.degree_name}</p>
          </div>

          <div className="col-md-3">
            <p>{singleUser?.college_name}</p>
          </div>

          <div className="col-md-3">
            <p>{singleUser?.university}</p>
          </div>

          <div className="col-md-3">
            <p>{singleUser?.specialization}</p>
          </div>

          <div className="col-md-4">
            <p>{singleUser?.degree_type}</p>
          </div>

          <div className="col-md-3">
            <p>{singleUser?.start_date}</p>
          </div>

          <div className="col-md-3">
            <p>{singleUser?.end_date}</p>
          </div>




          <h5>Certifications<span onClick={accountSettingsActive}><svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-pencil penciledit" viewBox="0 0 16 16"><path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"></path></svg></span>
           </h5>
          <div className="Description">
            <ul>
              {
                singleUser?.certification_one?.map((cert: any, index: any) => {
                  return (
                    <li>{cert?.name}</li>
                  )
                })
              }
              {/* <li>{singleUser?.certification_one}</li> */}
              {/* <li>{singleUser?.certification_two}</li> */}
            </ul>
          </div>

          <h5>Trail Blaizer Profile</h5>
          <div className="col-md-4">
              <p>{singleUser?.trail_blaizer_profile}</p>
          </div>

          <h4>
            Preferred Locations
            <span onClick={accountSettingsActive}><svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-pencil penciledit" viewBox="0 0 16 16"><path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"></path></svg></span>
           </h4>

          <div className="col-md-4">
            <p>{singleUser?.location_one}, {singleUser?.location_two}, {singleUser?.location_three}</p>
          </div>

          <h5>
            {singleUser?.street}
          </h5>

          <div className="col-md-4">
            <p>Country : {singleUser?.country}</p>
          </div>
          <div className="col-md-4">
            <p>State : {singleUser?.state}</p>
          </div>
          <div className="col-md-4">
            <p>City : {singleUser?.city} </p>
          </div>
          <div className="col-md-4">
            <p>Pincode : {singleUser?.postal_code} </p>
          </div>

          <div className="col-md-4">
            <p>Salary Expectations : {singleUser?.salary_expect} LPA</p>
          </div>

          <div className="col-md-4">
            <p> Languages Known : {singleUser?.languages}</p>
          </div>
          <div className="col-md-4">
            <p> Availability Date : {singleUser?.available_date}</p>
          </div>

        </div>
      </div>
    </>
  )
}

export default JobseekerProfile;