import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useContext, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { LocalStorage } from 'shared/utils/localStorageHelpers';
import UserService from "services/AuthService/auth.service";
import InputField from "../../../shared/components/InputField";
import ContextData from "../../useContext";
import { changePasswordSchema } from "./changePasswordSchema";
// import "../login.scss"

const JobSeekerForgotPassword = () => {
  const navigate = useNavigate()
  const { loginUser, superAdminlogin, jobSeekerUpdatePassword, sendOtpJobSeeker,jobSeekerVerifyOtpForgotPwd, jobSeekerChangePassword } = UserService()
  const [msg, setMsg] = useState("")
  const [errMsg, setErrMsg] = useState("")
  const [loginErr, setLoginErr] = useState('')
  const [err, setErr] = useState<string>("");
  const [isEmail, setIsEmail] = useState(true);
  const [isVerify, setIsVerify] = useState(false);
  const [isEnterPwd, setIsEnterPwd] = useState(false);

  // const userData = LocalStorage.getItem("USER").user

  // console.log('userData', userData)

  // const { data, setData } = useContext<any>(ContextData);

  // const location = useLocation()

  // const currentPath = location.pathname

  // console.log(currentPath)

  const handleSubmit = async (values: any) => {
    const otp = values.digit1 + values.digit2 + values.digit3 + values.digit4;
    // Here you can perform the OTP verification logic

    const userData = await jobSeekerVerifyOtpForgotPwd({ ...values, otp });
    if (userData?.status === "success") {

      setIsVerify(false);
      setIsEnterPwd(true);
    } else {
      setErr("Enter Valid OTP");
    }
  };

  return (
    <main id="main">
      <section id="loginregister" className="loginregister">
        <div className="loginregisterimg">
          <div className="container" data-aos="fade-up" data-aos-delay="100">
            <div className="row gy-4">
              <div className="col-lg-5 "></div>

              <div className="col-lg-7">
                <span style={{ color: 'green' }}>{msg ? msg : ''}</span>
                <span style={{ color: 'red' }}>{errMsg ? errMsg : ''}</span>
                <Formik
                  enableReinitialize
                  initialValues={{
                    email: "",
                    password: "",
                    new_password: "",
                  }}
                  validationSchema={changePasswordSchema}
                  onSubmit={async (values) => {

                    const info = await jobSeekerChangePassword(values)

                    console.log('info', info)
                    if (info) {

                      setMsg('Password updated successfully')
                      setErrMsg("")

                      // setData(info.data)
                      // navigate("/super-admin-dashboard", { state: { data: info?.data } })
                      // return
                    } else {
                      setErrMsg('Invalid')
                      setMsg("")
                    }
                    // }
                    // if (info) {
                    //   setData(info.data)
                    //   navigate("/company-dashboard", { state: { data: info?.data } })
                    // } else {
                    //   setLoginErr('invalid credentials ')
                    // }

                  }}
                >
                  {({ values, setFieldValue }) => (
                    <Form
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      {loginErr && <div className="alert alert-danger">{loginErr}</div>}

                      {(isEmail &&
                        <div>
                          <h4>Enter Email to get OTP</h4>
                          <div className="col-md-8 field">
                            <InputField name="email" placeholder="Your Email" />
                          </div>
                          <div className="col-md-12 m-50w">
                          <button
                            className="submit"
                            type="button"
                            onClick={async () => {
                              const isPartialValid = await sendOtpJobSeeker(
                                { email: values.email }
                                // setFieldError
                              );

                              // console.log(isPartialValid, 'isPartialValid')
                            
                              if (isPartialValid !== false) {
                                setErrMsg('');
                                setIsEmail(false);
                                setIsVerify(true);
                                const { email, ...refinedInfo } = values;
                                // LocalStorage.setItem("COMPANY_ADMIN_INFO", JSON.stringify(refinedInfo));
                              } else {
                                setErrMsg('user not existed with this email id')
                              }
                            }}
                          >
                            Next Step
                          </button>
                          </div>
                      </div>
                      )}

                       {(isVerify && 
                        <div className="col-lg-7">
                        <div className="row gy-4">
                          <h4>Enter verification code sent to your mail</h4>
                          {err ? <p className="text-danger">{err}</p> : ""}
                          <Field
                            className="col-md-1 otp_field"
                            type="text"
                            name="digit1"
                            maxLength={1}
                          />
                          <ErrorMessage name="digit1" component="div" />
                          <Field
                            className="col-md-1 otp_field"
                            type="text"
                            name="digit2"
                            maxLength={1}
                          />
                          <ErrorMessage name="digit2" component="div" />
                          <Field
                            className="col-md-1 otp_field"
                            type="text"
                            name="digit3"
                            maxLength={1}
                          />
                          <ErrorMessage name="digit3" component="div" />
                          <Field
                            className="col-md-1 otp_field"
                            type="text"
                            name="digit4"
                            maxLength={1}
                          />
                          <ErrorMessage name="digit4" component="div" />
                          <button
                            className="submit"
                            type="button"
                            onClick={() => {
                              handleSubmit(values);
                            }}
                          >
                            Verify OTP
                          </button>
                        </div>
                      </div>
                      )}

                      {(isEnterPwd && 
                      <div>
                        <h4>Reset Password</h4>
                        <div className="col-md-8 field">
                          <InputField
                            placeholder="Password"
                            name="password"
                            type="password"
                          />
                        </div>

                        <div className="col-md-8 field">
                          <InputField
                            placeholder="New Password"
                            name="new_password"
                            type="password"
                          />
                        </div>

                        <div className="col-md-12 field">
                          <button type="submit" className="submit">Update</button>
                          {/* <a href="/register" style={{paddingLeft:"30px"}}>Need Accout?, Signup here</a> */}
                          {/* <Link className="btn-getstarted" to="/register" style={{ paddingLeft: '30px' }}> Don't have account? Signup here</Link> */}
                        </div>
                      </div>
                      )}
                    </Form>
                  )}
                </Formik>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default JobSeekerForgotPassword;





// import { Form, Formik } from "formik";
// import React, { useContext, useState } from "react";
// import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
// import { LocalStorage } from 'shared/utils/localStorageHelpers';
// import UserService from "services/AuthService/auth.service";
// import InputField from "../../../shared/components/InputField";
// import ContextData from "../../useContext";
// import { changePasswordSchema } from "./changePasswordSchema";
// // import "../login.scss"

// const JobSeekerForgotPassword = () => {
//   const navigate = useNavigate()
//   const { loginUser, superAdminlogin, jobSeekerUpdatePassword } = UserService()
//   const [msg, setMsg] = useState("")
//   const [errMsg, setErrMsg] = useState("")
//   const [loginErr, setLoginErr] = useState('')

//   // const userData = LocalStorage.getItem("USER").user

//   // console.log('userData', userData)

//   // const { data, setData } = useContext<any>(ContextData);

//   // const location = useLocation()

//   // const currentPath = location.pathname

//   // console.log(currentPath)

//   return (
//     <main id="main">
//       <section id="loginregister" className="loginregister">
//         <div className="loginregisterimg">
//           <div className="container" data-aos="fade-up" data-aos-delay="100">
//             <div className="row gy-4">
//               <div className="col-lg-5 "></div>

//               <div className="col-lg-7">
//                 <span style={{ color: 'green' }}>{msg ? msg : ''}</span>
//                 <span style={{ color: 'red' }}>{errMsg ? errMsg : ''}</span>
//                 <Formik
//                   enableReinitialize
//                   initialValues={{
//                     email: "",
//                     password: "",
//                     new_password: "",
//                   }}
//                   validationSchema={changePasswordSchema}
//                   onSubmit={async (values) => {

//                     // console.log(values, '...values')
//                     // if(currentPath === "/login"){
//                     //    info = await loginUser(values)
//                     //    console.log(info, '...userINFO')
//                     //    if (info) {
//                     //     setData(info.data)
//                     //     navigate("/company-dashboard", { state: { data: info?.data } })
//                     //   } else {
//                     //     setLoginErr('invalid credentials or user not found ')
//                     //   }
//                     // }else{
//                     const info = await jobSeekerUpdatePassword(values)

//                     console.log('info', info)
//                     if (info) {

//                       setMsg('Password updated successfully')
//                       setErrMsg("")



//                       // setData(info.data)
//                       // navigate("/super-admin-dashboard", { state: { data: info?.data } })
//                       // return
//                     } else {
//                       setErrMsg('Invalid credentials')
//                       setMsg("")
//                     }
//                     // }
//                     // if (info) {
//                     //   setData(info.data)
//                     //   navigate("/company-dashboard", { state: { data: info?.data } })
//                     // } else {
//                     //   setLoginErr('invalid credentials ')
//                     // }

//                   }}
//                 >
//                   {({ values, setFieldValue }) => (
//                     <Form
//                       data-aos="fade-up"
//                       data-aos-delay="200"
//                     >
//                       <h4>Enter the details</h4>
//                       {loginErr && <div className="alert alert-danger">{loginErr}</div>}

//                       <div className="col-md-8 field">
//                         <InputField name="email" placeholder="Your Email" />
//                       </div>

//                       <div className="col-md-8 field">
//                         <InputField
//                           placeholder="Password"
//                           name="password"
//                           type="password"
//                         />
//                       </div>

//                       <div className="col-md-8 field">
//                         <InputField
//                           placeholder="New Password"
//                           name="new_password"
//                           type="password"
//                         />
//                       </div>

//                       <div className="col-md-12 field">
//                         <button type="submit" className="submit">Update</button>
//                         {/* <a href="/register" style={{paddingLeft:"30px"}}>Need Accout?, Signup here</a> */}
//                         {/* <Link className="btn-getstarted" to="/register" style={{ paddingLeft: '30px' }}> Don't have account? Signup here</Link> */}
//                       </div>
//                     </Form>
//                   )}
//                 </Formik>
//                 {/* </div> */}
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </main>
//   );
// };

// export default JobSeekerForgotPassword;
