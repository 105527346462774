const STUB_URL = process.env.REACT_APP_STUB_URL;

const FINANCE = "vendor/finance";

export const ApiRoutes = {
    // BASE_URL: "http://127.0.0.1:8000/api/v1/",
    BASE_URL: "http://89.116.34.244:8000/api/v1/",
    
    USER_LOGIN: "company_admin_management/auth/login",
    SEND_OTP: "company_admin_management/send_otp",


    JOBSEEKER_FORGOT_PWD_SEND_OTP_TO_EMAIL: "job_seeker_management/send_otp_email_forgot_pwd",
    JOB_SEEKER_FORGOT_VERIFY_OTP : "job_seeker_management/auth/verify_otp",
    JOB_SEEKER_FORGOT_UPDATE_PWD: "job_seeker_management/forgot_change_password",


    COMPANY_ADMIN_FORGOT_PWD: "company_admin_management_forgot_pwd_send_otp_email/users",
    VERIFY_OTP : "company_admin_management/verify_otp",    
    COMP_ADMIN_CHANGE_PWD: "company_management/auth/change_password",
    COMP_ADMIN_CHANGE_PWD_FORGOT: "company_management/change_password_forgot",


    JOB_SEEKER_VERIFY_OTP : "job_seeker_management/auth/verify_otp",
    


    // REGISTER_USER: "company_admin_management/users",
    COMPANY_ADMIN: "company_admin_management/users",
    COMPANY_ADMIN_WITHOUT_LOGIN: "company_admin_management_without_login/users",
    COMPANY_ADMIN_DEFAULT: "company_admin_management_without_login/users",
    COMPANY_ADMIN_LOGOUT : "company_admin_management/auth/logout",
    JOB_SEEKER_REGISRATION: "job_seeker_management/register",
    GET_JOB_SEEKER_REGISRATION: "job_seeker_management/register_update",
    JOB_SEEKER_USER_LOGIN: "job_seeker_management/auth/login",
    LOGOUT:"job_seeker_management/auth/logout",
    COMPANY_EMPLOYEE_REGISTRATION: "company_admin_management/employee/register",
    COMPANY_EMPLOYEE_UPDATION: "company_management/employee",
    POST_JOB : "job_post_management/post",
    GET_SINGLE_JOB_WITHOUT_LOGIN: "job_post_management_without_login/post",
    JOB_FUNCTIONS: "metalist_management/job_function",
    LOCATIONS: "metalist_management/location",
    COUNTRY: "metalist_management/country",
    JOB_FUNCTIONS_WIHTOUT_LOGIN: "metalist_management/job_function_without_login",
    APPLY_JOB_POST: "job_post_management/apply_post",
    APPLIED_JOBS_LIST: "job_post_management/applied_posts",
    JOB_APPLIED_STATUS: "job_post_management/post/application_list",
    SUPER_ADMIN_LOGIN: "super_admin_management/auth/login",
    SUPER_ADMIN_LOGOUT: "super_admin_management/auth/logout",
    SKILLS: "metalist_management/skils",
    
    COMP_TYPES: "metalist_management/company_types",
    COMPANY_LIST: "content_management/company",
    CURRENCY_TYPES: "metalist_management/currency",
    CERTIFICATION_TYPES: "metalist_management/certification",
    JOB_SEEKER_CHANGE_PWD: "job_seeker_management/auth/change_password",
    JOB_LIST_WITHOUT_LOGIN: 'job_post_without_login_management/post',
    JOBS_SEEKER_CREATE_NOTE: 'job_post_management/job_seeker_notes',

    TOTAL_COMP_LIST: 'super_admin_management/dashboard/company',
    TOTAL_JOBSEEKET_LIST: 'super_admin_management/dashboard/job_seeker',
    TOTAL_JOB_POST_LIST: 'super_admin_management/dashboard/job_post',

    // job_post_management/post/application_list/<int:job_post_id>/job_seeker/<int:job_seeker_id>

};
