import * as Yup from "yup";

export const ManageJobsValidationSchema = Yup.object({
  title: Yup.string().required("Job title is required"),
  requirements: Yup.string().required("Requirements is required"),
  job_details: Yup.string().required("Job Description is required"),
  salaryType: Yup.string().required("Please select at least one option"),
  currencyType_ids: Yup.array()
      .min(1, "Please select at least one type")
      .required("Type selection is required"),
  job_function_ids: Yup.array()
      .min(1, "Please select at least one type")
      .required("Type selection is required"),
  skill_ids: Yup.array()
      .min(1, "Please select at least one type")
      .required("Type selection is required"),
  //   company_website: Yup.string().url("Invalid URL").required("Required"),
  //   company_phone_number: Yup.string()
  //     .matches(/^[0-9]+$/, "Mobile number must only contain numbers")
  //     .required("Mobile number is required"),
  //   isdNumber: Yup.string()
  //     .matches(/^\+?[0-9]+$/, "ISD  must only contain numbers")
  //     .required("ISD number is required"),

  companyLocation: Yup.string().required("Location is Required"),
  companyCountry: Yup.string().required("Country is Required"),
  minimum_experience: Yup.string().required("Min Experience is required"),
  maximum_experience: Yup.string().required("Max Experience is required"),
  //   social_media_link_1: Yup.string().url("Invalid URL").required("Required"),
  //   ocial_media_link_2: Yup.string().url("Invalid URL").required("Required"),
  salary: Yup.string().required("Salary is required"),
  application_deadline: Yup.string().required("Job Expiry required"),
  mode: Yup.string().required("Work Mode is Required"),
  job_type: Yup.string().required("Job type is Required")
  //   company_state: Yup.string().required("Required"),
});
