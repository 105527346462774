import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserService from "services/AuthService/auth.service";
import { LocalStorage } from 'shared/utils/localStorageHelpers';




const CompanyDashboadrPanel = (props: any) => {

  const { updateCompanyAdm, getCompanyAdm, singleUser, updateCompanyEmployee } = UserService()

  const { setEmployee, setIsJobManage } = props
  const navigate = useNavigate()
  const data = LocalStorage.getItem("USER")

  // console.log('dataInfo', data)

  useEffect(() => {
    getCompanyAdm(data?.user.id)
    
  }, [])

  const delUser = () => {

    // Display a prompt and capture the entered value
    //  const userInput = window.prompt('Are you sure you want to delete your account?');
    const userConfirmed = window.confirm('Are you sure you want to delete your account?');

    // Check if the user clicked "Cancel" or entered an empty value
    if (userConfirmed) {



      // Assuming `updateJobSeekerUser` is an asynchronous function
      //  updateCompanyAdm(data?.user?.id, { active: false })
      updateCompanyEmployee(data?.user?.id, { active: false })
        .then(() => {
          // Display an alert when the update is successful
          alert('User account deleted successfully!');
          // LocalStorage.clear();
          LocalStorage.removeItem("USER")
          navigate("/");
        })
        .catch((error: any) => {
          // Handle errors if the update fails
          console.error('Error updating user:', error);
          alert('Failed to update user. Please try again.');
        });

    }


  }


  // console.log('...singleUser', singleUser)



  return (
    <>

      <div className="profile text-center">
        {/* <img src="assets/img/Companylogos/Companylogos1.png" alt="Salesforce Logo" className="img-fluid" /> */}

        <img src={`http://localhost:8000${singleUser?.company_data?.company_logo}`} alt="Salesforce Logo" className="img-fluid" />

        
        <h4>{data?.user?.name}</h4>
      </div>


      <div className="side-nav-item d-flex justify-content-center">
        <ul>

          <li onClick={() => navigate('/company-dashboard')} style={{ cursor: 'pointer' }}>
            <img src="assets/img/icons/dashboard.png" alt="" className="img-fluid" />
            <p>Dashboard</p>
          </li>

          {/* <li onClick={() => setEmployee(true)}>
                        <img src="assets/img/icons/Profile.png" alt="" className="img-fluid" />
                        <p style={{cursor:'pointer'}}>Manage Employee</p>
                      </li> */}

          {data?.user?.role !== "Company Employee" ?

            <li onClick={() => navigate('/manage-employee')}>
              <img src="assets/img/icons/Profile.png" alt="" className="img-fluid" />
              <p style={{ cursor: 'pointer' }}>Manage Employee</p>
            </li>
            :
            null}

          <li onClick={() => navigate('/company-profile')} style={{ cursor: 'pointer' }}>
            <img src="assets/img/icons/Profile.png" alt="" className="img-fluid" />
            <p>Profile</p>
          </li>


          {/* <li onClick={() => { setEmployee(false); setIsJobManage(true) }}>
                      <img src="assets/img/icons/JobPosted.png" alt="" className="img-fluid" />
                        <p style={{cursor:'pointer'}}>Manage jobs</p>                        
                      </li> */}

          <li onClick={() => navigate('/manage-jobs')}>
            <img src="assets/img/icons/JobPosted.png" alt="" className="img-fluid" />
            <p style={{ cursor: 'pointer' }}>Manage jobs</p>
          </li>


          {/* <li><a href="">
                        <img src="assets/img/icons/JobPosted.png" alt="" className="img-fluid" />
                        <p>Manage jobs</p></a>
                      </li> */}

          {/* <li><a href="">
                        <img src="assets/img/icons/savedresumes.png" alt="" className="img-fluid" />
                        <p>Job Applications</p></a>
                      </li> */}

          <li onClick={() => { navigate('/company-account-settings') }} style={{ cursor: 'pointer' }}>
            <img src="assets/img/icons/AccountSettings.png" alt="" className="img-fluid" />
            <p>Account Settings</p>
          </li>

          {/* <li onClick={() => navigate('/company-account-settings')}>
                        <img src="assets/img/icons/AccountSettings.png" alt="" className="img-fluid" />
                        <p style={{cursor:'pointer'}}>Account Settings</p>
                      </li> */}

          {data?.user?.role !== "Super Admin" ?
            <li><a href="javascript:void(0)" onClick={() => { navigate('/cmpAdminUpdatePwd') }} style={{ cursor: 'pointer' }}>
              <img src="assets/img/icons/AccountSettings.png" alt="" className="img-fluid" />
              <p>Change Password</p></a>
            </li>
            :
            null}

          {data?.user?.role !== "Company Employee" ?
            <li><a href="javascript:void(0)" onClick={delUser}>
              <img src="assets/img/icons/DeleteAccount.png" alt="" className="img-fluid" />
              <p>Delete Account</p></a>
            </li>
            :
            null}


          <li><a href="">
            <img src="assets/img/icons/Logout.png" alt="" className="img-fluid" />
            <p>Logout</p></a>
          </li>
        </ul>

      </div>
    </>
  )
}

export default CompanyDashboadrPanel