import React, { useEffect, useState } from 'react';
import Multiselect from 'multiselect-react-dropdown';

import CertificationTypes from 'services/MetaList/certification.types';



export interface Group {
  id: number;
  name: string;
}

function CertificationTypeComponent(props: any) {

    
    const { getCertificationTypes, certificationTypes } = CertificationTypes()
    const { compTypeInfo, setCompTypeInfo, setFieldValue, singleJob, singleUser, selectedVal }    =   props;

    console.log('singleUSER', singleUser?.certification_one)

  

  // const [Groups, setGroups] = useState<Group[]>([])

  const [getGrp, setGrp] = useState<string | undefined>();
  // const [GroupData, setGroupData] = useState<Group[] | undefined>();

  const [selectedJobFuns, setSelectedJobFuns] = useState<Group[]>([]);
  

  // const [GroupRes, setGroupRes] = useState(true);
  // const [transformedOptions, setTransformedOptions] = useState<Group[] | undefined>();
  // let transformedOptions: Group[] | undefined;
  // let getObjects: Group[] | undefined;

  
  
  useEffect(() => {    
    getCertificationTypes();
    
    

  }, []);

  const handleSearch = async (e: string) => {
    let Group = await certificationTypes?.find(function (element:any) {
      return element.name === e;
    });
    if (Group) {
      setGrp(undefined);
    } else {
      setGrp(e);
    }
  };


  

  return (
    <div>
      {/* <label>Company Type*:</label> */}

      {/* <div className="row col-md-6"> */}
        <div>
          <Multiselect
            style={{ borderRadius: "0px" }}
            isObject={true}
            placeholder='Certification Type'
            onRemove={async (removed) => {            
              let grpRmv: any = removed.map((obj: any) => obj.id);
              setSelectedJobFuns(removed)
            //   setJobInfo({...jobInfo, job_function_ids: grpRmv})
              setFieldValue('certification_one', grpRmv)
              setGrp(undefined);
             
            }}
            onSearch={handleSearch}
            onSelect={async (add) => {
                // console.log('add grps', add)
              let grpAdd: any = await add.map((obj: any) => obj.id);              
              setSelectedJobFuns(add);
            //   setJobInfo({...jobInfo, job_function_ids: grpAdd})
            setFieldValue('certification_one', grpAdd)
            setGrp(undefined);            

            }}
            options={certificationTypes}
            displayValue="name"
            showCheckbox
            selectedValues={ singleUser ? singleUser?.certification_one : null}
          />
        </div>
        {/* <div className="col-md-2 px-0">
          {getGrp ? (
            <button type='button' className="btn btn-dark btn-md" onClick={InsertGroup}>
              Add
            </button>
          ) : null}
        </div> */}
      {/* </div> */}
    </div>
  );
}

export default CertificationTypeComponent;