import * as Yup from "yup";

export const settingValidationSchema = Yup.object({
  company_name: Yup.string().required("Company name is required").min(3, "Company Name must contain atleast 3 characters"),
  company_website: Yup.string().url("Invalid URL").required("Website is Required"),
  company_description: Yup.string().required("Description is Required"),
  company_phone_number: Yup.string()
      .matches(/^[0-9]+$/, "Mobile number must only contain numbers")
      .required("Mobile number is required"),

  type_of_industry: Yup.string().required("Required"),
  company_size: Yup.string().required("Required"),
  
  products: Yup.string().required("Required"),
  social_media_link_1: Yup.string().url("Invalid URL").required("Required"),
  social_media_link_2: Yup.string().url("Invalid URL").required("Required"),
  company_address: Yup.string().required("Address is Required"),
  testmonial: Yup.string().required("Testmonial is Required"),
  company_history: Yup.string().required("Company History is Required"),
  companyLocation: Yup.string().required("Company Location is Required"),
  companyCountry: Yup.string().required("Country is Required"),
  // company_city: Yup.string().required("Required"),
  // company_state: Yup.string().required("Required"),
  company_postal_code: Yup.string()
    .matches(/^[0-9]+$/, "Pin Code must only contain numbers")
    .required("Pin Code is required"),
  companyType: Yup.array()
      .min(1, "Please select at least one type")
      .required("Type selection is required"),
});
