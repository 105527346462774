import axios from 'axios';
import { Token } from 'models/token.model';
import { User } from 'models/user.model';
import { ApiRoutes } from "routes/routeConstants/apiRoutes";
import { deserialize } from 'serializr';
import { LocalStorage } from 'shared/utils/localStorageHelpers';

interface IRequestQueue {
    request?: any;
    resolve: (val: unknown) => void;
    reject: (reason: unknown) => void;
}

let isTokenValid = true;

const queuedRequests: IRequestQueue[] = [];

const processQueue = (error?: Error, token = "") => {
    queuedRequests.forEach(({ resolve, reject }) => error ? reject(error) : resolve(token));
    queuedRequests.length = 0;
};

export const getHeaders = (): any => {

    let headers, token;
    if (LocalStorage?.getItem('USER')) {
        const localStorageUser = LocalStorage?.getItem('USER')
        token = localStorageUser?.user?.access_token;
        console.log(token)
    }
    headers = {
        'Content-Type': 'application/json',
        'Authorization': `${(token) ? token : ''}`,
    };
    return headers;
};

const axiosInstance = axios.create({
    baseURL: ApiRoutes.BASE_URL,
    timeout: 20000,
});

// axiosInstance.interceptors.request.use(function (config) {
//     config.headers = getHeaders();
//     return config;
// });

axiosInstance.interceptors.request.use(function (config) {
    const headers = getHeaders();
  
    // Add the Content-Type header for file uploads
    if (config.data instanceof FormData) {
      headers['Content-Type'] = 'multipart/form-data';
    }
  
    config.headers = headers;
  
    return config;
  });

axiosInstance.interceptors.response.use(
    (response): any => {
        return {
            data: response.data,
            message: response.statusText,
            status: response.status
        }
    },
    (error) => {
        const { config: originalRequest, response, _retry: RE_TRIED } = error;

        const logout = () => {
            LocalStorage.clear();
            window.location.replace("/");
        }

        if (response.status === 401) {
            if (!!RE_TRIED) return logout();

            if (!isTokenValid) {
                return new Promise((resolve, reject) => {
                    queuedRequests.push({ resolve, reject })
                })
                    .then((token) => {
                        originalRequest.headers["Authorization"] =
                            `${token}`;
                        return axiosInstance(originalRequest);
                    })
                    .catch((ex) => Promise.reject(ex))
            }

            originalRequest._retry = true;
            isTokenValid = false;
            const refreshToken = LocalStorage.getItem("USER")?.token?.refreshToken;
            const payload = {
                vendor_user: {
                    grant_type: "refresh_token",
                    refresh_token: refreshToken,
                },
            };
            return axios
                .post(`${ApiRoutes.BASE_URL}${ApiRoutes.USER_LOGIN}`, payload)
                .then((response) => {
                    if (response.status >= 200 && response.status <= 299) {
                        const userDetails = deserialize(User, response.data["vendor_user"]);
                        const tokenDetails = deserialize(Token, response.data["token"]);
                        LocalStorage.setItem("USER", { vendor_user: userDetails, token: tokenDetails })
                        window.dispatchEvent(new StorageEvent("storage", {
                            key: "USER",
                            newValue: JSON.stringify({vendor_user: userDetails, token: tokenDetails})
                        }));
                        originalRequest.headers["Authorization"] =
                            tokenDetails?.accessToken;
                        processQueue(undefined, tokenDetails?.accessToken);
                        return axiosInstance(originalRequest);
                    }
                })
                .catch((ex) => {
                    processQueue(ex);
                    logout();
                }).finally(() => {
                    isTokenValid = true;
                });
        }
        else if (response?.status === 422) {
           
        
        return Promise.reject(error);
    }
}
);

export default axiosInstance;
